import { FC, ReactNode } from "react";
import { Chip } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Check as ActiveIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    gap: theme.spacing(1),
  },
}));

export interface FilterChipsState {
  /**
   * Unique key for filter.
   */
  key: string;

  /**
   * Display name of filter.
   */
  title: ReactNode;

  /**
   * Is this filter active?
   */
  active: boolean;
}

export interface FilterChipsProps {
  /**
   * List of filters and their state.
   */
  filters?: FilterChipsState[];

  /**
   * Event handler for filter click.
   */
  onFilterClick?: (key: string) => void;

  classes?: ReturnType<typeof useStyles>;
}

/**
 * FilterChips shows a series of clickable chip components intended to represent a series of
 * filters which can be enabled or disabled.
 *
 * Consumers of the component are responsible for managing the filter state.
 */
export const FilterChips: FC<FilterChipsProps> = ({
  filters = [],
  onFilterClick,
  classes: classesProp,
}) => {
  const classes = useStyles({ classes: classesProp });
  const handleFilterClick = (key: string) => onFilterClick && onFilterClick(key);

  return (
    <div className={classes.root}>
      {filters.map(({ key, title, active }) => (
        <Chip
          key={key}
          label={title}
          variant={active ? "default" : "outlined"}
          onClick={() => handleFilterClick(key)}
          icon={active ? <ActiveIcon /> : undefined}
        />
      ))}
    </div>
  );
};
