/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Class representing a phone number held on an institution contact row, for
 * use by the web service API.
 * @export
 * @interface ContactPhoneNumber
 */
export interface ContactPhoneNumber {
  /**
   * The phone number's type.
   * @type {string}
   * @memberof ContactPhoneNumber
   */
  phoneType?: string;
  /**
   * The phone number.
   * @type {string}
   * @memberof ContactPhoneNumber
   */
  number?: string;
  /**
   * Any comment associated with the phone number.
   * @type {string}
   * @memberof ContactPhoneNumber
   */
  comment?: string;
}

export function ContactPhoneNumberFromJSON(json: any): ContactPhoneNumber {
  return ContactPhoneNumberFromJSONTyped(json, false);
}

export function ContactPhoneNumberFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ContactPhoneNumber {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    phoneType: !exists(json, "phoneType") ? undefined : json["phoneType"],
    number: !exists(json, "number") ? undefined : json["number"],
    comment: !exists(json, "comment") ? undefined : json["comment"],
  };
}

export function ContactPhoneNumberToJSON(
  value?: ContactPhoneNumber | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    phoneType: value.phoneType,
    number: value.number,
    comment: value.comment,
  };
}
