/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Attribute,
  AttributeFromJSON,
  AttributeFromJSONTyped,
  AttributeToJSON,
} from "./";

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 * @export
 * @interface InlineResponse2008Result
 */
export interface InlineResponse2008Result {
  /**
   *
   * @type {Array<Attribute>}
   * @memberof InlineResponse2008Result
   */
  attributes?: Array<Attribute>;
}

export function InlineResponse2008ResultFromJSON(
  json: any
): InlineResponse2008Result {
  return InlineResponse2008ResultFromJSONTyped(json, false);
}

export function InlineResponse2008ResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineResponse2008Result {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    attributes: !exists(json, "attributes")
      ? undefined
      : (json["attributes"] as Array<any>).map(AttributeFromJSON),
  };
}

export function InlineResponse2008ResultToJSON(
  value?: InlineResponse2008Result | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    attributes:
      value.attributes === undefined
        ? undefined
        : (value.attributes as Array<any>).map(AttributeToJSON),
  };
}
