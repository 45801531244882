/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Attribute,
  AttributeFromJSON,
  AttributeFromJSONTyped,
  AttributeToJSON,
  Group,
  GroupFromJSON,
  GroupFromJSONTyped,
  GroupToJSON,
  Identifier,
  IdentifierFromJSON,
  IdentifierFromJSONTyped,
  IdentifierToJSON,
  Institution,
  InstitutionFromJSON,
  InstitutionFromJSONTyped,
  InstitutionToJSON,
} from "./";

/**
 * Class representing a person returned by the web service API. Note that
 * the identifier is the person's primary identifier (typically their CRSid),
 * regardless of which identifier was used to query for the person.
 * @export
 * @interface Person
 */
export interface Person {
  /**
   * Flag indicating if the person is cancelled.
   * @type {boolean}
   * @memberof Person
   */
  cancelled?: boolean;
  /**
   *
   * @type {Identifier}
   * @memberof Person
   */
  identifier?: Identifier;
  /**
   * The person's display name (if visible).
   * @type {string}
   * @memberof Person
   */
  displayName?: string;
  /**
   * The person's registered name (if visible).
   * @type {string}
   * @memberof Person
   */
  registeredName?: string;
  /**
   * The person's surname (if visible).
   * @type {string}
   * @memberof Person
   */
  surname?: string;
  /**
   * The person's display name if that is visible, otherwise their
   * registered name if that is visible, otherwise their surname if
   * that is visible, otherwise the value of their primary identifier
   * (typically their CRSid) which is always visible.
   * @type {string}
   * @memberof Person
   */
  visibleName?: string;
  /**
   * The person's MIS status (`"staff"`, `"student"`,
   * `"staff,student"` or `""`).
   * @type {string}
   * @memberof Person
   */
  misAffiliation?: string;
  /**
   * A list of the person's identifiers. This will only be populated if
   * the `fetch` parameter included the
   * `"all_identifiers"` option.
   * @type {Array<Identifier>}
   * @memberof Person
   */
  identifiers?: Array<Identifier>;
  /**
   * A list of the person's attributes. This will only be populated if the
   * `fetch` parameter includes the `"all_attrs"` option,
   * or any specific attribute schemes such as `"email"` or
   * `"title"`, or the special pseudo-attribute scheme
   * `"phone_numbers"`.
   * @type {Array<Attribute>}
   * @memberof Person
   */
  attributes?: Array<Attribute>;
  /**
   * A list of all the institution's to which the person belongs. This
   * will only be populated if the `fetch` parameter includes
   * the `"all_insts"` option.
   * @type {Array<Institution>}
   * @memberof Person
   */
  institutions?: Array<Institution>;
  /**
   * A list of all the groups to which the person belongs, including
   * indirect group memberships, via groups that include other groups.
   * This will only be populated if the `fetch` parameter
   * includes the `"all_groups"` option.
   * @type {Array<Group>}
   * @memberof Person
   */
  groups?: Array<Group>;
  /**
   * A list of all the groups that the person directly belongs to. This
   * does not include indirect group memberships - i.e., groups that
   * include these groups. This will only be populated if the
   * `fetch` parameter includes the `"direct_groups"`
   * option.
   * @type {Array<Group>}
   * @memberof Person
   */
  directGroups?: Array<Group>;
  /**
   * An ID that can uniquely identify this person within the returned
   * XML/JSON document. This is only used in the flattened XML/JSON
   * representation (if the "flatten" parameter is specified).
   * @type {string}
   * @memberof Person
   */
  id?: string;
  /**
   * A reference (by id) to a person element in the XML/JSON document.
   * This is only used in the flattened XML/JSON representation (if the
   * "flatten" parameter is specified).
   * @type {string}
   * @memberof Person
   */
  ref?: string;
}

export function PersonFromJSON(json: any): Person {
  return PersonFromJSONTyped(json, false);
}

export function PersonFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Person {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cancelled: !exists(json, "cancelled") ? undefined : json["cancelled"],
    identifier: !exists(json, "identifier")
      ? undefined
      : IdentifierFromJSON(json["identifier"]),
    displayName: !exists(json, "displayName") ? undefined : json["displayName"],
    registeredName: !exists(json, "registeredName")
      ? undefined
      : json["registeredName"],
    surname: !exists(json, "surname") ? undefined : json["surname"],
    visibleName: !exists(json, "visibleName") ? undefined : json["visibleName"],
    misAffiliation: !exists(json, "misAffiliation")
      ? undefined
      : json["misAffiliation"],
    identifiers: !exists(json, "identifiers")
      ? undefined
      : (json["identifiers"] as Array<any>).map(IdentifierFromJSON),
    attributes: !exists(json, "attributes")
      ? undefined
      : (json["attributes"] as Array<any>).map(AttributeFromJSON),
    institutions: !exists(json, "institutions")
      ? undefined
      : (json["institutions"] as Array<any>).map(InstitutionFromJSON),
    groups: !exists(json, "groups")
      ? undefined
      : (json["groups"] as Array<any>).map(GroupFromJSON),
    directGroups: !exists(json, "directGroups")
      ? undefined
      : (json["directGroups"] as Array<any>).map(GroupFromJSON),
    id: !exists(json, "id") ? undefined : json["id"],
    ref: !exists(json, "ref") ? undefined : json["ref"],
  };
}

export function PersonToJSON(value?: Person | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cancelled: value.cancelled,
    identifier: IdentifierToJSON(value.identifier),
    displayName: value.displayName,
    registeredName: value.registeredName,
    surname: value.surname,
    visibleName: value.visibleName,
    misAffiliation: value.misAffiliation,
    identifiers:
      value.identifiers === undefined
        ? undefined
        : (value.identifiers as Array<any>).map(IdentifierToJSON),
    attributes:
      value.attributes === undefined
        ? undefined
        : (value.attributes as Array<any>).map(AttributeToJSON),
    institutions:
      value.institutions === undefined
        ? undefined
        : (value.institutions as Array<any>).map(InstitutionToJSON),
    groups:
      value.groups === undefined
        ? undefined
        : (value.groups as Array<any>).map(GroupToJSON),
    directGroups:
      value.directGroups === undefined
        ? undefined
        : (value.directGroups as Array<any>).map(GroupToJSON),
    id: value.id,
    ref: value.ref,
  };
}
