import { useAPIGateway } from "../api-gateway/APIGatewayProvider";
import { useCard, useCardAPI, useCardPermissions, CardSummary } from "../api/card-api";
import { CardContent, List, Typography } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useState } from "react";
import { InputDialog } from "./InputDialog";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

interface Props {
  card?: CardSummary;
  isLoading?: boolean;
}

export const CardNotes = ({ card }: Props) => {
  const cardApi = useCardAPI();
  const { user } = useAPIGateway();
  const { crsid } = user ?? { displayName: "Unknown user", crsid: "unknown" };
  const { id } = card || {};
  const { data: cardDetail, refetch } = useCard(id || "", { enabled: !!id });
  const { permissions: cardPermissions } = useCardPermissions();
  const isCardNoteUpdater = !!cardPermissions?.includes("CARD_NOTE_UPDATER");
  const [noteModalOpen, setNoteModalOpen] = useState<boolean>(false);
  const [deleteNoteModalOpen, setDeleteNoteModalOpen] = useState<boolean>(false);
  const [cardNoteId, setCardNoteId] = useState<undefined | string>(undefined);

  return (
    <>
      <InputDialog
        isOpen={noteModalOpen}
        onClose={() => setNoteModalOpen(false)}
        action={async (noteText?: string) => {
          await cardApi.v1beta1CardNotesCreate({
            cardNoteCreateRequestTypeRequest: {
              cardId: cardDetail!.id!,
              text: noteText || "",
            },
          });
        }}
        onSuccess={() => {
          refetch();
          setNoteModalOpen(false);
        }}
        dialogTitle="Add card note"
        failureTitle="Add card note failed!"
        content={{ fieldTitle: "Enter the card note", fieldValue: "" }}
      />
      <InputDialog
        isOpen={deleteNoteModalOpen}
        onClose={() => setDeleteNoteModalOpen(false)}
        action={async () => {
          await cardApi.v1beta1CardNotesDestroy({
            id: cardNoteId!,
          });
        }}
        onSuccess={() => {
          setCardNoteId(undefined);
          refetch();
          setDeleteNoteModalOpen(false);
        }}
        dialogTitle="Delete card note"
        failureTitle="Delete card note failed!"
        content="This card note will be deleted, please confirm"
      />
      <CardContent>
        <Typography variant="subtitle1">Card Notes</Typography>
        {(cardDetail?.notes || []).length < 1 && (
          <Typography variant="body2" color="textPrimary">
            No notes recorded for card.
          </Typography>
        )}

        {(cardDetail?.notes || []).length > 0 && (
          <List style={{ maxHeight: "300px", overflow: "auto" }}>
            {cardDetail?.notes.map(({ id, text, createdAt, createdBy }) => (
              <ListItem key={id}>
                <ListItemText
                  secondary={text}
                  primary={
                    (createdBy && createdBy.includes("@")
                      ? createdBy.substring(0, createdBy.lastIndexOf("@"))
                      : "-") +
                    ", " +
                    (createdAt ? createdAt.toLocaleDateString() : "-")
                  }
                />
                {(isCardNoteUpdater ||
                  (crsid &&
                    createdBy &&
                    createdBy.includes("@") &&
                    crsid === createdBy.substring(0, createdBy.lastIndexOf("@")))) && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setCardNoteId(id);
                      setDeleteNoteModalOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
    </>
  );
};
