import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useState } from "react";

export function SelectInputDialog({
  isOpen,
  onClose,
  onSuccess,
  action,
  dialogTitle,
  failureTitle,
  content,
}: {
  isOpen: boolean;
  onClose: () => void;
  action: (selectValue?: string, fieldValue?: string) => Promise<void>;
  onSuccess: () => void;
  dialogTitle: string;
  failureTitle: string;
  content: {
    fieldTitle: string;
    fieldValue: string;
    selectValue: string;
    selectOptions: string[];
    selectLabel: string;
    onValidate?: (selectValue: string, fieldValue: string) => boolean;
  };
}) {
  const [isLoading, setLoading] = useState(false);
  const [errorDescription, setErrorDescription] = useState<null | string>(null);
  const [fieldValue, setFieldValue] = useState(content.fieldValue);
  const [selectValue, setSelectValue] = useState(content.selectValue);

  const fieldsValid =
    typeof content === "string" ||
    !content?.onValidate ||
    content.onValidate(selectValue, fieldValue);

  const onSetConfirmed = async () => {
    try {
      setLoading(true);
      setErrorDescription(null);
      await action(selectValue, fieldValue);
      onSuccess();
      setFieldValue("");
    } catch (e) {
      setErrorDescription((e as any).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent dividers>
        {typeof content === "string" ? (
          <Typography variant="body1" gutterBottom>
            {content}
          </Typography>
        ) : (
          <Box display="flex" flexDirection="column" height="100%">
            <Box mb={2}>
              <FormControl fullWidth>
                <InputLabel id="form-select-label">{content.selectLabel}</InputLabel>
                <Select
                  id="form-select"
                  labelId="form-select-label"
                  value={selectValue}
                  onChange={(e) => {
                    setSelectValue(e.target.value as string);
                  }}
                  label={content.selectLabel}
                >
                  {content.selectOptions.map((opt) => (
                    <MenuItem key={opt} value={opt}>
                      {opt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <TextField
              placeholder={content.fieldTitle}
              variant="outlined"
              rows={4}
              multiline
              disabled={selectValue === ""}
              style={{ width: 400 }}
              value={fieldValue}
              onChange={(e) => {
                setFieldValue(e.target.value);
              }}
            />
          </Box>
        )}
        {errorDescription && (
          <Alert severity="error">
            <AlertTitle>{failureTitle}</AlertTitle> {errorDescription}{" "}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isLoading || !fieldsValid} onClick={onSetConfirmed}>
          {isLoading ? <CircularProgress /> : errorDescription ? "Retry" : "Accept"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
