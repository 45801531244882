/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AttributeScheme,
  AttributeSchemeFromJSON,
  AttributeSchemeFromJSONTyped,
  AttributeSchemeToJSON,
} from "./";

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 * @export
 * @interface InlineResponse2004Result
 */
export interface InlineResponse2004Result {
  /**
   *
   * @type {Array<AttributeScheme>}
   * @memberof InlineResponse2004Result
   */
  attributeSchemes?: Array<AttributeScheme>;
}

export function InlineResponse2004ResultFromJSON(
  json: any
): InlineResponse2004Result {
  return InlineResponse2004ResultFromJSONTyped(json, false);
}

export function InlineResponse2004ResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineResponse2004Result {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    attributeSchemes: !exists(json, "attributeSchemes")
      ? undefined
      : (json["attributeSchemes"] as Array<any>).map(AttributeSchemeFromJSON),
  };
}

export function InlineResponse2004ResultToJSON(
  value?: InlineResponse2004Result | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    attributeSchemes:
      value.attributeSchemes === undefined
        ? undefined
        : (value.attributeSchemes as Array<any>).map(AttributeSchemeToJSON),
  };
}
