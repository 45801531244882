import { Button, Grid, Link, Typography, Box, Divider } from "@material-ui/core";
import { FullWidthPage } from "../styles/layout";
import { VideoIFrame } from "../components";

const cardAPIDocsLink = "https://developer.api.apps.cam.ac.uk/docs/card/1/overview";
const apiGatewayLink = "https://developer.api.apps.cam.ac.uk";
const cardClientDocsLink =
  "https://gitlab.developers.cam.ac.uk/uis/devops/iam/card-database/card-client#card-client";

export const ExportPage = () => {
  return (
    <Grid container item xs={12} spacing={2}>
      <FullWidthPage>
        <Typography variant="h4" gutterBottom>
          Card Data Exports
        </Typography>
        <Typography variant="body1" gutterBottom>
          We recommend using the{" "}
          <Link href={cardClientDocsLink} target="_blank" color="textSecondary">
            Card Client
          </Link>{" "}
          to export card data. The Card Client allows CSVs of card data to be generated based on
          Lookup Institutions, Lookup Groups, CamSIS institutions, or even a list of CRSids or
          USNs.
        </Typography>
        <Typography variant="body1">
          Using the Card Client rather than downloading an export from a web interface has multiple
          advantages:
          <ul>
            <li>
              The Card Client is highly configurable, allowing the export of cards for different
              institutions and groups, and allowing the format of the export to be configured,
            </li>
            <li>
              The Card Client can be scheduled to run regularly, automatically keeping your CSV of
              card details up-to-date as users are issued new cards or as cards expire,
            </li>
            <li>
              The Card Client produces CSVs that can be used to populate access systems, identity
              databases, or directly ingested for use in other systems,
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom>
          The Card Client is a command-line tool, which may take a little effort to install and
          configure. In order to help with the installation process we've put together a video
          which walks through the steps required (@cam.ac.uk Microsoft sign-in required).
        </Typography>
        <VideoIFrame
          title="Using the University Card Client"
          src="https://web.microsoftstream.com/embed/video/a8ab9500-95de-4662-8247-748e18d46c7e?autoplay=false&showinfo=false"
          allowFullScreen
        />
        <Typography variant="body1" gutterBottom>
          For more information about the Card Client and in-depth instructions about how to install
          and configure it, please refer to the Card Client documentation.
        </Typography>
        <Box py={2}>
          <Button
            variant="contained"
            color="primary"
            href={cardClientDocsLink}
            target="_blank"
            title="Documentation about the Card Client"
          >
            Card Client documentation
          </Button>
        </Box>
        <Box py={2}>
          <Divider />
        </Box>
        <Typography variant="h4" gutterBottom>
          Consuming card data from other applications / services
        </Typography>
        <Typography variant="body1">
          All data displayed within this application is available through the{" "}
          <Link
            href={cardAPIDocsLink}
            target="_blank"
            color="textSecondary"
            title="Documentation about the University Card API"
          >
            University Card API
          </Link>
          . If you have an application or service which needs access to card data, you can make use
          of the Card API directly by registering an application within the{" "}
          <Link
            href={apiGatewayLink}
            target="_blank"
            color="textSecondary"
            title="API Gateway documentation"
          >
            University's API Gateway
          </Link>
          . The Card API's documentation gives an overview of the data that can be accessed via the
          API, as well as guidance about how best to make use of the API.
        </Typography>
        <Box py={2}>
          <Button variant="contained" color="primary" href={cardAPIDocsLink} target="_blank">
            Card API documentation
          </Button>
        </Box>
      </FullWidthPage>
    </Grid>
  );
};
