import { FC } from "react";
import { useInView } from "react-intersection-observer";
import { Fade, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

import { useCardVisualizationData, CardSummaryStatusEnum } from "../api/card-api";
import FrontPlaceholder from "../images/placeholder-front.svg";

const useStyles = makeStyles((theme: Theme) => ({
  cardVisualization: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.action.selected,
  },

  placeholder: {
    display: "block",
    position: "relative",
    width: "100%",
    height: "100%",
  },

  visualization: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },

  fallbackText: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.action.selected,
  },
}));

export interface CardVisualizationProps {
  visualizationUrl?: string;

  /**
   * Whether the card is loading.
   */
  isLoading: boolean;

  /**
   * The text to be used in case no visualization link is provided.
   */
  fallbackText?: string;

  cardStatus?: CardSummaryStatusEnum;

  classes?: ReturnType<typeof useStyles>;
}

/**
 * CardVisualization shows a visualisation of the card.
 */
export const CardVisualization: FC<CardVisualizationProps> = ({
  visualizationUrl,
  isLoading,
  fallbackText = "",
  cardStatus = null,
  classes: classesProp,
}) => {
  const classes = useStyles({ classes: classesProp });
  const { ref, inView } = useInView({ triggerOnce: true });

  const { data: visualizationData, isLoading: isLoadingVisualization } = useCardVisualizationData(
    inView ? visualizationUrl : undefined
  );
  const noCard = !isLoading && !visualizationUrl;

  const overlayTextCardStatus = [
    CardSummaryStatusEnum.Revoked,
    CardSummaryStatusEnum.Returned,
    CardSummaryStatusEnum.Expired,
  ];

  return (
    <div className={classes.cardVisualization} ref={ref}>
      {noCard && <Typography className={classes.fallbackText}>{fallbackText}</Typography>}
      {visualizationData && (
        <>
          <img
            className={classes.visualization}
            alt="Card Visualization"
            src={visualizationData}
          />
          {cardStatus && (
            <Typography className={classes.fallbackText} variant="h3" style={{ color: "red" }}>
              {overlayTextCardStatus.includes(cardStatus) ? cardStatus : ""}
            </Typography>
          )}
        </>
      )}
      <Fade
        in={isLoading || isLoadingVisualization}
        timeout={{ enter: 0, exit: 250 }}
        style={{ transitionDelay: "50ms" }}
      >
        <img className={classes.placeholder} src={FrontPlaceholder} alt="Placeholder card" />
      </Fade>
    </div>
  );
};
