// PeopleSearchInput.tsx defines a specialisation of SearchInput for searching for people.
import { useState, useMemo, forwardRef, ComponentProps } from "react";
import { Card, CardContent, Link, Typography } from "@material-ui/core";
import { SearchInput, SearchInputRefObject } from "../components/SearchInput";
import { lqlHelpLink } from "../config/consts";
import { useStyles } from "../styles/styles";

export type PeopleSearchInputProps = Omit<
  ComponentProps<typeof SearchInput>,
  "onAutoComplete" | "autoCompleteResults" | "searchHistory"
>;

/**
 * PeopleSearchInput specialises the SearchInput component and implements the autocomplete logic
 * for people and maintains a search history in local session storage.
 */
export const PeopleSearchInput = forwardRef<SearchInputRefObject, PeopleSearchInputProps>(
  ({ onSubmitQuery, ...otherProps }, ref) => {
    const classes = useStyles();
    const [searchHistory, setSearchHistory] = useState<string[]>([]);

    // Form search history suggestions from search history.
    const searchHistorySuggestions = useMemo(
      () => searchHistory.map((query) => ({ query, node: query })),
      [searchHistory]
    );

    // Event handler when a new search query is submitted.
    const handleSubmitQuery = (query: string) => {
      // Update history.
      if (query !== "") {
        setSearchHistory([query, ...searchHistory.filter((q) => q !== query)]);
      }

      // Delegate to onSubmitQuery prop.
      return onSubmitQuery?.(query);
    };

    // Popover to show when help icon clicked in search input
    const helpPopover = (
      <Card className={classes.helpTooltip}>
        <CardContent>
          <Typography variant="body2">
            In addition to searching by name, CRSid and other card identifiers, advanced searches
            can be performed by using{" "}
            <Link href={lqlHelpLink} target="_blank">
              Lookup's LQL syntax
            </Link>
            .
          </Typography>
        </CardContent>
      </Card>
    );

    return (
      <SearchInput
        searchHistory={searchHistorySuggestions}
        onSubmitQuery={handleSubmitQuery}
        ref={ref}
        helpPopover={helpPopover}
        {...otherProps}
      />
    );
  }
);
