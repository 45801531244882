/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  InlineResponse2004Result,
  InlineResponse2004ResultFromJSON,
  InlineResponse2004ResultFromJSONTyped,
  InlineResponse2004ResultToJSON,
} from "./";

/**
 *
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
  /**
   *
   * @type {InlineResponse2004Result}
   * @memberof InlineResponse2004
   */
  result?: InlineResponse2004Result;
}

export function InlineResponse2004FromJSON(json: any): InlineResponse2004 {
  return InlineResponse2004FromJSONTyped(json, false);
}

export function InlineResponse2004FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineResponse2004 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    result: !exists(json, "result")
      ? undefined
      : InlineResponse2004ResultFromJSON(json["result"]),
  };
}

export function InlineResponse2004ToJSON(
  value?: InlineResponse2004 | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    result: InlineResponse2004ResultToJSON(value.result),
  };
}
