import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
  makeStyles,
} from "@material-ui/core";

export const PhotoUploadDialog = ({
  open,
  onUpload,
  onClose,
}: {
  open: boolean;
  onUpload: () => void;
  onClose: () => void;
}) => {
  const useStyles = makeStyles(() => ({
    listItem: {
      marginTop: "0px",
      marginBottom: "0px",
      paddingTop: "0",
      paddingBottom: "0",
      lineHeight: "1",
    },
    listTypography: {
      lineHeight: "1",
    },
  }));

  const PhotoPolityBulletPointList = () => {
    const classes = useStyles();

    return (
      <List>
        The photo must be:
        <ListItem className={classes.listItem}>
          <Typography variant="body1" component="span" className={classes.listTypography}>
            {"\u2022"} in colour
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant="body1" component="span" className={classes.listTypography}>
            {"\u2022"} clear and in focus
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant="body1" component="span" className={classes.listTypography}>
            {"\u2022"} taken against a plain light-coloured background
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant="body1" component="span" className={classes.listTypography}>
            {"\u2022"} unaltered by the use of filters
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant="body1" component="span" className={classes.listTypography}>
            {"\u2022"} an original photo (not a photo of another photo)
          </Typography>
        </ListItem>
        <br></br>
        In the photo the user must:
        <ListItem className={classes.listItem}>
          <Typography variant="body1" component="span" className={classes.listTypography}>
            {"\u2022"} be facing forwards looking straight at the camera
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant="body1" component="span" className={classes.listTypography}>
            {"\u2022"} have your eyes open and visible
          </Typography>
        </ListItem>
        <br></br>
        The photo must not:
        <ListItem className={classes.listItem}>
          <Typography variant="body1" component="span" className={classes.listTypography}>
            {"\u2022"} contain other objects or people
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant="body1" component="span" className={classes.listTypography}>
            {"\u2022"} have 'red eye' effect
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant="body1" component="span" className={classes.listTypography}>
            {"\u2022"} have hair in front of your eyes
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant="body1" component="span" className={classes.listTypography}>
            {"\u2022"} have any head covering (unless for religious or medical reasons)
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant="body1" component="span" className={classes.listTypography}>
            {"\u2022"} have anything covering your eyes
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant="body1" component="span" className={classes.listTypography}>
            {"\u2022"} have any shadows on your face or behind you
          </Typography>
        </ListItem>
        <br></br>
      </List>
    );
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Upload New Photo</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" gutterBottom>
          <Grid item sm={12}>
            <DialogContentText>
              <Typography paragraph={true}>
                Please ensure the new photo complies with the{" "}
                <Link target="_blank" href="https://help.uis.cam.ac.uk/university-card-photo">
                  University photo policy
                </Link>
                :
                <PhotoPolityBulletPointList />
                Photos are automatically sized, cropped and rotated as needed; then manually
                approved or rejected by the Card Office.
              </Typography>
            </DialogContentText>
          </Grid>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onUpload();
            onClose();
          }}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};
