import { Button, Box, Divider, Grid, Link, Typography } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { feedbackIssueEmail, projectTeamEmail, faqLink } from "../config/consts";
import { FullWidthPage } from "../styles/layout";

export const FeedbackPage = () => {
  return (
    <Grid container item xs={12} spacing={2}>
      <FullWidthPage>
        <Typography variant="h4" gutterBottom>
          Using this application
        </Typography>
        <Typography variant="body1">
          UIS has put together an information page for card representatives, including a series of
          videos, which gives an overview of this application and walks through some common
          workflows. This can be found&nbsp;
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.uis.cam.ac.uk/service/security/university-cards/information-card-reps"
          >
            here on the UIS help site
            <LaunchIcon fontSize="inherit" />
          </Link>
          .
        </Typography>
        <Box py={2}>
          <Divider />
        </Box>
        <Typography variant="h4" gutterBottom>
          Providing feedback and reporting issues
        </Typography>
        <Typography variant="body1" gutterBottom>
          The Card Management project team greatly appreciate your feedback and thoughts about how
          to improve the service.
        </Typography>

        <Typography variant="body1" gutterBottom>
          If you have a proposal for improvement, or question about existing functionality, please
          either create an issue within the Feedback project on GitLab, or contact the project team
          via email.
        </Typography>

        <Typography variant="body1">
          Before reporting an issue through the methods below, please check the FAQ in case your
          question has already been answered.
        </Typography>

        <Box py={2}>
          <Button
            variant="contained"
            color="primary"
            href={`mailto:${feedbackIssueEmail}`}
            target="_blank"
          >
            Contact the Card office
          </Button>
          &nbsp;
          <Button variant="outlined" href={`mailto:${projectTeamEmail}`}>
            Report a bug
          </Button>
          &nbsp;
          <Button variant="outlined" href={faqLink} target="_blank">
            FAQ
          </Button>
        </Box>
      </FullWidthPage>
    </Grid>
  );
};
