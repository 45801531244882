/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  InlineResponse200,
  InlineResponse200FromJSON,
  InlineResponse200ToJSON,
  InlineResponse2001,
  InlineResponse2001FromJSON,
  InlineResponse2001ToJSON,
  InlineResponse20011,
  InlineResponse20011FromJSON,
  InlineResponse20011ToJSON,
  InlineResponse20012,
  InlineResponse20012FromJSON,
  InlineResponse20012ToJSON,
  InlineResponse2003,
  InlineResponse2003FromJSON,
  InlineResponse2003ToJSON,
  InlineResponse2004,
  InlineResponse2004FromJSON,
  InlineResponse2004ToJSON,
  InlineResponse2005,
  InlineResponse2005FromJSON,
  InlineResponse2005ToJSON,
  InlineResponse2008,
  InlineResponse2008FromJSON,
  InlineResponse2008ToJSON,
  InlineResponse2009,
  InlineResponse2009FromJSON,
  InlineResponse2009ToJSON,
  InlineResponseDefault,
  InlineResponseDefaultFromJSON,
  InlineResponseDefaultToJSON,
} from "../models";

export interface PersonAllPeopleRequest {
  includeCancelled?: boolean;
  identifier?: string;
  limit?: number;
  fetch?: string;
}

export interface PersonGetAttributeRequest {
  scheme: string;
  identifier: string;
  attrid: number;
}

export interface PersonGetAttributesRequest {
  scheme: string;
  identifier: string;
  attrs: string;
}

export interface PersonGetGroupsRequest {
  scheme: string;
  identifier: string;
  fetch?: string;
}

export interface PersonGetInstsRequest {
  scheme: string;
  identifier: string;
  fetch?: string;
}

export interface PersonGetManagedGroupsRequest {
  scheme: string;
  identifier: string;
  fetch?: string;
}

export interface PersonGetManagedInstsRequest {
  scheme: string;
  identifier: string;
  fetch?: string;
}

export interface PersonGetPersonRequest {
  scheme: string;
  identifier: string;
  fetch?: string;
}

export interface PersonIsMemberOfGroupRequest {
  scheme: string;
  identifier: string;
  groupid: string;
}

export interface PersonIsMemberOfInstRequest {
  scheme: string;
  identifier: string;
  instid: string;
}

export interface PersonListPeopleRequest {
  crsids: string;
  fetch?: string;
}

export interface PersonModifiedPeopleRequest {
  minTxId: number;
  maxTxId: number;
  crsids?: string;
  includeCancelled?: boolean;
  membershipChanges?: boolean;
  instNameChanges?: boolean;
  fetch?: string;
}

export interface PersonSearchRequest {
  query: string;
  approxMatches?: boolean;
  includeCancelled?: boolean;
  misStatus?: string;
  attributes?: string;
  offset?: number;
  limit?: number;
  orderBy?: string;
  fetch?: string;
}

export interface PersonSearchCountRequest {
  query: string;
  approxMatches?: boolean;
  includeCancelled?: boolean;
  misStatus?: string;
  attributes?: string;
}

/**
 *
 */
export class PersonApi extends runtime.BaseAPI {
  /**
   * Return a list of all the person attribute schemes available. The `schemeid` values of these schemes may be used in the `fetch` parameter of other methods that return people.  NOTE: Some of these attribute schemes are not currently used (no people have attribute values in the scheme). These schemes are reserved for possible future use.
   * Return a list of all the person attribute schemes available.
   */
  async personAllAttributeSchemesRaw(): Promise<
    runtime.ApiResponse<InlineResponse2004>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/all-attr-schemes`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2004FromJSON(jsonValue)
    );
  }

  /**
   * Return a list of all the person attribute schemes available. The `schemeid` values of these schemes may be used in the `fetch` parameter of other methods that return people.  NOTE: Some of these attribute schemes are not currently used (no people have attribute values in the scheme). These schemes are reserved for possible future use.
   * Return a list of all the person attribute schemes available.
   */
  async personAllAttributeSchemes(): Promise<InlineResponse2004> {
    const response = await this.personAllAttributeSchemesRaw();
    return await response.value();
  }

  /**
   * Return a list of all people (in batches).  The results are sorted by identifier, starting with the first person after the person with the specified identifier. Thus, to iterate over all people, pass a `null` identifier to get the first batch of people, then pass the last identifier from the previous batch to get the next batch, and repeat until no more people are returned.  By default, only a few basic details about each person are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.
   * Return a list of all people (in batches).
   */
  async personAllPeopleRaw(
    requestParameters: PersonAllPeopleRequest
  ): Promise<runtime.ApiResponse<InlineResponse2003>> {
    const queryParameters: any = {};

    if (requestParameters.includeCancelled !== undefined) {
      queryParameters["includeCancelled"] = requestParameters.includeCancelled;
    }

    if (requestParameters.identifier !== undefined) {
      queryParameters["identifier"] = requestParameters.identifier;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/all-people`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2003FromJSON(jsonValue)
    );
  }

  /**
   * Return a list of all people (in batches).  The results are sorted by identifier, starting with the first person after the person with the specified identifier. Thus, to iterate over all people, pass a `null` identifier to get the first batch of people, then pass the last identifier from the previous batch to get the next batch, and repeat until no more people are returned.  By default, only a few basic details about each person are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.
   * Return a list of all people (in batches).
   */
  async personAllPeople(
    requestParameters: PersonAllPeopleRequest
  ): Promise<InlineResponse2003> {
    const response = await this.personAllPeopleRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a specific attribute of a person.
   * Get a specific attribute of a person.
   */
  async personGetAttributeRaw(
    requestParameters: PersonGetAttributeRequest
  ): Promise<runtime.ApiResponse<InlineResponse2009>> {
    if (
      requestParameters.scheme === null ||
      requestParameters.scheme === undefined
    ) {
      throw new runtime.RequiredError(
        "scheme",
        "Required parameter requestParameters.scheme was null or undefined when calling personGetAttribute."
      );
    }

    if (
      requestParameters.identifier === null ||
      requestParameters.identifier === undefined
    ) {
      throw new runtime.RequiredError(
        "identifier",
        "Required parameter requestParameters.identifier was null or undefined when calling personGetAttribute."
      );
    }

    if (
      requestParameters.attrid === null ||
      requestParameters.attrid === undefined
    ) {
      throw new runtime.RequiredError(
        "attrid",
        "Required parameter requestParameters.attrid was null or undefined when calling personGetAttribute."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/{scheme}/{identifier}/{attrid}`
        .replace(
          `{${"scheme"}}`,
          encodeURIComponent(String(requestParameters.scheme))
        )
        .replace(
          `{${"identifier"}}`,
          encodeURIComponent(String(requestParameters.identifier))
        )
        .replace(
          `{${"attrid"}}`,
          encodeURIComponent(String(requestParameters.attrid))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2009FromJSON(jsonValue)
    );
  }

  /**
   * Get a specific attribute of a person.
   * Get a specific attribute of a person.
   */
  async personGetAttribute(
    requestParameters: PersonGetAttributeRequest
  ): Promise<InlineResponse2009> {
    const response = await this.personGetAttributeRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get one or more (possibly multi-valued) attributes of a person. The returned attributes are sorted by attribute scheme precedence and then attribute precedence.
   * Get one or more (possibly multi-valued) attributes of a person.
   */
  async personGetAttributesRaw(
    requestParameters: PersonGetAttributesRequest
  ): Promise<runtime.ApiResponse<InlineResponse2008>> {
    if (
      requestParameters.scheme === null ||
      requestParameters.scheme === undefined
    ) {
      throw new runtime.RequiredError(
        "scheme",
        "Required parameter requestParameters.scheme was null or undefined when calling personGetAttributes."
      );
    }

    if (
      requestParameters.identifier === null ||
      requestParameters.identifier === undefined
    ) {
      throw new runtime.RequiredError(
        "identifier",
        "Required parameter requestParameters.identifier was null or undefined when calling personGetAttributes."
      );
    }

    if (
      requestParameters.attrs === null ||
      requestParameters.attrs === undefined
    ) {
      throw new runtime.RequiredError(
        "attrs",
        "Required parameter requestParameters.attrs was null or undefined when calling personGetAttributes."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.attrs !== undefined) {
      queryParameters["attrs"] = requestParameters.attrs;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/{scheme}/{identifier}/get-attributes`
        .replace(
          `{${"scheme"}}`,
          encodeURIComponent(String(requestParameters.scheme))
        )
        .replace(
          `{${"identifier"}}`,
          encodeURIComponent(String(requestParameters.identifier))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2008FromJSON(jsonValue)
    );
  }

  /**
   * Get one or more (possibly multi-valued) attributes of a person. The returned attributes are sorted by attribute scheme precedence and then attribute precedence.
   * Get one or more (possibly multi-valued) attributes of a person.
   */
  async personGetAttributes(
    requestParameters: PersonGetAttributesRequest
  ): Promise<InlineResponse2008> {
    const response = await this.personGetAttributesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get all the groups to which the specified person belongs, including indirect group memberships, via groups that include other groups. The returned list of groups is sorted by groupid.  Note that some group memberships may not be visible to you. This method will only return those group memberships that you have permission to see.  By default, only a few basic details about each group are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of each group.  NOTE: This method will not include cancelled groups.
   * Get all the groups to which the specified person belongs, including indirect group memberships, via groups that include other groups.
   */
  async personGetGroupsRaw(
    requestParameters: PersonGetGroupsRequest
  ): Promise<runtime.ApiResponse<InlineResponse200>> {
    if (
      requestParameters.scheme === null ||
      requestParameters.scheme === undefined
    ) {
      throw new runtime.RequiredError(
        "scheme",
        "Required parameter requestParameters.scheme was null or undefined when calling personGetGroups."
      );
    }

    if (
      requestParameters.identifier === null ||
      requestParameters.identifier === undefined
    ) {
      throw new runtime.RequiredError(
        "identifier",
        "Required parameter requestParameters.identifier was null or undefined when calling personGetGroups."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/{scheme}/{identifier}/groups`
        .replace(
          `{${"scheme"}}`,
          encodeURIComponent(String(requestParameters.scheme))
        )
        .replace(
          `{${"identifier"}}`,
          encodeURIComponent(String(requestParameters.identifier))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse200FromJSON(jsonValue)
    );
  }

  /**
   * Get all the groups to which the specified person belongs, including indirect group memberships, via groups that include other groups. The returned list of groups is sorted by groupid.  Note that some group memberships may not be visible to you. This method will only return those group memberships that you have permission to see.  By default, only a few basic details about each group are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of each group.  NOTE: This method will not include cancelled groups.
   * Get all the groups to which the specified person belongs, including indirect group memberships, via groups that include other groups.
   */
  async personGetGroups(
    requestParameters: PersonGetGroupsRequest
  ): Promise<InlineResponse200> {
    const response = await this.personGetGroupsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get all the institutions to which the specified person belongs. The returned list of institutions is sorted by name.  By default, only a few basic details about each institution are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of each institution.  NOTE: This method will not include cancelled institutions.
   * Get all the institutions to which the specified person belongs.
   */
  async personGetInstsRaw(
    requestParameters: PersonGetInstsRequest
  ): Promise<runtime.ApiResponse<InlineResponse2005>> {
    if (
      requestParameters.scheme === null ||
      requestParameters.scheme === undefined
    ) {
      throw new runtime.RequiredError(
        "scheme",
        "Required parameter requestParameters.scheme was null or undefined when calling personGetInsts."
      );
    }

    if (
      requestParameters.identifier === null ||
      requestParameters.identifier === undefined
    ) {
      throw new runtime.RequiredError(
        "identifier",
        "Required parameter requestParameters.identifier was null or undefined when calling personGetInsts."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/{scheme}/{identifier}/insts`
        .replace(
          `{${"scheme"}}`,
          encodeURIComponent(String(requestParameters.scheme))
        )
        .replace(
          `{${"identifier"}}`,
          encodeURIComponent(String(requestParameters.identifier))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2005FromJSON(jsonValue)
    );
  }

  /**
   * Get all the institutions to which the specified person belongs. The returned list of institutions is sorted by name.  By default, only a few basic details about each institution are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of each institution.  NOTE: This method will not include cancelled institutions.
   * Get all the institutions to which the specified person belongs.
   */
  async personGetInsts(
    requestParameters: PersonGetInstsRequest
  ): Promise<InlineResponse2005> {
    const response = await this.personGetInstsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get all the groups that the specified person has persmission to edit. The returned list of groups is sorted by groupid.  Note that some group memberships may not be visible to you. This method will only include groups for which you have persmission to see the applicable manager group memberships.  By default, only a few basic details about each group are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of each group.  NOTE: This method will not include cancelled groups.
   * Get all the groups that the specified person has persmission to edit.
   */
  async personGetManagedGroupsRaw(
    requestParameters: PersonGetManagedGroupsRequest
  ): Promise<runtime.ApiResponse<InlineResponse200>> {
    if (
      requestParameters.scheme === null ||
      requestParameters.scheme === undefined
    ) {
      throw new runtime.RequiredError(
        "scheme",
        "Required parameter requestParameters.scheme was null or undefined when calling personGetManagedGroups."
      );
    }

    if (
      requestParameters.identifier === null ||
      requestParameters.identifier === undefined
    ) {
      throw new runtime.RequiredError(
        "identifier",
        "Required parameter requestParameters.identifier was null or undefined when calling personGetManagedGroups."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/{scheme}/{identifier}/manages-groups`
        .replace(
          `{${"scheme"}}`,
          encodeURIComponent(String(requestParameters.scheme))
        )
        .replace(
          `{${"identifier"}}`,
          encodeURIComponent(String(requestParameters.identifier))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse200FromJSON(jsonValue)
    );
  }

  /**
   * Get all the groups that the specified person has persmission to edit. The returned list of groups is sorted by groupid.  Note that some group memberships may not be visible to you. This method will only include groups for which you have persmission to see the applicable manager group memberships.  By default, only a few basic details about each group are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of each group.  NOTE: This method will not include cancelled groups.
   * Get all the groups that the specified person has persmission to edit.
   */
  async personGetManagedGroups(
    requestParameters: PersonGetManagedGroupsRequest
  ): Promise<InlineResponse200> {
    const response = await this.personGetManagedGroupsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get all the institutions that the specified person has permission to edit. The returned list of institutions is sorted by name.  Note that some group memberships may not be visible to you. This method will only include institutions for which you have permission to see the applicable editor group memberships.  By default, only a few basic details about each institution are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of each institution.  NOTE: This method will not include cancelled institutions.
   * Get all the institutions that the specified person has permission to edit.
   */
  async personGetManagedInstsRaw(
    requestParameters: PersonGetManagedInstsRequest
  ): Promise<runtime.ApiResponse<InlineResponse2005>> {
    if (
      requestParameters.scheme === null ||
      requestParameters.scheme === undefined
    ) {
      throw new runtime.RequiredError(
        "scheme",
        "Required parameter requestParameters.scheme was null or undefined when calling personGetManagedInsts."
      );
    }

    if (
      requestParameters.identifier === null ||
      requestParameters.identifier === undefined
    ) {
      throw new runtime.RequiredError(
        "identifier",
        "Required parameter requestParameters.identifier was null or undefined when calling personGetManagedInsts."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/{scheme}/{identifier}/manages-insts`
        .replace(
          `{${"scheme"}}`,
          encodeURIComponent(String(requestParameters.scheme))
        )
        .replace(
          `{${"identifier"}}`,
          encodeURIComponent(String(requestParameters.identifier))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2005FromJSON(jsonValue)
    );
  }

  /**
   * Get all the institutions that the specified person has permission to edit. The returned list of institutions is sorted by name.  Note that some group memberships may not be visible to you. This method will only include institutions for which you have permission to see the applicable editor group memberships.  By default, only a few basic details about each institution are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of each institution.  NOTE: This method will not include cancelled institutions.
   * Get all the institutions that the specified person has permission to edit.
   */
  async personGetManagedInsts(
    requestParameters: PersonGetManagedInstsRequest
  ): Promise<InlineResponse2005> {
    const response = await this.personGetManagedInstsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the person with the specified identifier.  By default, only a few basic details about the person are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of the person.  NOTE: The person returned may be a cancelled person. It is the caller\'s repsonsibility to check its cancelled flag.
   * Get the person with the specified identifier.
   */
  async personGetPersonRaw(
    requestParameters: PersonGetPersonRequest
  ): Promise<runtime.ApiResponse<InlineResponse20011>> {
    if (
      requestParameters.scheme === null ||
      requestParameters.scheme === undefined
    ) {
      throw new runtime.RequiredError(
        "scheme",
        "Required parameter requestParameters.scheme was null or undefined when calling personGetPerson."
      );
    }

    if (
      requestParameters.identifier === null ||
      requestParameters.identifier === undefined
    ) {
      throw new runtime.RequiredError(
        "identifier",
        "Required parameter requestParameters.identifier was null or undefined when calling personGetPerson."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/{scheme}/{identifier}`
        .replace(
          `{${"scheme"}}`,
          encodeURIComponent(String(requestParameters.scheme))
        )
        .replace(
          `{${"identifier"}}`,
          encodeURIComponent(String(requestParameters.identifier))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse20011FromJSON(jsonValue)
    );
  }

  /**
   * Get the person with the specified identifier.  By default, only a few basic details about the person are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of the person.  NOTE: The person returned may be a cancelled person. It is the caller\'s repsonsibility to check its cancelled flag.
   * Get the person with the specified identifier.
   */
  async personGetPerson(
    requestParameters: PersonGetPersonRequest
  ): Promise<InlineResponse20011> {
    const response = await this.personGetPersonRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test if the specified person is a member of the specified group.  NOTE: This may be used with cancelled people and groups.
   * Test if the specified person is a member of the specified group.
   */
  async personIsMemberOfGroupRaw(
    requestParameters: PersonIsMemberOfGroupRequest
  ): Promise<runtime.ApiResponse<InlineResponse20012>> {
    if (
      requestParameters.scheme === null ||
      requestParameters.scheme === undefined
    ) {
      throw new runtime.RequiredError(
        "scheme",
        "Required parameter requestParameters.scheme was null or undefined when calling personIsMemberOfGroup."
      );
    }

    if (
      requestParameters.identifier === null ||
      requestParameters.identifier === undefined
    ) {
      throw new runtime.RequiredError(
        "identifier",
        "Required parameter requestParameters.identifier was null or undefined when calling personIsMemberOfGroup."
      );
    }

    if (
      requestParameters.groupid === null ||
      requestParameters.groupid === undefined
    ) {
      throw new runtime.RequiredError(
        "groupid",
        "Required parameter requestParameters.groupid was null or undefined when calling personIsMemberOfGroup."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/{scheme}/{identifier}/is-member-of-group/{groupid}`
        .replace(
          `{${"scheme"}}`,
          encodeURIComponent(String(requestParameters.scheme))
        )
        .replace(
          `{${"identifier"}}`,
          encodeURIComponent(String(requestParameters.identifier))
        )
        .replace(
          `{${"groupid"}}`,
          encodeURIComponent(String(requestParameters.groupid))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse20012FromJSON(jsonValue)
    );
  }

  /**
   * Test if the specified person is a member of the specified group.  NOTE: This may be used with cancelled people and groups.
   * Test if the specified person is a member of the specified group.
   */
  async personIsMemberOfGroup(
    requestParameters: PersonIsMemberOfGroupRequest
  ): Promise<InlineResponse20012> {
    const response = await this.personIsMemberOfGroupRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test if the specified person is a member of the specified institution.  NOTE: This may be used with cancelled people and institutions, but it will not include cancelled membership groups.
   * Test if the specified person is a member of the specified institution.
   */
  async personIsMemberOfInstRaw(
    requestParameters: PersonIsMemberOfInstRequest
  ): Promise<runtime.ApiResponse<InlineResponse20012>> {
    if (
      requestParameters.scheme === null ||
      requestParameters.scheme === undefined
    ) {
      throw new runtime.RequiredError(
        "scheme",
        "Required parameter requestParameters.scheme was null or undefined when calling personIsMemberOfInst."
      );
    }

    if (
      requestParameters.identifier === null ||
      requestParameters.identifier === undefined
    ) {
      throw new runtime.RequiredError(
        "identifier",
        "Required parameter requestParameters.identifier was null or undefined when calling personIsMemberOfInst."
      );
    }

    if (
      requestParameters.instid === null ||
      requestParameters.instid === undefined
    ) {
      throw new runtime.RequiredError(
        "instid",
        "Required parameter requestParameters.instid was null or undefined when calling personIsMemberOfInst."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/{scheme}/{identifier}/is-member-of-inst/{instid}`
        .replace(
          `{${"scheme"}}`,
          encodeURIComponent(String(requestParameters.scheme))
        )
        .replace(
          `{${"identifier"}}`,
          encodeURIComponent(String(requestParameters.identifier))
        )
        .replace(
          `{${"instid"}}`,
          encodeURIComponent(String(requestParameters.instid))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse20012FromJSON(jsonValue)
    );
  }

  /**
   * Test if the specified person is a member of the specified institution.  NOTE: This may be used with cancelled people and institutions, but it will not include cancelled membership groups.
   * Test if the specified person is a member of the specified institution.
   */
  async personIsMemberOfInst(
    requestParameters: PersonIsMemberOfInstRequest
  ): Promise<InlineResponse20012> {
    const response = await this.personIsMemberOfInstRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the people with the specified identifiers (typically CRSids).  Each identifier may be either a CRSid, or an identifier from another identifier scheme, prefixed with that scheme\'s name and a slash. For example `\"mug99\"` or `\"usn/123456789\"`.  By default, only a few basic details about each person are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.  The results are sorted by identifier scheme and value.  NOTE: The number of people that may be fetched in a single call is limited by the URL path length limit (around 8000 characters). A CRSid is up to 7 characters long, and other identifiers are typically longer, since they must also include the identifier scheme. Thus the number of people that this method may fetch is typically limited to a few hundred.  NOTE: The people returned may include cancelled people. It is the caller\'s repsonsibility to check their cancelled flags.
   * Get the people with the specified identifiers (typically CRSids).
   */
  async personListPeopleRaw(
    requestParameters: PersonListPeopleRequest
  ): Promise<runtime.ApiResponse<InlineResponse2003>> {
    if (
      requestParameters.crsids === null ||
      requestParameters.crsids === undefined
    ) {
      throw new runtime.RequiredError(
        "crsids",
        "Required parameter requestParameters.crsids was null or undefined when calling personListPeople."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.crsids !== undefined) {
      queryParameters["crsids"] = requestParameters.crsids;
    }

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/list`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2003FromJSON(jsonValue)
    );
  }

  /**
   * Get the people with the specified identifiers (typically CRSids).  Each identifier may be either a CRSid, or an identifier from another identifier scheme, prefixed with that scheme\'s name and a slash. For example `\"mug99\"` or `\"usn/123456789\"`.  By default, only a few basic details about each person are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.  The results are sorted by identifier scheme and value.  NOTE: The number of people that may be fetched in a single call is limited by the URL path length limit (around 8000 characters). A CRSid is up to 7 characters long, and other identifiers are typically longer, since they must also include the identifier scheme. Thus the number of people that this method may fetch is typically limited to a few hundred.  NOTE: The people returned may include cancelled people. It is the caller\'s repsonsibility to check their cancelled flags.
   * Get the people with the specified identifiers (typically CRSids).
   */
  async personListPeople(
    requestParameters: PersonListPeopleRequest
  ): Promise<InlineResponse2003> {
    const response = await this.personListPeopleRaw(requestParameters);
    return await response.value();
  }

  /**
   * Find all people modified between the specified pair of transactions.  The transaction IDs specified should be the IDs from two different requests for the last (most recent) transaction ID, made at different times, that returned different values, indicating that some Lookup data was modified in the period between the two requests. This method then determines which (if any) people were affected.  By default, only a few basic details about each person are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.  NOTE: All data returned reflects the latest available data about each person. It is not possible to query for old data, or more detailed information about the specific changes made.
   * Find all people modified between the specified pair of transactions.
   */
  async personModifiedPeopleRaw(
    requestParameters: PersonModifiedPeopleRequest
  ): Promise<runtime.ApiResponse<InlineResponse2003>> {
    if (
      requestParameters.minTxId === null ||
      requestParameters.minTxId === undefined
    ) {
      throw new runtime.RequiredError(
        "minTxId",
        "Required parameter requestParameters.minTxId was null or undefined when calling personModifiedPeople."
      );
    }

    if (
      requestParameters.maxTxId === null ||
      requestParameters.maxTxId === undefined
    ) {
      throw new runtime.RequiredError(
        "maxTxId",
        "Required parameter requestParameters.maxTxId was null or undefined when calling personModifiedPeople."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.minTxId !== undefined) {
      queryParameters["minTxId"] = requestParameters.minTxId;
    }

    if (requestParameters.maxTxId !== undefined) {
      queryParameters["maxTxId"] = requestParameters.maxTxId;
    }

    if (requestParameters.crsids !== undefined) {
      queryParameters["crsids"] = requestParameters.crsids;
    }

    if (requestParameters.includeCancelled !== undefined) {
      queryParameters["includeCancelled"] = requestParameters.includeCancelled;
    }

    if (requestParameters.membershipChanges !== undefined) {
      queryParameters["membershipChanges"] =
        requestParameters.membershipChanges;
    }

    if (requestParameters.instNameChanges !== undefined) {
      queryParameters["instNameChanges"] = requestParameters.instNameChanges;
    }

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/modified-people`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2003FromJSON(jsonValue)
    );
  }

  /**
   * Find all people modified between the specified pair of transactions.  The transaction IDs specified should be the IDs from two different requests for the last (most recent) transaction ID, made at different times, that returned different values, indicating that some Lookup data was modified in the period between the two requests. This method then determines which (if any) people were affected.  By default, only a few basic details about each person are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.  NOTE: All data returned reflects the latest available data about each person. It is not possible to query for old data, or more detailed information about the specific changes made.
   * Find all people modified between the specified pair of transactions.
   */
  async personModifiedPeople(
    requestParameters: PersonModifiedPeopleRequest
  ): Promise<InlineResponse2003> {
    const response = await this.personModifiedPeopleRaw(requestParameters);
    return await response.value();
  }

  /**
   * Search for people using a free text query string. This is the same search function that is used in the Lookup web application.  By default, only a few basic details about each person are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.  NOTE: If the query string starts with the prefix `\"person:\"`, it is treated as an [LQL query](/lql), allowing more advanced searches. An LQL query will ignore the `approxMatches` and `attributes` parameters, but it will respect the values of `includeCancelled` and `misStatus`. In addition, an LQL query will ignore the `orderBy` parameter, since LQL queries always return results in ID order.
   * Search for people using a free text query string.
   */
  async personSearchRaw(
    requestParameters: PersonSearchRequest
  ): Promise<runtime.ApiResponse<InlineResponse2003>> {
    if (
      requestParameters.query === null ||
      requestParameters.query === undefined
    ) {
      throw new runtime.RequiredError(
        "query",
        "Required parameter requestParameters.query was null or undefined when calling personSearch."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.query !== undefined) {
      queryParameters["query"] = requestParameters.query;
    }

    if (requestParameters.approxMatches !== undefined) {
      queryParameters["approxMatches"] = requestParameters.approxMatches;
    }

    if (requestParameters.includeCancelled !== undefined) {
      queryParameters["includeCancelled"] = requestParameters.includeCancelled;
    }

    if (requestParameters.misStatus !== undefined) {
      queryParameters["misStatus"] = requestParameters.misStatus;
    }

    if (requestParameters.attributes !== undefined) {
      queryParameters["attributes"] = requestParameters.attributes;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters["orderBy"] = requestParameters.orderBy;
    }

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/search`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2003FromJSON(jsonValue)
    );
  }

  /**
   * Search for people using a free text query string. This is the same search function that is used in the Lookup web application.  By default, only a few basic details about each person are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.  NOTE: If the query string starts with the prefix `\"person:\"`, it is treated as an [LQL query](/lql), allowing more advanced searches. An LQL query will ignore the `approxMatches` and `attributes` parameters, but it will respect the values of `includeCancelled` and `misStatus`. In addition, an LQL query will ignore the `orderBy` parameter, since LQL queries always return results in ID order.
   * Search for people using a free text query string.
   */
  async personSearch(
    requestParameters: PersonSearchRequest
  ): Promise<InlineResponse2003> {
    const response = await this.personSearchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Count the number of people that would be returned by a search using a free text query string.  NOTE: If the query string starts with the prefix `\"person:\"`, it is treated as an [LQL query](/lql), allowing more advanced searches. An LQL query will ignore the `approxMatches` and `attributes` parameters, but it will respect the values of `includeCancelled` and `misStatus`.
   * Count the number of people that would be returned by a search using a free text query string.
   */
  async personSearchCountRaw(
    requestParameters: PersonSearchCountRequest
  ): Promise<runtime.ApiResponse<InlineResponse2001>> {
    if (
      requestParameters.query === null ||
      requestParameters.query === undefined
    ) {
      throw new runtime.RequiredError(
        "query",
        "Required parameter requestParameters.query was null or undefined when calling personSearchCount."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.query !== undefined) {
      queryParameters["query"] = requestParameters.query;
    }

    if (requestParameters.approxMatches !== undefined) {
      queryParameters["approxMatches"] = requestParameters.approxMatches;
    }

    if (requestParameters.includeCancelled !== undefined) {
      queryParameters["includeCancelled"] = requestParameters.includeCancelled;
    }

    if (requestParameters.misStatus !== undefined) {
      queryParameters["misStatus"] = requestParameters.misStatus;
    }

    if (requestParameters.attributes !== undefined) {
      queryParameters["attributes"] = requestParameters.attributes;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/person/search-count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2001FromJSON(jsonValue)
    );
  }

  /**
   * Count the number of people that would be returned by a search using a free text query string.  NOTE: If the query string starts with the prefix `\"person:\"`, it is treated as an [LQL query](/lql), allowing more advanced searches. An LQL query will ignore the `approxMatches` and `attributes` parameters, but it will respect the values of `includeCancelled` and `misStatus`.
   * Count the number of people that would be returned by a search using a free text query string.
   */
  async personSearchCount(
    requestParameters: PersonSearchCountRequest
  ): Promise<InlineResponse2001> {
    const response = await this.personSearchCountRaw(requestParameters);
    return await response.value();
  }
}
