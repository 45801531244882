import { createContext, FC, useState, useCallback, useEffect, useContext } from "react";

import { useAppConfig } from "../config/AppConfigProvider";
import { ApiGatewayService, AuthState } from "./api-gateway-service";

// Scopes required for the card frontend app to access the required data from Lookup
// and the Card API
const REQUIRED_SCOPES = [
  "openid",
  "profile",
  "email",
  "https://api.apps.cam.ac.uk/card/cards",
  "https://api.apps.cam.ac.uk/card/card-requests",
  "https://api.apps.cam.ac.uk/card/card-logos.readonly",
  "https://api.apps.cam.ac.uk/lookup",
  "https://api.apps.cam.ac.uk/photo/photos",
];

const APIGatewayContext = createContext<ApiGatewayService | null>(null);
export const useAPIGateway = () => {
  const gateway = useContext(APIGatewayContext);
  if (!gateway) {
    throw new Error(
      "API Gateway not available - only use this hook inside an authenticated gateway provider"
    );
  }
  return gateway;
};

interface APIGatewayProviderProps {
  LoginComponent: FC<{ onInitiateLogin: () => void; environment?: string; loading: boolean }>;
  LoadingComponent: FC;
}

export const APIGatewayProvider: FC<APIGatewayProviderProps> = ({
  LoginComponent,
  LoadingComponent,
  children,
}) => {
  const appConfig = useAppConfig();
  const [apiService, setAPIService] = useState<ApiGatewayService | null>(null);
  const [authState, setAuthState] = useState<AuthState>(AuthState.Uninitialized);

  const login = useCallback(() => apiService?.login(), [apiService]);

  useEffect(() => {
    if (appConfig && !apiService) {
      setAPIService(
        new ApiGatewayService(
          appConfig.clientId,
          appConfig.authorizeEndpoint,
          appConfig.tokenEndpoint,
          REQUIRED_SCOPES,
          setAuthState
        )
      );
    }
  }, [appConfig, apiService, setAPIService]);

  if (!appConfig || !apiService || authState === AuthState.Uninitialized) {
    return <LoadingComponent />;
  }
  if ([AuthState.Unauthenticated, AuthState.Redirecting, AuthState.Error].includes(authState)) {
    return (
      <LoginComponent
        onInitiateLogin={login}
        loading={authState === AuthState.Redirecting}
        environment={appConfig.environment !== "production" ? appConfig.environment : undefined}
      />
    );
  }
  return <APIGatewayContext.Provider value={apiService}>{children}</APIGatewayContext.Provider>;
};
