/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  InlineResponse2001,
  InlineResponse2001FromJSON,
  InlineResponse2001ToJSON,
  InlineResponse2003,
  InlineResponse2003FromJSON,
  InlineResponse2003ToJSON,
  InlineResponse2004,
  InlineResponse2004FromJSON,
  InlineResponse2004ToJSON,
  InlineResponse2005,
  InlineResponse2005FromJSON,
  InlineResponse2005ToJSON,
  InlineResponse2006,
  InlineResponse2006FromJSON,
  InlineResponse2006ToJSON,
  InlineResponse2007,
  InlineResponse2007FromJSON,
  InlineResponse2007ToJSON,
  InlineResponse2008,
  InlineResponse2008FromJSON,
  InlineResponse2008ToJSON,
  InlineResponse2009,
  InlineResponse2009FromJSON,
  InlineResponse2009ToJSON,
  InlineResponseDefault,
  InlineResponseDefaultFromJSON,
  InlineResponseDefaultToJSON,
} from "../models";

export interface InstitutionAllInstsRequest {
  includeCancelled?: boolean;
  fetch?: string;
}

export interface InstitutionGetAttributeRequest {
  instid: string;
  attrid: number;
}

export interface InstitutionGetAttributesRequest {
  instid: string;
  attrs: string;
}

export interface InstitutionGetCancelledMembersRequest {
  instid: string;
  fetch?: string;
}

export interface InstitutionGetContactRowsRequest {
  instid: string;
  fetch?: string;
}

export interface InstitutionGetInstRequest {
  instid: string;
  fetch?: string;
}

export interface InstitutionGetMembersRequest {
  instid: string;
  fetch?: string;
}

export interface InstitutionListInstsRequest {
  instids: string;
  fetch?: string;
}

export interface InstitutionModifiedInstsRequest {
  minTxId: number;
  maxTxId: number;
  instids?: string;
  includeCancelled?: boolean;
  contactRowChanges?: boolean;
  membershipChanges?: boolean;
  fetch?: string;
}

export interface InstitutionSearchRequest {
  query: string;
  approxMatches?: boolean;
  includeCancelled?: boolean;
  attributes?: string;
  offset?: number;
  limit?: number;
  orderBy?: string;
  fetch?: string;
}

export interface InstitutionSearchCountRequest {
  query: string;
  approxMatches?: boolean;
  includeCancelled?: boolean;
  attributes?: string;
}

/**
 *
 */
export class InstitutionApi extends runtime.BaseAPI {
  /**
   * Return a list of all the institution attribute schemes available. The `schemeid` values of these schemes may be used in the `fetch` parameter of other methods that return institutions.
   * Return a list of all the institution attribute schemes available.
   */
  async institutionAllAttributeSchemesRaw(): Promise<
    runtime.ApiResponse<InlineResponse2004>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/inst/all-attr-schemes`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2004FromJSON(jsonValue)
    );
  }

  /**
   * Return a list of all the institution attribute schemes available. The `schemeid` values of these schemes may be used in the `fetch` parameter of other methods that return institutions.
   * Return a list of all the institution attribute schemes available.
   */
  async institutionAllAttributeSchemes(): Promise<InlineResponse2004> {
    const response = await this.institutionAllAttributeSchemesRaw();
    return await response.value();
  }

  /**
   * Return a list of all institutions.  By default, only a few basic details about each institution are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.
   * Return a list of all institutions.
   */
  async institutionAllInstsRaw(
    requestParameters: InstitutionAllInstsRequest
  ): Promise<runtime.ApiResponse<InlineResponse2005>> {
    const queryParameters: any = {};

    if (requestParameters.includeCancelled !== undefined) {
      queryParameters["includeCancelled"] = requestParameters.includeCancelled;
    }

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/inst/all-insts`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2005FromJSON(jsonValue)
    );
  }

  /**
   * Return a list of all institutions.  By default, only a few basic details about each institution are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.
   * Return a list of all institutions.
   */
  async institutionAllInsts(
    requestParameters: InstitutionAllInstsRequest
  ): Promise<InlineResponse2005> {
    const response = await this.institutionAllInstsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a specific attribute of an institution.
   * Get a specific attribute of an institution.
   */
  async institutionGetAttributeRaw(
    requestParameters: InstitutionGetAttributeRequest
  ): Promise<runtime.ApiResponse<InlineResponse2009>> {
    if (
      requestParameters.instid === null ||
      requestParameters.instid === undefined
    ) {
      throw new runtime.RequiredError(
        "instid",
        "Required parameter requestParameters.instid was null or undefined when calling institutionGetAttribute."
      );
    }

    if (
      requestParameters.attrid === null ||
      requestParameters.attrid === undefined
    ) {
      throw new runtime.RequiredError(
        "attrid",
        "Required parameter requestParameters.attrid was null or undefined when calling institutionGetAttribute."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/inst/{instid}/{attrid}`
        .replace(
          `{${"instid"}}`,
          encodeURIComponent(String(requestParameters.instid))
        )
        .replace(
          `{${"attrid"}}`,
          encodeURIComponent(String(requestParameters.attrid))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2009FromJSON(jsonValue)
    );
  }

  /**
   * Get a specific attribute of an institution.
   * Get a specific attribute of an institution.
   */
  async institutionGetAttribute(
    requestParameters: InstitutionGetAttributeRequest
  ): Promise<InlineResponse2009> {
    const response = await this.institutionGetAttributeRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get one or more (possibly multi-valued) attributes of an institution. The returned attributes are sorted by attribute scheme precedence and then attribute precedence.
   * Get one or more (possibly multi-valued) attributes of an institution.
   */
  async institutionGetAttributesRaw(
    requestParameters: InstitutionGetAttributesRequest
  ): Promise<runtime.ApiResponse<InlineResponse2008>> {
    if (
      requestParameters.instid === null ||
      requestParameters.instid === undefined
    ) {
      throw new runtime.RequiredError(
        "instid",
        "Required parameter requestParameters.instid was null or undefined when calling institutionGetAttributes."
      );
    }

    if (
      requestParameters.attrs === null ||
      requestParameters.attrs === undefined
    ) {
      throw new runtime.RequiredError(
        "attrs",
        "Required parameter requestParameters.attrs was null or undefined when calling institutionGetAttributes."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.attrs !== undefined) {
      queryParameters["attrs"] = requestParameters.attrs;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/inst/{instid}/get-attributes`.replace(
        `{${"instid"}}`,
        encodeURIComponent(String(requestParameters.instid))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2008FromJSON(jsonValue)
    );
  }

  /**
   * Get one or more (possibly multi-valued) attributes of an institution. The returned attributes are sorted by attribute scheme precedence and then attribute precedence.
   * Get one or more (possibly multi-valued) attributes of an institution.
   */
  async institutionGetAttributes(
    requestParameters: InstitutionGetAttributesRequest
  ): Promise<InlineResponse2008> {
    const response = await this.institutionGetAttributesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get all the cancelled members of the specified institution.  By default, only a few basic details about each member are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of each person.  NOTE: This method returns only cancelled people. It does not include people who were removed from the institution. Cancelled people are no longer considered to be current staff, students or accredited visitors, and are no longer regarded as belonging to any groups or institutions. The list returned here reflects their institutional memberships just before they were cancelled, and so is out-of-date data that should be used with caution.
   * Get all the cancelled members of the specified institution.
   */
  async institutionGetCancelledMembersRaw(
    requestParameters: InstitutionGetCancelledMembersRequest
  ): Promise<runtime.ApiResponse<InlineResponse2003>> {
    if (
      requestParameters.instid === null ||
      requestParameters.instid === undefined
    ) {
      throw new runtime.RequiredError(
        "instid",
        "Required parameter requestParameters.instid was null or undefined when calling institutionGetCancelledMembers."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/inst/{instid}/cancelled-members`.replace(
        `{${"instid"}}`,
        encodeURIComponent(String(requestParameters.instid))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2003FromJSON(jsonValue)
    );
  }

  /**
   * Get all the cancelled members of the specified institution.  By default, only a few basic details about each member are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of each person.  NOTE: This method returns only cancelled people. It does not include people who were removed from the institution. Cancelled people are no longer considered to be current staff, students or accredited visitors, and are no longer regarded as belonging to any groups or institutions. The list returned here reflects their institutional memberships just before they were cancelled, and so is out-of-date data that should be used with caution.
   * Get all the cancelled members of the specified institution.
   */
  async institutionGetCancelledMembers(
    requestParameters: InstitutionGetCancelledMembersRequest
  ): Promise<InlineResponse2003> {
    const response = await this.institutionGetCancelledMembersRaw(
      requestParameters
    );
    return await response.value();
  }

  /**
   * Get all the contact rows of the specified institution.  Any addresses, email addresses, phone numbers and web pages associated with the contact rows are automatically returned, as well as any people referred to by the contact rows.  If any of the contact rows refer to people, then only a few basic details about each person are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of each person.  NOTE: This method will not include cancelled people.
   * Get all the contact rows of the specified institution.
   */
  async institutionGetContactRowsRaw(
    requestParameters: InstitutionGetContactRowsRequest
  ): Promise<runtime.ApiResponse<InlineResponse2007>> {
    if (
      requestParameters.instid === null ||
      requestParameters.instid === undefined
    ) {
      throw new runtime.RequiredError(
        "instid",
        "Required parameter requestParameters.instid was null or undefined when calling institutionGetContactRows."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/inst/{instid}/contact-rows`.replace(
        `{${"instid"}}`,
        encodeURIComponent(String(requestParameters.instid))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2007FromJSON(jsonValue)
    );
  }

  /**
   * Get all the contact rows of the specified institution.  Any addresses, email addresses, phone numbers and web pages associated with the contact rows are automatically returned, as well as any people referred to by the contact rows.  If any of the contact rows refer to people, then only a few basic details about each person are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of each person.  NOTE: This method will not include cancelled people.
   * Get all the contact rows of the specified institution.
   */
  async institutionGetContactRows(
    requestParameters: InstitutionGetContactRowsRequest
  ): Promise<InlineResponse2007> {
    const response = await this.institutionGetContactRowsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the institution with the specified ID.  By default, only a few basic details about the institution are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of the institution.  NOTE: The institution returned may be a cancelled institution. It is the caller\'s repsonsibility to check its cancelled flag.
   * Get the institution with the specified ID.
   */
  async institutionGetInstRaw(
    requestParameters: InstitutionGetInstRequest
  ): Promise<runtime.ApiResponse<InlineResponse2006>> {
    if (
      requestParameters.instid === null ||
      requestParameters.instid === undefined
    ) {
      throw new runtime.RequiredError(
        "instid",
        "Required parameter requestParameters.instid was null or undefined when calling institutionGetInst."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/inst/{instid}`.replace(
        `{${"instid"}}`,
        encodeURIComponent(String(requestParameters.instid))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2006FromJSON(jsonValue)
    );
  }

  /**
   * Get the institution with the specified ID.  By default, only a few basic details about the institution are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of the institution.  NOTE: The institution returned may be a cancelled institution. It is the caller\'s repsonsibility to check its cancelled flag.
   * Get the institution with the specified ID.
   */
  async institutionGetInst(
    requestParameters: InstitutionGetInstRequest
  ): Promise<InlineResponse2006> {
    const response = await this.institutionGetInstRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get all the members of the specified institution.  By default, only a few basic details about each member are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of each person.  NOTE: This method will not include cancelled people.
   * Get all the members of the specified institution.
   */
  async institutionGetMembersRaw(
    requestParameters: InstitutionGetMembersRequest
  ): Promise<runtime.ApiResponse<InlineResponse2003>> {
    if (
      requestParameters.instid === null ||
      requestParameters.instid === undefined
    ) {
      throw new runtime.RequiredError(
        "instid",
        "Required parameter requestParameters.instid was null or undefined when calling institutionGetMembers."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/inst/{instid}/members`.replace(
        `{${"instid"}}`,
        encodeURIComponent(String(requestParameters.instid))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2003FromJSON(jsonValue)
    );
  }

  /**
   * Get all the members of the specified institution.  By default, only a few basic details about each member are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references of each person.  NOTE: This method will not include cancelled people.
   * Get all the members of the specified institution.
   */
  async institutionGetMembers(
    requestParameters: InstitutionGetMembersRequest
  ): Promise<InlineResponse2003> {
    const response = await this.institutionGetMembersRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the institutions with the specified IDs.  By default, only a few basic details about each institution are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.  The results are sorted by ID.  NOTE: The URL path length is limited to around 8000 characters, and an instid is up to 8 characters long. Allowing for comma separators and URL encoding, this limits the number of institutions that this method may fetch to around 700.  NOTE: The institutions returned may include cancelled institutions. It is the caller\'s repsonsibility to check their cancelled flags.
   * Get the institutions with the specified IDs.
   */
  async institutionListInstsRaw(
    requestParameters: InstitutionListInstsRequest
  ): Promise<runtime.ApiResponse<InlineResponse2005>> {
    if (
      requestParameters.instids === null ||
      requestParameters.instids === undefined
    ) {
      throw new runtime.RequiredError(
        "instids",
        "Required parameter requestParameters.instids was null or undefined when calling institutionListInsts."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.instids !== undefined) {
      queryParameters["instids"] = requestParameters.instids;
    }

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/inst/list`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2005FromJSON(jsonValue)
    );
  }

  /**
   * Get the institutions with the specified IDs.  By default, only a few basic details about each institution are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.  The results are sorted by ID.  NOTE: The URL path length is limited to around 8000 characters, and an instid is up to 8 characters long. Allowing for comma separators and URL encoding, this limits the number of institutions that this method may fetch to around 700.  NOTE: The institutions returned may include cancelled institutions. It is the caller\'s repsonsibility to check their cancelled flags.
   * Get the institutions with the specified IDs.
   */
  async institutionListInsts(
    requestParameters: InstitutionListInstsRequest
  ): Promise<InlineResponse2005> {
    const response = await this.institutionListInstsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Find all institutions modified between the specified pair of transactions.  The transaction IDs specified should be the IDs from two different requests for the last (most recent) transaction ID, made at different times, that returned different values, indicating that some Lookup data was modified in the period between the two requests. This method then determines which (if any) institutions were affected.  By default, only a few basic details about each institution are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.  NOTE: All data returned reflects the latest available data about each institution. It is not possible to query for old data, or more detailed information about the specific changes made.
   * Find all institutions modified between the specified pair of transactions.
   */
  async institutionModifiedInstsRaw(
    requestParameters: InstitutionModifiedInstsRequest
  ): Promise<runtime.ApiResponse<InlineResponse2005>> {
    if (
      requestParameters.minTxId === null ||
      requestParameters.minTxId === undefined
    ) {
      throw new runtime.RequiredError(
        "minTxId",
        "Required parameter requestParameters.minTxId was null or undefined when calling institutionModifiedInsts."
      );
    }

    if (
      requestParameters.maxTxId === null ||
      requestParameters.maxTxId === undefined
    ) {
      throw new runtime.RequiredError(
        "maxTxId",
        "Required parameter requestParameters.maxTxId was null or undefined when calling institutionModifiedInsts."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.minTxId !== undefined) {
      queryParameters["minTxId"] = requestParameters.minTxId;
    }

    if (requestParameters.maxTxId !== undefined) {
      queryParameters["maxTxId"] = requestParameters.maxTxId;
    }

    if (requestParameters.instids !== undefined) {
      queryParameters["instids"] = requestParameters.instids;
    }

    if (requestParameters.includeCancelled !== undefined) {
      queryParameters["includeCancelled"] = requestParameters.includeCancelled;
    }

    if (requestParameters.contactRowChanges !== undefined) {
      queryParameters["contactRowChanges"] =
        requestParameters.contactRowChanges;
    }

    if (requestParameters.membershipChanges !== undefined) {
      queryParameters["membershipChanges"] =
        requestParameters.membershipChanges;
    }

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/inst/modified-insts`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2005FromJSON(jsonValue)
    );
  }

  /**
   * Find all institutions modified between the specified pair of transactions.  The transaction IDs specified should be the IDs from two different requests for the last (most recent) transaction ID, made at different times, that returned different values, indicating that some Lookup data was modified in the period between the two requests. This method then determines which (if any) institutions were affected.  By default, only a few basic details about each institution are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.  NOTE: All data returned reflects the latest available data about each institution. It is not possible to query for old data, or more detailed information about the specific changes made.
   * Find all institutions modified between the specified pair of transactions.
   */
  async institutionModifiedInsts(
    requestParameters: InstitutionModifiedInstsRequest
  ): Promise<InlineResponse2005> {
    const response = await this.institutionModifiedInstsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Search for institutions using a free text query string. This is the same search function that is used in the Lookup web application.  By default, only a few basic details about each institution are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.  NOTE: If the query string starts with the prefix `\"inst:\"`, it is treated as an [LQL query](/lql), allowing more advanced searches. An LQL query will ignore the `approxMatches` and `attributes` parameters, but it will respect the value of `includeCancelled`. In addition, an LQL query will ignore the `orderBy` parameter, since LQL queries always return results in ID order.
   * Search for institutions using a free text query string.
   */
  async institutionSearchRaw(
    requestParameters: InstitutionSearchRequest
  ): Promise<runtime.ApiResponse<InlineResponse2005>> {
    if (
      requestParameters.query === null ||
      requestParameters.query === undefined
    ) {
      throw new runtime.RequiredError(
        "query",
        "Required parameter requestParameters.query was null or undefined when calling institutionSearch."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.query !== undefined) {
      queryParameters["query"] = requestParameters.query;
    }

    if (requestParameters.approxMatches !== undefined) {
      queryParameters["approxMatches"] = requestParameters.approxMatches;
    }

    if (requestParameters.includeCancelled !== undefined) {
      queryParameters["includeCancelled"] = requestParameters.includeCancelled;
    }

    if (requestParameters.attributes !== undefined) {
      queryParameters["attributes"] = requestParameters.attributes;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters["orderBy"] = requestParameters.orderBy;
    }

    if (requestParameters.fetch !== undefined) {
      queryParameters["fetch"] = requestParameters.fetch;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/inst/search`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2005FromJSON(jsonValue)
    );
  }

  /**
   * Search for institutions using a free text query string. This is the same search function that is used in the Lookup web application.  By default, only a few basic details about each institution are returned, but the optional `fetch` parameter may be used to fetch additional attributes or references.  NOTE: If the query string starts with the prefix `\"inst:\"`, it is treated as an [LQL query](/lql), allowing more advanced searches. An LQL query will ignore the `approxMatches` and `attributes` parameters, but it will respect the value of `includeCancelled`. In addition, an LQL query will ignore the `orderBy` parameter, since LQL queries always return results in ID order.
   * Search for institutions using a free text query string.
   */
  async institutionSearch(
    requestParameters: InstitutionSearchRequest
  ): Promise<InlineResponse2005> {
    const response = await this.institutionSearchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Count the number of institutions that would be returned by a search using a free text query string.  NOTE: If the query string starts with the prefix `\"inst:\"`, it is treated as an [LQL query](/lql), allowing more advanced searches. An LQL query will ignore the `approxMatches` and `attributes` parameters, but it will respect the value of `includeCancelled`.
   * Count the number of institutions that would be returned by a search using a free text query string.
   */
  async institutionSearchCountRaw(
    requestParameters: InstitutionSearchCountRequest
  ): Promise<runtime.ApiResponse<InlineResponse2001>> {
    if (
      requestParameters.query === null ||
      requestParameters.query === undefined
    ) {
      throw new runtime.RequiredError(
        "query",
        "Required parameter requestParameters.query was null or undefined when calling institutionSearchCount."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.query !== undefined) {
      queryParameters["query"] = requestParameters.query;
    }

    if (requestParameters.approxMatches !== undefined) {
      queryParameters["approxMatches"] = requestParameters.approxMatches;
    }

    if (requestParameters.includeCancelled !== undefined) {
      queryParameters["includeCancelled"] = requestParameters.includeCancelled;
    }

    if (requestParameters.attributes !== undefined) {
      queryParameters["attributes"] = requestParameters.attributes;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "APIGatewayAuth",
          ["https://api.apps.cam.ac.uk/lookup"]
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/inst/search-count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse2001FromJSON(jsonValue)
    );
  }

  /**
   * Count the number of institutions that would be returned by a search using a free text query string.  NOTE: If the query string starts with the prefix `\"inst:\"`, it is treated as an [LQL query](/lql), allowing more advanced searches. An LQL query will ignore the `approxMatches` and `attributes` parameters, but it will respect the value of `includeCancelled`.
   * Count the number of institutions that would be returned by a search using a free text query string.
   */
  async institutionSearchCount(
    requestParameters: InstitutionSearchCountRequest
  ): Promise<InlineResponse2001> {
    const response = await this.institutionSearchCountRaw(requestParameters);
    return await response.value();
  }
}
