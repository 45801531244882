// SearchInput.tsx implements a simple Persistent Search component as described on
// https://material.io/design/navigation/search.html#persistent-search.
//
// It does its best to implement the design as described although it is a little underspec-ed.
// Autocomplete is delegated to the user of the component by means of the onAutoComplete event
// handler and autoCompleteResults prop.
//
// Users of the component should record search history in whichever manner best fits the use case
// of the search input and the most recent search history can be provided in the searchHistory
// prop.
//
// Suggestions (both autocomplete and search history) can have an appearance which differs from the
// textual representation of the query. This is useful in the case of autocomplete, for example,
// where matching sections of the query can be emphasised.
import {
  ComponentProps,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  ReactNode,
  useRef,
  useState,
  useEffect,
  forwardRef,
} from "react";
import {
  Collapse,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Box,
  MenuList,
  MenuItem,
  Typography,
  PaperProps,
  Popover,
} from "@material-ui/core";
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import {
  ArrowBack as BackIcon,
  Clear as ClearIcon,
  HelpOutline as HelpIcon,
  History as HistoryIcon,
  Search as SearchIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: "100%",
  },
}));

// interface
export interface SearchInputRefObject {
  setValue: (value: string) => void;
}

// Interface used for search history and autocomplete suggestions.
export interface SearchInputSuggestion {
  // Search query corresponding to this suggestion.
  query: string;

  // How this suggestion is to be rendered.
  node: ReactNode;
}

/**
 * The SearchInput component is not controlled and so the "value" prop is not present.
 * Applications should not use onInput or onChange but instead use onAutoComplete and
 * onSubmitQuery.
 */
export interface SearchInputProps
  extends Omit<ComponentProps<typeof InputBase>, "value" | "onChange" | "onInput"> {
  /**
   * Handle user submission of new search query. If the submitted query is blank the application
   * should clear any existing search results.
   */
  onSubmitQuery?: (query: string) => void;

  /**
   * Handle autocomplete. When results are available populate the autoCompleteResults prop.
   */
  onAutoComplete?: (query: string) => void;

  /**
   * Any autocomplete search results. Will replace history if non-empty. Any React node can be
   * provided. Applications should listen for the onInput event and set autocomplete appropriately.
   */
  autoCompleteResults?: SearchInputSuggestion[];

  /**
   * Any search history. Will be shown if there are no autocomplete results.
   */
  searchHistory?: SearchInputSuggestion[];

  /**
   * Maximum number of suggestions to show.
   */
  maxSuggestions?: number;

  /**
   * Contents of a "tune search" panel.
   */
  tuneSearchContent?: ReactNode | null;

  /**
   * Optional help popover
   */
  helpPopover?: ReactNode;

  /**
   * The variant of the material the input is displayed on.
   */
  variant?: PaperProps["variant"];

  classes?: ReturnType<typeof useStyles> & ComponentProps<typeof InputBase>["classes"];
}

export const SearchInput = forwardRef<SearchInputRefObject, SearchInputProps>(
  (
    {
      autoCompleteResults = [],
      searchHistory = [],
      maxSuggestions = 4,
      tuneSearchContent = null,
      classes: classesProp,
      onSubmitQuery,
      onAutoComplete,
      onKeyDown,
      onFocus,
      helpPopover,
      variant,
      ...inputProps
    },
    ref
  ) => {
    const theme = useTheme();
    const classes = useStyles({ classes: classesProp });
    const inputRef = useRef<HTMLInputElement>(null);
    const firstMenuItemRef = useRef<HTMLLIElement>(null);
    const [value, setValue] = useState("");

    // Should we show suggestions if we have any?
    const [showSuggestions, setShowSuggestions] = useState(false);

    // Element the optional help popover is anchored too
    const [helpAnchor, setHelpAnchor] = useState<HTMLButtonElement | null>(null);

    // create a ref object and set it on the forwarded ref
    useEffect(() => {
      const refObject = { setValue };
      if (typeof ref === "function") {
        ref(refObject);
      } else if (ref) {
        ref.current = refObject;
      }
    }, [ref]);

    // How many of each should we show? We prioritise autocomplete suggestions.
    const autoCompleteCount = Math.min(autoCompleteResults.length, maxSuggestions);
    const searchHistoryCount =
      autoCompleteCount < maxSuggestions
        ? Math.min(searchHistory.length, maxSuggestions - autoCompleteCount)
        : 0;

    // Some boolean values to make it a bit more obvious what we're testing for below.
    const haveSuggestions = autoCompleteCount + searchHistoryCount > 0;
    const isEmpty = value === "";

    // Submit the passed value to the application as a new search.
    const submit = (s: string) => {
      setShowSuggestions(false);
      inputRef.current?.blur();
      onSubmitQuery?.(s);
    };

    // Activating the "clear" button will clear the current value but *not* submit it. It will move
    // focus back to the input.
    const handleClear = () => {
      setValue("");
      onAutoComplete?.("");
      inputRef.current?.focus();
    };

    // Activating the "back" button clears the current value and *does* submit it. It will blur the
    // input.
    const handleBack = () => {
      setValue("");
      onAutoComplete?.("");
      submit("");
      inputRef.current?.blur();
    };

    // Activating the search button switches focus to the input element.
    const handleSearch = () => inputRef.current?.focus();

    // When the input element gains the focus, suggestions are shown.
    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      setShowSuggestions(true);
      return onFocus?.(e);
    };

    // When the input element receives input, update the stored value state.
    const handleInput = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setValue(value);
      onAutoComplete?.(value);
    };

    // Event handlers which allow arrow keys to be used to navigate between the input box and the
    // autocomplete menu if present. We also allow consumers of this component to set the onKeyDown
    // event handler since this component acts like an input component.
    //
    // Following https://material.io/design/navigation/search.html#persistent-search, pressing Enter
    // submits the search and removes focus. Following usual conventions, Escape can be used to blur
    // the input without submitting the result.

    const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "ArrowUp" || e.key === "Escape") {
        setShowSuggestions(false);
        inputRef.current?.blur();
        e.preventDefault();
        return;
      }
      if (e.key === "ArrowDown" && showSuggestions) {
        firstMenuItemRef.current?.focus();
        e.preventDefault();
        return;
      }
      if (e.key === "Enter") {
        submit(value);
        e.preventDefault();
        return;
      }
      return onKeyDown?.(e);
    };

    const handleFirstMenuItemKeyDown = (e: KeyboardEvent<HTMLLIElement>) => {
      if (e.key === "ArrowUp") {
        inputRef.current?.focus();
        e.preventDefault();
      }
    };

    // The start adornment is either the back or search buttons depending on the current state.
    const startAdornment = (
      <Box width={theme.spacing(6)} flexGrow={0} flexShrink={0}>
        {isEmpty ? (
          <IconButton size="small" edge="start" onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        ) : (
          <IconButton size="small" edge="start" onClick={handleBack}>
            <BackIcon />
          </IconButton>
        )}
      </Box>
    );

    // Handlers for optional popover for help
    const helpClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setHelpAnchor(event.currentTarget);
    };
    const helpClose = () => setHelpAnchor(null);
    const openHelp = Boolean(helpAnchor);

    // The "clear" icon button is only shown as an end adornment if the input is not empty
    // otherwise show the "help" icon button if helpPopover has been provided
    const endAdornment = !isEmpty ? (
      <IconButton size="small" edge="end" onClick={handleClear}>
        <ClearIcon />
      </IconButton>
    ) : (
      helpPopover && (
        <>
          <IconButton size="small" edge="end" onClick={helpClick}>
            <HelpIcon />
          </IconButton>
          <Popover
            open={openHelp}
            onClose={helpClose}
            anchorEl={helpAnchor}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {helpPopover}
          </Popover>
        </>
      )
    );

    return (
      <Paper variant={variant}>
        <InputBase
          classes={{ root: classes.inputRoot }}
          value={value}
          inputRef={inputRef}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          onKeyDown={handleInputKeyDown}
          onFocus={handleFocus}
          onInput={handleInput}
          ref={ref}
          {...inputProps}
        />
        <Collapse in={showSuggestions && haveSuggestions}>
          <Divider />
          <MenuList disableListWrap={true}>
            {autoCompleteResults.slice(0, autoCompleteCount).map(({ query, node }, index) => {
              const extraProps =
                index === 0
                  ? { onKeyDown: handleFirstMenuItemKeyDown, ref: firstMenuItemRef }
                  : {};
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setValue(query);
                    submit(query);
                  }}
                  disableGutters
                  {...extraProps}
                >
                  <Box px={8}>
                    <Typography variant="body2" component="span">
                      {node}
                    </Typography>
                  </Box>
                </MenuItem>
              );
            })}
            {searchHistory.slice(0, searchHistoryCount).map(({ query, node }, index) => {
              const extraProps =
                index === 0 && autoCompleteCount === 0
                  ? { onKeyDown: handleFirstMenuItemKeyDown, ref: firstMenuItemRef }
                  : {};
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setValue(query);
                    submit(query);
                  }}
                  disableGutters
                  {...extraProps}
                >
                  <Box display="flex" pl={2} pr={8}>
                    <Box width={theme.spacing(6)} height={theme.spacing(1)}>
                      <HistoryIcon color="disabled" />
                    </Box>
                    <Box flexGrow={1}>
                      <Typography variant="body2" component="span">
                        {node}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>
              );
            })}
          </MenuList>
        </Collapse>
        <Collapse in={tuneSearchContent !== null && !(showSuggestions && haveSuggestions)}>
          <Divider />
          {tuneSearchContent}
        </Collapse>
      </Paper>
    );
  }
);
