import { ComponentProps } from "react";
import { Grid, Paper } from "@material-ui/core";
import { styled, Theme, useTheme } from "@material-ui/core/styles";

export const FullPageGrid = styled(Grid)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  height: "100vh",
  margin: "0 auto",
  justifyContent: "center",
  alignItems: "center",
}));

export const FullWidthPage = styled(Paper)({
  flexGrow: 1,
  overflow: "hidden",
  padding: "2rem 1.5rem",
  margin: "2rem",
});

interface MaxWidthContentProps extends ComponentProps<"div"> {
  maxWidth?: keyof Theme["breakpoints"]["values"];
}

/**
 * A component which can be used to fix the _maximum_ width of a page to one of the
 * breakpoints in the current theme. If maxWidth is not provided, it defaults to "lg".
 */
export const MaxWidthContent = ({
  maxWidth = "lg",
  style = {},
  ...rootProps
}: MaxWidthContentProps) => {
  const theme = useTheme();
  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: theme.breakpoints.values[maxWidth],
        ...style,
      }}
      {...rootProps}
    />
  );
};
