/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ContactRow,
  ContactRowFromJSON,
  ContactRowFromJSONTyped,
  ContactRowToJSON,
} from "./";

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 * @export
 * @interface InlineResponse2007Result
 */
export interface InlineResponse2007Result {
  /**
   *
   * @type {Array<ContactRow>}
   * @memberof InlineResponse2007Result
   */
  institutionContactRows?: Array<ContactRow>;
}

export function InlineResponse2007ResultFromJSON(
  json: any
): InlineResponse2007Result {
  return InlineResponse2007ResultFromJSONTyped(json, false);
}

export function InlineResponse2007ResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineResponse2007Result {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    institutionContactRows: !exists(json, "institution.contactRows")
      ? undefined
      : (json["institution.contactRows"] as Array<any>).map(ContactRowFromJSON),
  };
}

export function InlineResponse2007ResultToJSON(
  value?: InlineResponse2007Result | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    "institution.contactRows":
      value.institutionContactRows === undefined
        ? undefined
        : (value.institutionContactRows as Array<any>).map(ContactRowToJSON),
  };
}
