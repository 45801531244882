import { FC, useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Hotjar from "@hotjar/browser";

import { useAPIGateway } from "../api-gateway/APIGatewayProvider";
import { useCardPermissions } from "../api/card-api";
import { usePhotoPermissions } from "../api/photo-api";
import { useAppConfig } from "../config/AppConfigProvider";
import { PeoplePage, PersonDetailPage } from "../people";
import { PrintQueuePage } from "../print-queue";

import { EnvironmentWarning } from "./EnvironmentWarning";
import { NavigationWrapper } from "./NavigationWrapper";
import { NotAllowed } from "./NotAllowed";
import { ExportPage } from "./ExportPage";
import { FeedbackPage } from "./FeedbackPage";
import { PhotoReviewPage } from "./PhotoReviewPage";
import { DebugPanel } from "./DebugPanel";
import { DashboardPage } from "./DashboardPage";

export const CardAdminLayout = ({ LoadingComponent }: { LoadingComponent: FC }) => {
  const { logout, user } = useAPIGateway();
  const { environment, hotjarSiteId, hotjarVersion } = useAppConfig(true);
  const { isLoading: isCardPermissionsLoading, permissions: cardPermissions } =
    useCardPermissions();
  const { isLoading: isPhotoPermissionsLoading, permissions: photoPermissions } =
    usePhotoPermissions();

  const { displayName, crsid } = user ?? { displayName: "Unknown user", crsid: "unknown" };

  const isLoading = isCardPermissionsLoading || isPhotoPermissionsLoading;

  const canViewCardholders = !!cardPermissions?.includes("CARD_DATA_READER");
  const canReviewPhotos = !!(
    photoPermissions?.includes("PHOTO_VIEWER") && photoPermissions?.includes("PHOTO_REVIEWER")
  );
  const canViewAnalytics = !!cardPermissions?.includes("CARD_ANALYTICS_READER");
  const permissions = {
    canViewCardholders,
    canReviewPhotos,
    canViewAnalytics,
  };

  useEffect(() => {
    if (hotjarSiteId && hotjarVersion) {
      Hotjar.init(hotjarSiteId, hotjarVersion);
    }
  }, [hotjarSiteId, hotjarVersion]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <BrowserRouter>
      <NavigationWrapper
        username={`(${crsid})`}
        displayName={displayName}
        permissions={permissions}
        onLogOut={logout}
      >
        {environment !== "production" ? <EnvironmentWarning environment={environment} /> : null}
        <DebugPanel />
        <Switch>
          <Route exact path="/">
            {canViewCardholders && <Redirect to="/cardholders" />}
            {!canViewCardholders && canReviewPhotos && <Redirect to="/photo-review" />}
          </Route>
          <Route exact path="/cardholders">
            {canViewCardholders && <PeoplePage />}
            {!canViewCardholders && (
              <NotAllowed logout={logout} message="You do not have permission to view card data" />
            )}
          </Route>
          <Route exact path="/cardholders/:personIdValue@:personIdScheme">
            {canViewCardholders && <PersonDetailPage />}
            {!canViewCardholders && (
              <NotAllowed logout={logout} message="You do not have permission to view card data" />
            )}
          </Route>
          <Route exact path="/print-queue">
            {canViewCardholders && <PrintQueuePage />}
            {!canViewCardholders && (
              <NotAllowed logout={logout} message="You do not have permission to view card data" />
            )}
          </Route>
          <Route exact path="/card-requests">
            {canViewCardholders && <Redirect to="/print-queue" />}
            {!canViewCardholders && (
              <NotAllowed logout={logout} message="You do not have permission to view card data" />
            )}
          </Route>
          <Route exact path="/photo-review">
            {canReviewPhotos && <PhotoReviewPage />}
            {!canReviewPhotos && (
              <NotAllowed logout={logout} message="You do not have permission to review photos" />
            )}
          </Route>
          <Route exact path="/exports">
            {canViewCardholders && <ExportPage />}
            {!canViewCardholders && (
              <NotAllowed
                logout={logout}
                message="You do not have permission to export card data"
              />
            )}
          </Route>
          <Route exact path="/feedback">
            <FeedbackPage />
          </Route>
          {canViewAnalytics && (
            <Route exact path="/dashboard">
              <DashboardPage />
            </Route>
          )}
        </Switch>
      </NavigationWrapper>
    </BrowserRouter>
  );
};
