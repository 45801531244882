import { Button, CardActions, CardContent, CardHeader } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FullPageGrid } from "../styles/layout";
import { ErrorContainer } from "../errors/ErrorBoundary";

export const NotAllowed = ({ message, logout }: { message: string; logout: () => void }) => {
  return (
    <FullPageGrid container>
      <ErrorContainer variant="outlined">
        <CardHeader title="Not allowed"></CardHeader>
        <CardContent>
          <Alert severity="warning">{message}</Alert>
        </CardContent>
        <CardActions>
          <Button onClick={logout}>Logout</Button>
        </CardActions>
      </ErrorContainer>
    </FullPageGrid>
  );
};
