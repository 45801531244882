import {
  Grid,
  makeStyles,
  Typography,
  Chip,
  Hidden,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { CardIdentifierSummarySchemeEnum } from "../api/card-api";
import { QueryType } from "../people";

const useClasses = makeStyles({
  searchTypeIndicator: {
    margin: "auto 0.5rem auto 0",
  },
});

export const queryTypeOptions: Map<QueryType, string> = new Map([
  ["ALL", "All"],
  ["NAME", "Name"],
  [CardIdentifierSummarySchemeEnum.V1PersonIdentifiersCamAcUk, "CRSid"],
  [CardIdentifierSummarySchemeEnum.PersonV1StudentRecordsUniversityIdentifiersCamAcUk, "USN"],
  [CardIdentifierSummarySchemeEnum.MifareNumberV1CardUniversityIdentifiersCamAcUk, "UCam Card ID"],
  [
    CardIdentifierSummarySchemeEnum.MifareIdentifierV1CardUniversityIdentifiersCamAcUk,
    "Manufacturer Card UID",
  ],
  [CardIdentifierSummarySchemeEnum.BarcodeV1CardUniversityIdentifiersCamAcUk, "Barcode"],
  [
    CardIdentifierSummarySchemeEnum.PersonV1LegacyCardUniversityIdentifiersCamAcUk,
    "Legacy Cardholder ID",
  ],
] as ReadonlyArray<[QueryType, string]>);

export interface QueryTypeSelectorProps {
  selectedQueryType: QueryType;
  updateQueryType: (queryType: QueryType) => void;
}

export const QueryTypeSelector = ({
  selectedQueryType,
  updateQueryType,
}: QueryTypeSelectorProps) => {
  const classes = useClasses();

  return (
    <Grid container direction="row" alignContent="center" spacing={1}>
      <Hidden smDown={true}>
        <Typography variant="body2" className={classes.searchTypeIndicator}>
          Search using
        </Typography>
        {[...queryTypeOptions.entries()].map(([searchOption, label]) => (
          <Chip
            clickable
            key={searchOption}
            className={classes.searchTypeIndicator}
            label={label}
            color={searchOption === selectedQueryType ? "secondary" : "default"}
            variant={searchOption === selectedQueryType ? "default" : "outlined"}
            onClick={() => updateQueryType(searchOption)}
          />
        ))}
      </Hidden>
      <Hidden mdUp={true}>
        <TextField
          select
          fullWidth
          variant="outlined"
          size="small"
          label="Search using"
          value={selectedQueryType}
          onChange={({ target }) => updateQueryType(target.value as QueryType)}
        >
          {[...queryTypeOptions.entries()].map(([searchOption, label]) => (
            <MenuItem key={searchOption} value={searchOption}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Hidden>
    </Grid>
  );
};
