/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Institution,
  InstitutionFromJSON,
  InstitutionFromJSONTyped,
  InstitutionToJSON,
} from "./";

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 * @export
 * @interface InlineResponse2005Result
 */
export interface InlineResponse2005Result {
  /**
   *
   * @type {Array<Institution>}
   * @memberof InlineResponse2005Result
   */
  institutions?: Array<Institution>;
}

export function InlineResponse2005ResultFromJSON(
  json: any
): InlineResponse2005Result {
  return InlineResponse2005ResultFromJSONTyped(json, false);
}

export function InlineResponse2005ResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineResponse2005Result {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    institutions: !exists(json, "institutions")
      ? undefined
      : (json["institutions"] as Array<any>).map(InstitutionFromJSON),
  };
}

export function InlineResponse2005ResultToJSON(
  value?: InlineResponse2005Result | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    institutions:
      value.institutions === undefined
        ? undefined
        : (value.institutions as Array<any>).map(InstitutionToJSON),
  };
}
