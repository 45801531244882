/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Class representing an attribute of a person or institution returned by
 * the web service API. Note that for institution attributes, the instid,
 * visibility and owningGroupid fields will be `null`.
 * @export
 * @interface Attribute
 */
export interface Attribute {
  /**
   * The unique internal identifier of the attribute.
   * @type {number}
   * @memberof Attribute
   */
  attrid?: number;
  /**
   * The attribute's scheme.
   * @type {string}
   * @memberof Attribute
   */
  scheme?: string;
  /**
   * The attribute's value (except for binary attributes).
   * @type {string}
   * @memberof Attribute
   */
  value?: string;
  /**
   * Any comment associated with the attribute.
   * @type {string}
   * @memberof Attribute
   */
  comment?: string;
  /**
   * For a person attribute, the optional institution that the attribute
   * is associated with. This will not be set for institution attributes.
   * @type {string}
   * @memberof Attribute
   */
  instid?: string;
  /**
   * For a person attribute, it's visibility (`"private"`,
   * `"institution"`, `"university"` or `"world"`). This
   * will not be set for institution attributes.
   * @type {string}
   * @memberof Attribute
   */
  visibility?: string;
  /**
   * For time-limited attributes, the date from which it takes effect.
   * @type {Date}
   * @memberof Attribute
   */
  effectiveFrom?: Date;
  /**
   * For time-limited attributes, the date after which it is no longer effective.
   * @type {Date}
   * @memberof Attribute
   */
  effectiveTo?: Date;
  /**
   * For a person attribute, the ID of the group that owns it (typically
   * the user agent group that created it).
   * @type {string}
   * @memberof Attribute
   */
  owningGroupid?: string;
}

export function AttributeFromJSON(json: any): Attribute {
  return AttributeFromJSONTyped(json, false);
}

export function AttributeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Attribute {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    attrid: !exists(json, "attrid") ? undefined : json["attrid"],
    scheme: !exists(json, "scheme") ? undefined : json["scheme"],
    value: !exists(json, "value") ? undefined : json["value"],
    comment: !exists(json, "comment") ? undefined : json["comment"],
    instid: !exists(json, "instid") ? undefined : json["instid"],
    visibility: !exists(json, "visibility") ? undefined : json["visibility"],
    effectiveFrom: !exists(json, "effectiveFrom")
      ? undefined
      : new Date(json["effectiveFrom"]),
    effectiveTo: !exists(json, "effectiveTo")
      ? undefined
      : new Date(json["effectiveTo"]),
    owningGroupid: !exists(json, "owningGroupid")
      ? undefined
      : json["owningGroupid"],
  };
}

export function AttributeToJSON(value?: Attribute | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    attrid: value.attrid,
    scheme: value.scheme,
    value: value.value,
    comment: value.comment,
    instid: value.instid,
    visibility: value.visibility,
    effectiveFrom:
      value.effectiveFrom === undefined
        ? undefined
        : value.effectiveFrom.toISOString(),
    effectiveTo:
      value.effectiveTo === undefined
        ? undefined
        : value.effectiveTo.toISOString(),
    owningGroupid: value.owningGroupid,
  };
}
