/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  InlineResponse2003Result,
  InlineResponse2003ResultFromJSON,
  InlineResponse2003ResultFromJSONTyped,
  InlineResponse2003ResultToJSON,
} from "./";

/**
 *
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
  /**
   *
   * @type {InlineResponse2003Result}
   * @memberof InlineResponse2003
   */
  result?: InlineResponse2003Result;
}

export function InlineResponse2003FromJSON(json: any): InlineResponse2003 {
  return InlineResponse2003FromJSONTyped(json, false);
}

export function InlineResponse2003FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineResponse2003 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    result: !exists(json, "result")
      ? undefined
      : InlineResponse2003ResultFromJSON(json["result"]),
  };
}

export function InlineResponse2003ToJSON(
  value?: InlineResponse2003 | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    result: InlineResponse2003ResultToJSON(value.result),
  };
}
