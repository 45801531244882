import { useState } from "react";

import { Button, Grid, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";

interface EditableFieldProps {
  fieldOptions?: string[];
  currentValue: string;
  isEditable: boolean;
  onChangeValue: (value: string) => void;
  optionToDisplayValue?: (value: string) => string;
}

export function EditableField({
  fieldOptions,
  currentValue,
  isEditable,
  onChangeValue,
  optionToDisplayValue,
}: EditableFieldProps) {
  const [inputValue, setInputValue] = useState(currentValue);
  const [intermediateValue, setIntermediateValue] = useState(currentValue);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {!isEditing ? (
        // Explicitly setting the margin appears to be required here for some reason,
        // without it the text is not aligned correctly.
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
          style={{ marginLeft: "-0.5rem" }}
        >
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {optionToDisplayValue?.(currentValue) || currentValue}
            </Typography>
          </Grid>
          {isEditable && (
            <Grid item>
              <Button
                variant="contained"
                onClick={() => setIsEditing(true)}
                size="small"
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
          style={{ textAlign: "center" }}
        >
          <Grid item xs={12} sm={8}>
            <Autocomplete
              options={fieldOptions || []}
              getOptionLabel={(option) => optionToDisplayValue?.(option) ?? ""}
              style={{ width: "100%" }}
              value={intermediateValue}
              onChange={(_, newValue) => setIntermediateValue(newValue ?? "")}
              inputValue={inputValue}
              onInputChange={(_, newInputValue) =>
                setInputValue(newInputValue === "-" ? "" : newInputValue)
              }
              renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
            />
          </Grid>

          <Grid item xs={6} sm={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onChangeValue(intermediateValue);
                setIsEditing(false);
              }}
              size="small"
              startIcon={<CheckIcon />}
            >
              Save
            </Button>
          </Grid>

          <Grid item xs={6} sm={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setIntermediateValue(currentValue);
                setIsEditing(false);
              }}
              size="small"
              startIcon={<ClearIcon />}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}
