import { FC, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Hidden,
  Divider,
  Link,
  LinearProgress,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import Hotjar from "@hotjar/browser";

import backgroundSVG from "../images/login-background.svg";
import shieldSVG from "../images/shield.svg";
import { FullPageGrid } from "../styles/layout";
import { rolesAndResponsibilitiesLink } from "../config/consts";
import { useAppConfig } from "../config/AppConfigProvider";

const BackgroundGrid = styled(FullPageGrid)(({ theme }) => ({
  "&::before": {
    content: "' '",
    display: "block",
    position: "absolute",
    width: "75vw",
    height: "100vh",
    background: theme.palette.primary.light,
    opacity: 0.5,
    transform: "skew(30deg)",
    zIndex: -1,
  },
}));
const CardImage = styled(Box)({
  width: "80%",
  height: "80vh",
  maxWidth: "30rem",
  backgroundImage: `url(${backgroundSVG})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "center",
});
const ContainerGrid = styled(Grid)({ maxWidth: "1600px" });
const FullHeightGridItem = styled(Grid)({ justifyContent: "center", alignItems: "center" });
const PaperContainer = styled(Paper)({ overflow: "hidden" });
const Title = styled(Typography)({ textAlign: "center" });
const ExplanationText = styled(Typography)({ margin: "1.5rem auto", textAlign: "center" });
const LoginButton = styled(Button)({ display: "block", margin: "2rem auto " });
const ShieldImage = styled("img")({ margin: "0 auto 1rem", width: "3rem", display: "block" });

export const Login: FC<{
  onInitiateLogin: () => void;
  environment?: string;
  loading: boolean;
}> = ({ onInitiateLogin, environment, loading }) => {
  const { hotjarSiteId, hotjarVersion } = useAppConfig(true);

  useEffect(() => {
    if (hotjarSiteId && hotjarVersion) {
      Hotjar.init(hotjarSiteId, hotjarVersion);
    }
  }, [hotjarSiteId, hotjarVersion]);

  return (
    <BackgroundGrid container>
      <ContainerGrid container>
        <FullHeightGridItem md={6} container item>
          <Box m={5}>
            <PaperContainer elevation={6}>
              {loading ? <LinearProgress /> : null}
              <Box p={5}>
                <ShieldImage src={shieldSVG} alt="University Shield" />
                <Title variant="h5">University Card Management</Title>
                <ExplanationText>
                  This system can be used to view and manage University Cards.
                </ExplanationText>
                <ExplanationText>
                  This is a new service and is intended for use by recognised card representatives.
                </ExplanationText>
                {environment ? (
                  <Alert severity="warning">
                    This is a {environment} environment - changes will not be propagated to live
                    systems
                  </Alert>
                ) : null}
                <LoginButton
                  variant="contained"
                  color="primary"
                  onClick={onInitiateLogin}
                  disabled={loading}
                >
                  Login with Raven credentials
                </LoginButton>
                <Divider />
                <ExplanationText variant="body2">
                  By logging in to this service you are agreeing to these&nbsp;
                  <Link href={rolesAndResponsibilitiesLink} color="secondary" target="_blank">
                    Roles and Responsibilities
                  </Link>
                </ExplanationText>
                <ExplanationText variant="body2">
                  Developed and maintained by the&nbsp;
                  <Link href="https://guidebook.devops.uis.cam.ac.uk/en/latest/" color="secondary">
                    UIS Devops Division
                  </Link>
                </ExplanationText>
              </Box>
            </PaperContainer>
          </Box>
        </FullHeightGridItem>
        <Hidden smDown>
          <FullHeightGridItem md={6} sm={false} container item>
            <CardImage />
          </FullHeightGridItem>
        </Hidden>
      </ContainerGrid>
    </BackgroundGrid>
  );
};
