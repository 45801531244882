import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  alignCenter: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noResultsImage: {
    maxWidth: "40vw",
    maxHeight: "40vh",
  },
  progressBox: {
    alignItems: "center",
    aspectRatio: String(5 / 3),
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  reviewButton: {
    width: "9rem",
  },
  customImage: {
    aspectRatio: "0.8333333333333334",
    objectFit: "contain",
    maxWidth: "100%",
    border: "3px solid grey",
  },
  profileActions: {
    display: "flex",
  },
  visualizationContainer: {
    margin: "1rem auto",
    display: "block",
    overflow: "hidden",
    maxWidth: "95%",
    maxHeight: "30vh",
    aspectRatio: "5 / 3.25",
    animationName: "$appear",
    animationDuration: `${theme.transitions.duration.complex}ms`,
    animationTimingFunction: theme.transitions.easing.easeInOut,
    animationFillMode: "both",
  },
  "@keyframes appear": {
    from: {
      opacity: 0,
      transform: `translateY(${theme.spacing(2)}px)`,
    },
    to: {
      opacity: 1,
    },
  },
  detailsAccordion: {
    boxShadow: "none",
  },
  accordionStaticButton: {
    transform: "rotate(0deg)",
    timeout: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  helpTooltip: {
    maxWidth: 300,
  },
  helpOutlineIcon: {
    marginLeft: "6px",
  },
  skeletonListItem: {
    maxWidth: "10rem",
    marginLeft: "1rem",
  },
}));
