/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  InlineResponse20012Result,
  InlineResponse20012ResultFromJSON,
  InlineResponse20012ResultFromJSONTyped,
  InlineResponse20012ResultToJSON,
} from "./";

/**
 *
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
  /**
   *
   * @type {InlineResponse20012Result}
   * @memberof InlineResponse20012
   */
  result?: InlineResponse20012Result;
}

export function InlineResponse20012FromJSON(json: any): InlineResponse20012 {
  return InlineResponse20012FromJSONTyped(json, false);
}

export function InlineResponse20012FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineResponse20012 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    result: !exists(json, "result")
      ? undefined
      : InlineResponse20012ResultFromJSON(json["result"]),
  };
}

export function InlineResponse20012ToJSON(
  value?: InlineResponse20012 | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    result: InlineResponse20012ResultToJSON(value.result),
  };
}
