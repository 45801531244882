import { List, styled } from "@material-ui/core";

/**
 * Styled List which lays out ListItems in columns if necessary.
 */
export const AttributeList = styled(List)({
  "&": {
    columnWidth: "100%",
  },

  "& li": {
    breakInside: "avoid",
  },
});
