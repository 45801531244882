import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import RedoIcon from "@material-ui/icons/Redo";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { MaxWidthContent } from "../styles/layout";
import { useStyles } from "../styles/styles";
import { PhotoRejectDialog } from "../components/PhotoRejectDialog";
import {
  useOldestUnapprovedPhoto,
  useReviewPhoto,
  PrimaryIdentifierScheme,
} from "../api/photo-api";
import { CRSID_SCHEME } from "../api/card-api";

import noResultsSVG from "../images/no-results.svg";

export const PhotoReviewPage = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Fetch the oldest unapproved photo
  const {
    isLoading: isUnapprovedPhotoLoading,
    photo: unapprovedPhoto,
    photoContent: unapprovedPhotoContent,
    existsNextPhoto,
  } = useOldestUnapprovedPhoto((msg, severity) => enqueueSnackbar(msg, { variant: severity }));
  // Mutations for reviewing an unapproved photo
  const {
    approvePhoto,
    isLoading: isReviewOperationInProgress,
    rejectPhoto,
    skipPhoto,
  } = useReviewPhoto({
    id: unapprovedPhoto?.id,
    onResult: (msg, severity) => enqueueSnackbar(msg, { variant: severity }),
  });

  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);

  // Primary id is the CRSId, or 'Unknown' if either the photo is undefined, or has no identifier
  // with the CRSId scheme.
  const primaryId = unapprovedPhoto
    ? unapprovedPhoto.identifiers.find((ident) => ident.scheme === PrimaryIdentifierScheme) || {
        value: "Unknown",
      }
    : { value: "Unknown" };

  const isLoading = isUnapprovedPhotoLoading || isReviewOperationInProgress;

  return (
    <MaxWidthContent style={{ width: "100%" }}>
      <Box m={2}>
        <Grid container spacing={2} alignItems="stretch" justifyContent="center">
          {isLoading && !unapprovedPhoto && (
            <Grid item xs={12} md={12} lg={12}>
              <Box className={classes.progressBox}>
                <CircularProgress size={60} />
              </Box>
            </Grid>
          )}
          {!isLoading && !unapprovedPhoto && (
            <Grid item xs={12} md={12} lg={12}>
              <Box pt={10} textAlign="center">
                <img className={classes.noResultsImage} src={noResultsSVG} alt="No results" />
              </Box>
              <Box pt={6} textAlign="center">
                <Typography color="textSecondary" variant="subtitle1">
                  No photos in photo review queue.
                </Typography>
              </Box>
            </Grid>
          )}
          {unapprovedPhoto && (
            <>
              <PhotoRejectDialog
                open={rejectModalOpen}
                onReject={(rejectReason?: string) =>
                  rejectPhoto.mutate({ rejectReason: rejectReason })
                }
                onClose={() => setRejectModalOpen(false)}
              />
              <Grid item xs={12} md={6} lg={6}>
                <Card>
                  <Box display="flex" flexDirection="column" width="100%">
                    <CardContent>
                      <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <Typography variant="h5" component="h5">
                          Photo under review
                        </Typography>
                      </Box>
                    </CardContent>
                    <Divider />
                    <CardContent>
                      <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <Box className={classes.alignCenter}>
                          <Box>
                            <img
                              className={classes.customImage}
                              src={unapprovedPhotoContent}
                              alt="For review"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Card>
                  <Box display="flex" flexDirection="column" width="100%">
                    <CardContent>
                      <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <Typography variant="h5" component="h5">
                          Review
                        </Typography>
                      </Box>
                    </CardContent>
                    <Divider />
                    <CardContent>
                      <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <Typography variant="body2" paragraph={true}>
                          <List disablePadding dense>
                            <ListItem disableGutters>
                              <ListItemText secondary={primaryId.value} primary="CRSid" />
                            </ListItem>
                            <ListItem disableGutters>
                              <ListItemText secondary={unapprovedPhoto.id} primary="Photo UUID" />
                            </ListItem>
                            <ListItem disableGutters>
                              <ListItemText
                                secondary={unapprovedPhoto.submittedBy.replace(
                                  `@${CRSID_SCHEME}`,
                                  ""
                                )}
                                primary="Submitted by"
                              />
                            </ListItem>
                            <ListItem disableGutters>
                              <ListItemText
                                secondary={
                                  unapprovedPhoto.createdAt
                                    ? unapprovedPhoto.createdAt.toLocaleString([], {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                      })
                                    : ""
                                }
                                primary="Upload date"
                              />
                            </ListItem>
                          </List>
                        </Typography>
                      </Box>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Box display="flex" justifyContent="space-evenly" width="100%">
                        <Box p={2}>
                          <Tooltip title="Reject the photo">
                            <span>
                              <Button
                                className={classes.reviewButton}
                                startIcon={<ClearIcon />}
                                color="secondary"
                                variant="contained"
                                size="large"
                                disabled={isLoading}
                                onClick={() => setRejectModalOpen(true)}
                              >
                                Reject
                              </Button>
                            </span>
                          </Tooltip>
                        </Box>
                        <Box p={2}>
                          <Tooltip title="Skip the photo">
                            <span>
                              <Button
                                className={classes.reviewButton}
                                startIcon={<RedoIcon />}
                                variant="contained"
                                size="large"
                                disabled={isLoading || !existsNextPhoto}
                                onClick={() => skipPhoto.mutate()}
                              >
                                Skip
                              </Button>
                            </span>
                          </Tooltip>
                        </Box>
                        <Box p={2}>
                          <Tooltip title="Approve the photo">
                            <span>
                              <Button
                                className={classes.reviewButton}
                                startIcon={<CheckIcon />}
                                color="primary"
                                variant="contained"
                                size="large"
                                disabled={isLoading}
                                onClick={() => approvePhoto.mutate()}
                              >
                                Approve
                              </Button>
                            </span>
                          </Tooltip>
                        </Box>
                      </Box>
                    </CardActions>
                  </Box>
                </Card>
                <Card style={{ marginTop: "12px" }}>
                  <Box display="flex" flexDirection="column" width="100%">
                    <CardContent>
                      <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <Typography variant="h5" component="h5" paragraph={true}>
                          Photo review guidelines
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                          Please review this photo in accordance with the University{" "}
                          <Link
                            target="_blank"
                            href="https://help.uis.cam.ac.uk/university-card-photo"
                          >
                            photo policy
                          </Link>
                          .
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                          If the photo complies with all aspects of the policy click Approve. If
                          the photo does not comply with one or more aspects of the policy click
                          Reject.
                        </Typography>
                      </Box>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </MaxWidthContent>
  );
};
