import { ComponentProps, useEffect, useState } from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

import { useStyles } from "../styles/styles";
import { CardVisualization } from "./CardVisualization";
import { CardAttributes } from "./CardAttributes";
import {
  sortRecords,
  useCardsWithIdentifiers,
  CardSummary,
  useCardRequests,
  CardRequestSummary,
  workflowStatusDetails,
  cardStateDetails,
} from "../api/card-api";

import noCardsImgUrl from "../images/no-cards.svg";
import { CardNotes } from "./CardNotes";
import {
  CardControls,
  CardNoteControls,
  CardRequestControls,
  CardRequestCreateControl,
  CardRequestEditableFields,
} from "./CardControls";
import CardRequestAttributes from "./CardRequestAttributes";
import CardRequestAffiliations from "./CardRequestAffiliations";

interface Props extends ComponentProps<typeof Card> {
  identifier: string;
  displayName: string;
}

function isCardSummary(record: any): record is CardSummary {
  // Perform type checking based on the properties of CardSummary
  // Return a boolean indicating whether the object is of type CardSummary
  return typeof record === "object" && "status" in record;
}

/**
 * A card showing the cards and card requests for a given identifier.
 */
export const CardholderCardsCard = ({ identifier, displayName, ...cardProps }: Props) => {
  // 0-based index of card to display or -1 if no card is shown
  const [selectedCardIndex, setSelectedCardIndex] = useState(-1);

  const {
    isLoading: isCardsLoading,
    data: cardData,
    refetch: refetchCardData,
  } = useCardsWithIdentifiers([`${identifier}`], "", {
    enabled: !!identifier,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const {
    isLoading: isCardRequestsLoading,
    data: cardRequestData,
    refetch: refetchCardRequestData,
    isRefetching: isRefetchingCardRequestData,
  } = useCardRequests({ identifier: `${identifier}` }, { staleTime: 0, cacheTime: 0 });

  const isLoading = isCardsLoading || isCardRequestsLoading;

  const [cardholderRecords, setCardholderRecords] = useState(
    Array<CardSummary | CardRequestSummary>()
  );

  // when we get our data - update the selected card index to the last card
  useEffect(() => {
    if (cardData && cardRequestData) {
      if (!isRefetchingCardRequestData) {
        const mergedData = [...(cardData || []), ...(cardRequestData?.cardRequests || [])];
        setCardholderRecords(sortRecords(mergedData));
        setSelectedCardIndex((previousValue) =>
          previousValue === -1 ? mergedData.length - 1 : previousValue
        );
      }
    }
  }, [cardData, cardRequestData, isRefetchingCardRequestData]);

  return (
    <Card {...cardProps}>
      <Box display="flex" flexDirection="column" height="100%">
        <CardContent>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              container
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography gutterBottom variant="h5" component="h2">
                Cards & Requests - {displayName} ({identifier.split("@", 1)[0]})
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <CardRequestCreateControl
                identifier={identifier}
                onChange={() => {
                  setSelectedCardIndex(-1);
                  refetchCardData();
                  refetchCardRequestData();
                }}
              />
            </Grid>
          </Grid>

          <Collapse in={cardholderRecords.length > 0}>
            <Box mt={2}>
              <FormControl fullWidth>
                <InputLabel id="displayed-card">Display card...</InputLabel>
                <Select
                  labelId="displayed-card"
                  value={selectedCardIndex >= 0 ? selectedCardIndex : ""}
                  onChange={(e) => setSelectedCardIndex(e.target.value as number)}
                >
                  {cardholderRecords.map((record, index) => {
                    if (isCardSummary(record)) {
                      return (
                        // a card record
                        <MenuItem value={index} key={index}>
                          <Box display="flex" flexDirection="left">
                            <Box mr={1} minWidth={120}>
                              CARD
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <Box mx={1}>{`Issue: ${(record as CardSummary).issueNumber}`}</Box>
                            <Divider orientation="vertical" flexItem />
                            <Box mx={1} minWidth={175}>
                              {(record as CardSummary).issuedAt
                                ? `Issued: ${(
                                    record as CardSummary
                                  ).issuedAt?.toLocaleDateString()}`
                                : `Created: ${(
                                    record as CardSummary
                                  ).createdAt?.toLocaleDateString()}`}
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <Box ml={1}>
                              {`Status: ${
                                cardStateDetails[(record as CardSummary).status]?.displayName ||
                                (record as CardSummary).status
                              }`}
                            </Box>
                          </Box>
                        </MenuItem>
                      );
                    } else {
                      return (
                        <MenuItem value={index} key={index}>
                          <Box display="flex" flexDirection="left">
                            <Box mr={1} minWidth={120}>
                              CARD REQUEST
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <Box mx={1}>{`Issue: ${record.issueNumber}`}</Box>
                            <Divider orientation="vertical" flexItem />
                            <Box
                              mx={1}
                              minWidth={175}
                            >{`Requested: ${record.createdAt?.toLocaleDateString()}`}</Box>
                            <Divider orientation="vertical" flexItem />
                            <Box ml={1}>{`Status: ${
                              workflowStatusDetails[record.workflowState]?.displayName ||
                              record.workflowState
                            }`}</Box>
                          </Box>
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>
            </Box>
          </Collapse>
        </CardContent>
        <Divider />
        {!isLoading && cardholderRecords.length === 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            my={4}
          >
            <Box pb={3} width="10rem">
              <img src={noCardsImgUrl} alt="No cards" width="100%" />
            </Box>
            <Typography variant="caption" color="textSecondary">
              This user has no cards.
            </Typography>
          </Box>
        )}

        <Box flexGrow={1}>
          {(isLoading || cardholderRecords.length !== 0) &&
            (isCardSummary(cardholderRecords[selectedCardIndex]) ? (
              <CardContainer
                isLoading={isLoading}
                card={
                  selectedCardIndex > -1 && selectedCardIndex < cardholderRecords.length
                    ? (cardholderRecords[selectedCardIndex] as CardSummary)
                    : undefined
                }
              />
            ) : (
              <CardRequestContainer
                isLoading={isLoading}
                cardRequest={
                  selectedCardIndex > -1 && selectedCardIndex < cardholderRecords.length
                    ? (cardholderRecords[selectedCardIndex] as CardRequestSummary)
                    : undefined
                }
                onChange={refetchCardRequestData}
              />
            ))}
        </Box>
      </Box>
    </Card>
  );
};

export default CardholderCardsCard;

/**
 * Internal component for card within the card carousel.
 */
const CardContainer = ({
  card,
  isLoading = false,
}: {
  card?: CardSummary;
  isLoading?: boolean;
}) => {
  const classes = useStyles();
  const [tabNumber, setTabNumber] = useState(0);
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const handleDetailsChange =
    (expand: boolean) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setDetailsExpanded(isExpanded);
    };

  return (
    <>
      <Box flexShrink={0} width="100%">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              <Tabs
                centered
                value={tabNumber}
                onChange={(_, newValue) => setTabNumber(newValue)}
                indicatorColor="primary"
                aria-label="card front and back tabs"
              >
                <Tab label="Front" />
                <Tab label="Back" />
              </Tabs>
              <Paper key={card?.id || ""} elevation={2} className={classes.visualizationContainer}>
                <CardVisualization
                  visualizationUrl={
                    tabNumber === 0 ? card?.frontVisualizationLink : card?.backVisualizationLink
                  }
                  cardStatus={card?.status}
                  isLoading={isLoading || !card}
                />
              </Paper>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <CardNotes card={card} />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />

        <Box flexShrink={0} width="100%">
          <Accordion
            expanded={detailsExpanded}
            onChange={handleDetailsChange(!detailsExpanded)}
            className={classes.detailsAccordion}
          >
            <AccordionSummary
              expandIcon={
                detailsExpanded ? (
                  <Button variant="contained">
                    Show less <ExpandLessIcon />
                  </Button>
                ) : (
                  <Button variant="contained">
                    Show more <ExpandMoreIcon />
                  </Button>
                )
              }
              IconButtonProps={{ className: classes.accordionStaticButton }}
              aria-controls="card-details-content"
              id="card-details-header"
            >
              <Typography variant="subtitle1">Card Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box flexShrink={0} width="100%">
                <CardAttributes card={card} isLoading={isLoading || !card} />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Divider />

        <CardActions>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              <CardControls card={card} />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <CardNoteControls card={card} />
            </Grid>
          </Grid>
        </CardActions>
      </Box>
    </>
  );
};

export const CardRequestContainer = ({
  cardRequest,
  isLoading = false,
  onChange,
  showPersonLink = false,
}: {
  cardRequest?: CardRequestSummary;
  isLoading?: boolean;
  onChange: () => void;
  showPersonLink?: boolean;
}) => {
  const classes = useStyles();
  const [tabNumber, setTabNumber] = useState(0);
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const handleDetailsChange =
    (expand: boolean) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setDetailsExpanded(isExpanded);
    };

  return (
    <>
      <Box flexShrink={0} width="100%">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              <Tabs
                centered
                value={tabNumber}
                onChange={(_, newValue) => setTabNumber(newValue)}
                indicatorColor="primary"
                aria-label="card front and back tabs"
              >
                <Tab label="Front" />
                <Tab label="Back" />
              </Tabs>
              <Paper
                key={cardRequest?.id || ""}
                elevation={2}
                className={classes.visualizationContainer}
              >
                <CardVisualization
                  visualizationUrl={
                    tabNumber === 0
                      ? cardRequest?.frontVisualizationLink
                      : cardRequest?.backVisualizationLink
                  }
                  isLoading={isLoading || !cardRequest}
                />
              </Paper>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <CardRequestEditableFields
                id={cardRequest?.id || ""}
                onChange={onChange}
                showPersonLink={showPersonLink}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />

        <Box flexShrink={0} width="100%">
          <Accordion
            expanded={detailsExpanded}
            onChange={handleDetailsChange(!detailsExpanded)}
            className={classes.detailsAccordion}
          >
            <AccordionSummary
              expandIcon={
                detailsExpanded ? (
                  <Button variant="contained">
                    Show less <ExpandLessIcon />
                  </Button>
                ) : (
                  <Button variant="contained">
                    Show more <ExpandMoreIcon />
                  </Button>
                )
              }
              IconButtonProps={{ className: classes.accordionStaticButton }}
              aria-controls="card-request-details-content"
              id="card-request-details-header"
            >
              <Typography variant="subtitle1">Card Request Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box flexShrink={0} width="100%">
                <CardRequestAttributes
                  id={cardRequest?.id || ""}
                  isLoading={isLoading || !cardRequest}
                />
                <Divider />
                <CardRequestAffiliations
                  id={cardRequest?.id || ""}
                  isLoading={isLoading || !cardRequest}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Divider />

        <CardActions>
          <Grid container spacing={2}>
            <Grid container item xs={12} md={12} lg={12} spacing={1} justifyContent="flex-end">
              <CardRequestControls id={cardRequest?.id || ""} onChange={onChange} />
            </Grid>
          </Grid>
        </CardActions>
      </Box>
    </>
  );
};
