import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@material-ui/core";

type StatusMappings = {
  [key: string]: { displayName: string; tooltip: string };
};

interface StatusDisplayProps {
  status: string;
  isFlagged?: boolean;
  useMapping: StatusMappings;
}

const StatusDisplay = ({ status, isFlagged, useMapping }: StatusDisplayProps) => {
  const statusInfo = useMapping[status] || {
    displayName: status,
    tooltip: "Unknown status",
  };

  const textStyle = isFlagged ? { color: "red" } : {};

  const renderTooltipContent = (tooltipText: string) => {
    const contentLines = tooltipText.split("\n").map((line, index, array) => (
      <React.Fragment>
        {line}
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));

    return <div>{contentLines}</div>;
  };

  return (
    <Tooltip title={renderTooltipContent(statusInfo.tooltip)}>
      <Typography component="span" style={textStyle}>
        {statusInfo.displayName}
      </Typography>
    </Tooltip>
  );
};

export default StatusDisplay;
