import { createContext, FC, useContext } from "react";
import { useQuery } from "react-query";

import { AppConfig, getConfig } from "./config-service";

const AppConfigContext = createContext<AppConfig | null>(null);

export const useAppConfig = <T extends boolean>(
  throwOnMissingConfig?: T
): T extends true ? AppConfig : AppConfig | null => {
  const config = useContext(AppConfigContext);
  if (config === null && throwOnMissingConfig) {
    throw new Error("useAppConfig hook used without config being loaded or provided");
  }
  return config as AppConfig;
};
export const AppConfigProvider: FC = ({ children }) => {
  const { data } = useQuery("application-config", getConfig, { retry: false });
  return <AppConfigContext.Provider value={data ?? null}>{children}</AppConfigContext.Provider>;
};
