import { FC } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { CssBaseline } from "@material-ui/core";
import { SnackbarProvider } from "notistack";

import { AppConfigProvider } from "./config/AppConfigProvider";
import { APIGatewayProvider } from "./api-gateway/APIGatewayProvider";
import { Login } from "./login/Login";
import { AppThemeProvider } from "./theme/globals";
import { Loading } from "./loading/Loading";
import { CardAdminLayout } from "./admin-layout/CardAdminLayout";
import { ErrorBoundary } from "./errors/ErrorBoundary";
import { LoggerProvider } from "./logger/LoggerProvider";

export const App: FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        useErrorBoundary: true,
        refetchInterval: Infinity,
        refetchOnWindowFocus: false,
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <AppThemeProvider>
      <CssBaseline />
      <ErrorBoundary>
        <SnackbarProvider
          dense
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <QueryClientProvider client={queryClient}>
            <AppConfigProvider>
              <LoggerProvider LoadingComponent={Loading}>
                <APIGatewayProvider LoginComponent={Login} LoadingComponent={Loading}>
                  <CardAdminLayout LoadingComponent={Loading} />
                </APIGatewayProvider>
              </LoggerProvider>
            </AppConfigProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </ErrorBoundary>
    </AppThemeProvider>
  );
};
