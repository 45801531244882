import { useState, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { VariantType } from "notistack";
import { useAPIGateway } from "../api-gateway/APIGatewayProvider";
import { useAppConfig } from "../config/AppConfigProvider";

import { V1beta1PhotosCreateStatusEnum as CreateStatusEnum } from "./photo/apis/V1beta1Api";

import {
  Configuration as PhotoAPIConfig,
  PermissionsApi as PermissionsAPI,
  StatusEnum,
  SchemeEnum,
  V1beta1PhotosPartialUpdateStatusEnum as PartialUpdateStatusEnum,
  V1Beta1Photo as Photo,
} from "./photo/";
import { StatusEnum as PhotoStatusEnum } from "./photo/";

import { V1beta1Api } from "./photo";

import {
  CardRequestUpdateRequestActionEnum,
  CardRequestFilterParams,
  cardRequestRefreshableWorkflowStates,
  useCardAPI,
  useCardRequests,
  CardRequestSummaryWorkflowStateEnum,
} from "./card-api";

/**
 * The following are exported here (having possibly been renamed) to abstract their usage from
 * changes to client generation
 */

export { StatusEnum, CreateStatusEnum };
export { PhotoStatusEnum };

export type { Photo };

/**
 * Primary photo identifier - CRSId.
 */
export const PrimaryIdentifierScheme = SchemeEnum.V1PersonIdentifiersCamAcUk;

/**
 * A hook which returns the permissions which the current user has on the Card API.
 */
export const usePhotoPermissions = () => {
  const { fetch: apiGatewayFetch } = useAPIGateway();
  const { photoAPIBaseUrl } = useAppConfig(true);
  const permissionsAPI = new PermissionsAPI(
    new PhotoAPIConfig({ fetchApi: apiGatewayFetch, basePath: photoAPIBaseUrl })
  );

  const { isLoading, data: permissions } = useQuery("photo-permissions", async () => {
    const { permissions } = await permissionsAPI.permissionsRetrieve();
    return permissions;
  });
  return { isLoading, permissions };
};

/**
 * A hook which returns an instance of the Photo API for accessing photos using the authenticated
 * API Gateway instance and base url from the config context.
 */
export const usePhotosIncludingUnapprovedPhotosAPI = () => {
  const { fetch: apiGatewayFetch } = useAPIGateway();
  const { photoAPIBaseUrl } = useAppConfig(true);

  return new V1beta1Api(
    new PhotoAPIConfig({ fetchApi: apiGatewayFetch, basePath: photoAPIBaseUrl })
  );
};

/**
 * Hook to return a single photo instance by photo UUID.
 */
export const usePhotoById = (
  id?: undefined | string,
  onResult?: (msg: string, severity: VariantType) => void
) => {
  const photosIncludingUnapprovedPhotosAPI = usePhotosIncludingUnapprovedPhotosAPI();

  const photoByIdQueryState = useQuery(
    ["photoById", id],
    async () => {
      if (!id) {
        return undefined;
      }
      const photo = await photosIncludingUnapprovedPhotosAPI.v1beta1PhotosRetrieve({ id: id });
      return photo;
    },
    {
      cacheTime: 0,
      retry: false,
      useErrorBoundary: false,
      onError: (error: Response) => {
        if ([401, 403].includes(error.status)) {
          if (onResult) {
            onResult("You do not have permission to view photos.", "error");
          }
        }
      },
    }
  );

  return {
    photo: photoByIdQueryState.data,
    isLoading: photoByIdQueryState.isLoading,
  };
};

/**
 * A hook which returns the most recent photo associated with a user identifier. This is either
 * the photo that was approved most recently, or the unapproved photo that was submitted most
 * recently.
 *
 * The metadata associated with the photo is returned as well as a content blob that
 * describes the photo image.
 */
export const useNewestPhoto = (opts: {
  identifier?: string;
  onResult?: (msg: string, severity: VariantType) => void;
}) => {
  const queryClient = useQueryClient();
  const { fetch: apiGatewayFetch } = useAPIGateway();
  const photosIncludingUnapprovedPhotosAPI = usePhotosIncludingUnapprovedPhotosAPI();

  const identifier = opts.identifier;
  const onResult = opts.onResult;

  const newestPhotoQueryState = useQuery(
    ["newestPhoto", identifier],
    async () => {
      if (!identifier) {
        return {
          photo: undefined,
          contentBlob: undefined,
        };
      }
      const photos: Photo[] = await photosIncludingUnapprovedPhotosAPI.v1beta1PhotosList({
        identifier,
      });
      let mostRecentPhoto: Photo | undefined = undefined;
      let contentBlob: Blob | undefined = undefined;
      photos.forEach((photo) => {
        if (!mostRecentPhoto) {
          mostRecentPhoto = photo;
          return;
        }
        const mostRecentPhotoDate: Date | null | undefined =
          mostRecentPhoto.status === StatusEnum.Approved
            ? mostRecentPhoto.approvedAt
            : mostRecentPhoto.createdAt;
        const photoDate: Date | null | undefined =
          photo.status === StatusEnum.Approved ? photo.approvedAt : photo.createdAt;
        if (!mostRecentPhotoDate || !photoDate) {
          return;
        }
        if (photoDate > mostRecentPhotoDate) {
          mostRecentPhoto = photo;
        }
      });
      if (mostRecentPhoto) {
        const fetchedPhoto = await apiGatewayFetch((mostRecentPhoto as Photo).contentLink);
        if (fetchedPhoto.ok) {
          contentBlob = await fetchedPhoto.blob();
        }
      }

      return {
        photo: mostRecentPhoto,
        contentBlob: contentBlob,
      };
    },
    {
      cacheTime: 0,
      retry: false,
      useErrorBoundary: false,
      onError: (error: Response) => {
        if ([401, 403].includes(error.status)) {
          if (onResult) {
            onResult("You do not have permission to view photos.", "error");
          }
        }
      },
    }
  );

  // Mutation function to upload a new photo for a person indicated by the specified person
  // identifier.
  const uploadPhotoMutation = useMutation<
    Photo,
    Response,
    { identifier: string; contentBlob: Blob; status: CreateStatusEnum }
  >(
    (opts: { identifier: string; contentBlob: Blob; status: CreateStatusEnum }) => {
      return photosIncludingUnapprovedPhotosAPI.v1beta1PhotosCreate({
        identifiers: opts.identifier,
        status: opts.status,
        body: opts.contentBlob,
      });
    },
    {
      onError: (error: Response) => {
        if ([400, 415, 500].includes(error.status)) {
          error.json().then((obj) => {
            if (onResult) {
              onResult(obj.detail || "An error occurred.", "error");
            }
          });
        } else if ([401, 403].includes(error.status)) {
          if (onResult) {
            onResult("You do not have permission to upload photos.", "error");
          }
        } else {
          if (onResult) {
            onResult("An error occurred. Please try again later.", "warning");
          }
        }
      },
      onSettled: (
        _: Photo | undefined,
        __: Response | null,
        opts: { identifier: string; contentBlob: Blob; status: CreateStatusEnum }
      ) => {
        // The latest unapproved photo may have changed as a result of this upload, so invalidate
        // the existing query to force a refresh.
        queryClient.invalidateQueries("unapprovedPhoto");
        queryClient.invalidateQueries("card-visualization");

        // Remove the query result for the newest photo of the person identifier and force a
        // refresh.
        queryClient.resetQueries(
          ["newestPhoto", opts.identifier],
          { exact: true },
          { cancelRefetch: true }
        );
      },
    }
  );

  return {
    isLoading: newestPhotoQueryState.isLoading || uploadPhotoMutation.isLoading,
    photo: newestPhotoQueryState.data?.photo,
    photoContentBlob: newestPhotoQueryState.data?.contentBlob,
    uploadPhoto: uploadPhotoMutation,
  };
};

/**
 * A hook which returns the oldest unapproved photo, along with functions to approve and reject
 * the photo.
 */
export const useOldestUnapprovedPhoto = (
  onResult?: (msg: string, severity: VariantType) => void
) => {
  const { fetch: apiGatewayFetch } = useAPIGateway();
  const unapprovedPhotosAPI = usePhotosIncludingUnapprovedPhotosAPI();

  // Track contents (as object URLs) of two photos so we don't risk revoking an object URL that
  // is being displayed.
  const [photo, setPhoto] = useState<Photo | undefined>(undefined);
  const [content, setContent] = useState<string | undefined>(undefined);
  const [prevContent, setPrevContent] = useState<string | undefined>(undefined);

  const refetchedDueToUnknownPrimaryIdRef = useRef(false);

  const unapprovedPhotoQueryState = useQuery(
    ["unapprovedPhoto"],
    async () => {
      const { next, results, count } = await unapprovedPhotosAPI.v1beta1UnapprovedPhotosList({
        pageSize: 1,
      });
      const photoArray = results || [];
      const newPhoto = photoArray.length >= 1 ? photoArray[0] : undefined;

      const primaryId = newPhoto
        ? newPhoto.identifiers.find((ident) => ident.scheme === PrimaryIdentifierScheme) || {
            value: "Unknown",
          }
        : { value: "Unknown" };

      if (primaryId.value === "Unknown" && next && !refetchedDueToUnknownPrimaryIdRef.current) {
        refetchedDueToUnknownPrimaryIdRef.current = true;
        unapprovedPhotoQueryState.refetch();
        return;
      }

      if ((!newPhoto && !photo) || (newPhoto && photo && newPhoto.id === photo.id)) {
        return {
          photo: photo,
          content: content,
          existsNextPhoto: next != null,
        };
      }
      let newContent;
      if (newPhoto) {
        const fetchedPhoto = await apiGatewayFetch(newPhoto.contentLink);
        if (fetchedPhoto.ok) {
          const photoBlob = await fetchedPhoto.blob();
          newContent = URL.createObjectURL(photoBlob);
        }
      }
      if (prevContent) {
        URL.revokeObjectURL(prevContent);
      }
      setPhoto(newPhoto);
      setPrevContent(content);
      setContent(newContent);
      return {
        photo: newPhoto,
        content: newContent,
        existsNextPhoto: next != null,
        count: count,
      };
    },
    {
      cacheTime: 0,
      refetchInterval: false,
      retry: false,
      useErrorBoundary: false,
      onError: (error: Response) => {
        if ([401, 403].includes(error.status)) {
          if (onResult) {
            onResult("You do not have permission to review photos.", "error");
          }
        }
      },
    }
  );

  return {
    photo: unapprovedPhotoQueryState.data?.photo,
    photoContent: unapprovedPhotoQueryState.data?.content,
    existsNextPhoto: unapprovedPhotoQueryState.data?.existsNextPhoto,
    isLoading: unapprovedPhotoQueryState.isLoading,
    unapprovedPhotoCount: unapprovedPhotoQueryState.data?.count,
  };
};

/**
 * A hook which provides functions to review a photo (approve or reject the photo). The `id`
 * passed is the identifier of the individual photo, and although it is optional it must be
 * provided for the mutations to function. The optional `identifier` is the related person
 * identifier, and when provided ensures that any cached queries that relate to the person
 * are invalidated (as the mutation may affect the query result).
 */
export const useReviewPhoto = (opts: {
  id?: string;
  identifier?: string;
  onResult?: (msg: string, severity: VariantType) => void;
}) => {
  const queryClient = useQueryClient();
  const photosIncludingUnapprovedPhotosAPI = usePhotosIncludingUnapprovedPhotosAPI();

  const id = opts.id;
  const identifier = opts.identifier;
  const onResult = opts.onResult;

  const cardAPI = useCardAPI();
  const [rowsPerPage] = useState<number>(200);
  const [filters] = useState<Partial<CardRequestFilterParams>>({
    identifier: identifier,
  });
  const { data: { cardRequests } = {} } = useCardRequests(
    { ...filters, pageSize: rowsPerPage },
    { staleTime: 0, cacheTime: 0 }
  );
  const refreshableCardRequests = (cardRequests || []).filter(
    (cardRequest: { workflowState: CardRequestSummaryWorkflowStateEnum }) =>
      cardRequestRefreshableWorkflowStates.includes(cardRequest.workflowState)
  );

  const reviewPhotoOnSuccess = () => {
    queryClient.invalidateQueries("unapprovedPhoto");
    if (identifier) {
      // Remove the query result for the newest photo of the person identifier and force a
      // refresh.
      queryClient.resetQueries(
        ["newestPhoto", identifier],
        { exact: true },
        { cancelRefetch: true }
      );
    }
  };

  const reviewPhotoOnSuccessWithCardRequestRefresh = () => {
    queryClient.invalidateQueries("unapprovedPhoto");
    if (identifier) {
      // Refetch query result for the newest photo of the person identifier
      queryClient.resetQueries(
        ["newestPhoto", identifier],
        { exact: true },
        { cancelRefetch: true }
      );
      if (refreshableCardRequests) {
        // Force a refresh of pending card requests of the person identifier
        refreshableCardRequests.map((cardRequest) =>
          cardAPI.v1beta1CardRequestsUpdate({
            id: cardRequest.id!,
            cardRequestUpdateRequest: {
              action: CardRequestUpdateRequestActionEnum.Update,
            },
          })
        );
        queryClient.invalidateQueries("card-visualization");
      }
    }
  };

  const reviewPhotoOnError = (error: Response, action: string) => {
    if ([401, 403].includes(error.status)) {
      if (onResult) {
        onResult(`You do not have permission to ${action} photos.`, "error");
      }
    } else {
      if (onResult) {
        onResult("An error occurred. Please try again later.", "warning");
      }
    }
  };

  // Mutation function that marks an unapproved photo as approved.
  const approvePhotoMutation = useMutation<Photo, Response>(
    () => {
      return photosIncludingUnapprovedPhotosAPI.v1beta1PhotosPartialUpdate({
        id: id || "",
        status: PartialUpdateStatusEnum.Approved,
      });
    },
    {
      onSuccess: reviewPhotoOnSuccessWithCardRequestRefresh,
      onError: (error: Response) => {
        reviewPhotoOnError(error, "approve");
      },
    }
  );

  // Mutation function that rejects (deletes) an unapproved photo.
  const rejectPhotoMutation = useMutation<void, Response, { rejectReason?: string }>(
    (opts: { rejectReason?: string }) => {
      return photosIncludingUnapprovedPhotosAPI.v1beta1PhotosDestroy({
        id: id || "",
        reason: opts.rejectReason || "",
      });
    },
    {
      onSuccess: reviewPhotoOnSuccess,
      onError: (error: Response) => {
        reviewPhotoOnError(error, "reject");
      },
    }
  );

  // Mutation function that invalidates an approved photo.
  const invalidatePhotoMutation = useMutation<Photo, Response>(
    () => {
      return photosIncludingUnapprovedPhotosAPI.v1beta1PhotosPartialUpdate({
        id: id || "",
        status: PartialUpdateStatusEnum.Invalidated,
      });
    },
    {
      onSuccess: reviewPhotoOnSuccessWithCardRequestRefresh,
      onError: (error: Response) => {
        reviewPhotoOnError(error, "invalidate");
      },
    }
  );

  const skipPhotoMutation = useMutation<Photo, Response>(
    () => {
      return photosIncludingUnapprovedPhotosAPI.v1beta1PhotosPartialUpdate({
        id: id || "",
        postponeReview: true,
      });
    },
    {
      onSuccess: reviewPhotoOnSuccess,
      onError: (error: Response) => {
        reviewPhotoOnError(error, "skip");
      },
    }
  );

  return {
    approvePhoto: approvePhotoMutation,
    rejectPhoto: rejectPhotoMutation,
    skipPhoto: skipPhotoMutation,
    invalidatePhoto: invalidatePhotoMutation,
    isLoading: approvePhotoMutation.isLoading || rejectPhotoMutation.isLoading,
  };
};

export const photoStatusDetails = {
  [PhotoStatusEnum.Approved]: {
    displayName: "Approved",
    tooltip:
      "This photo has been approved by the Card Office as meeting the card photo requirements.",
  },
  [PhotoStatusEnum.Unapproved]: {
    displayName: "Pending Review",
    tooltip:
      "This photo has been submitted (usually by a Card Rep) but has yet to be approved by the Card Office.",
  },
  [PhotoStatusEnum.Invalidated]: {
    displayName: "Invalidated",
    tooltip:
      "This photo has either been marked as not suitable (not meeting the card photo requirements) by the Card Office, or has been superseded by a newer photo.",
  },
} as const;
