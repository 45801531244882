import { CRSID_SCHEME, STAFF_NUMBER_SCHEME, USN_SCHEME } from "../api/card-api";

// Recognised AppID's for automated card request creation processes
const autoCreateRequestAppIDs: Array<string> = [
  "2d896495-5663-42f2-ab6f-ef13e5eb4da3@application.api.apps.cam.ac.uk", // production
  "6e8a3df5-67d3-4432-9f3d-f6ee8767d08f@application.api.apps.cam.ac.uk", // staging
  "e5b0d6d7-41b5-4cd6-8512-3f936ee16ad3@application.api.apps.cam.ac.uk", // devel
];

// Friendly name for Card Request requestors
export const requestorDescription = (requestor?: string | null) => {
  return !requestor
    ? "-"
    : autoCreateRequestAppIDs.includes(requestor)
    ? "Automated (CamSIS)"
    : requestor.endsWith(CRSID_SCHEME)
    ? `Manual (${requestor.replace(`@${CRSID_SCHEME}`, "")})`
    : requestor.endsWith(USN_SCHEME)
    ? "Automated (Legacy CamSIS)"
    : requestor.endsWith(STAFF_NUMBER_SCHEME)
    ? "Automated (Legacy CHRIS)"
    : requestor;
};
