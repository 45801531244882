import React, { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import { Location } from "history";
import { useAppConfig } from "../config/AppConfigProvider";
import {
  Box,
  Divider,
  ListItem,
  ListItemText,
  Drawer,
  Hidden,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBack, Menu } from "@material-ui/icons";

import {
  NavigationPanel as NavigationPanelRoot,
  NavigationPanelAvatar,
  NavigationPanelLogo,
  NavigationPanelSection,
  NavigationPanelSectionLink,
  NavigationPanelFooter,
  NavigationPanelFooterLink,
} from "@ucam.uis.devops/navigation-panel";

import LogoImage from "../images/shield.svg";
import { guideBookLink, rolesAndResponsibilitiesLink } from "../config/consts";

import { useAllCardRequests, CardRequestWorkflowFilterOptions } from "../api/card-api";
import { useOldestUnapprovedPhoto } from "../api/photo-api";
import { enqueueSnackbar } from "notistack";

const DRAW_WIDTH = 240; // px

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "100%",
  },
  appBar: { flex: 0 },
  drawer: { width: DRAW_WIDTH },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  iconButton: { marginRight: "0.5em" },
});

/**
 * Utility function to return props for ListItem when it is used as a Link to other parts of the
 * application.
 */
const navLinkProps = (location: Location<unknown>, to: string, text: string) => {
  return {
    to,
    text,
    selected: to === location.pathname,
    dataRole: "link",
  };
};

interface PermissionsProps {
  canViewCardholders: boolean;
  canReviewPhotos: boolean;
  canViewAnalytics: boolean;
}

interface NavigationPanelProps {
  username: string;
  displayName: string;
  permissions: PermissionsProps;
  onLogOut: () => void;
}

const NavigationContent: FC<
  NavigationPanelProps & { location: Location; onNavigationClick: () => void }
> = ({ location, username, displayName, permissions, onNavigationClick, onLogOut }) => {
  const { isLoading: isLoadingCardRequests, data: { cardRequestsCount } = {} } =
    useAllCardRequests(
      {
        workflowState: [CardRequestWorkflowFilterOptions.Pending],
        // do not follow next pagination as we are only interested in the total count
        followNextPagination: false,
      },
      { staleTime: 0, cacheTime: 0, refetchInterval: 300000 }
    );

  // Fetch the oldest unapproved photo
  const { isLoading: isUnapprovedPhotoLoading, unapprovedPhotoCount } = useOldestUnapprovedPhoto(
    (msg, severity) => enqueueSnackbar(msg, { variant: severity })
  );

  return (
    <NavigationPanelRoot>
      <NavigationPanelLogo
        logoImage={LogoImage}
        logoImageAlt="University Card"
        projectStatusTag="Beta"
      />
      <Divider />
      <Box flexGrow={1} onClick={onNavigationClick}>
        <NavigationPanelAvatar profile={{ username, displayName, avatarUrl: "" }} />
        <Divider />
        <NavigationPanelSection dataRole="navDrawer">
          {permissions.canViewCardholders && (
            <NavigationPanelSectionLink
              {...navLinkProps(location, "/cardholders", "Cardholders")}
            />
          )}
          {permissions.canReviewPhotos && (
            <NavigationPanelSectionLink
              {...navLinkProps(
                location,
                "/photo-review",
                `Photo Review Queue ${
                  isUnapprovedPhotoLoading
                    ? ""
                    : `(${unapprovedPhotoCount !== undefined ? unapprovedPhotoCount : 0})`
                }`
              )}
            />
          )}
          {permissions.canViewCardholders && (
            <>
              <NavigationPanelSectionLink
                {...navLinkProps(
                  location,
                  "/print-queue",
                  `Print Queue ${isLoadingCardRequests ? "" : "(" + cardRequestsCount + ")"}`
                )}
              />
            </>
          )}
          <Divider />
          {permissions.canViewAnalytics && (
            <>
              <NavigationPanelSectionLink {...navLinkProps(location, "/dashboard", "Analytics")} />
            </>
          )}
          {permissions.canViewCardholders && (
            <>
              <NavigationPanelSectionLink
                {...navLinkProps(location, "/exports", "Data Exports")}
              />
            </>
          )}
          <NavigationPanelSectionLink
            {...navLinkProps(location, "/feedback", "Help and Feedback")}
          />
        </NavigationPanelSection>
        <Divider />
        <NavigationPanelSection dataRole="accountDrawer">
          <ListItem button={true} onClick={onLogOut}>
            <ListItemText primary="Sign out" />
          </ListItem>
        </NavigationPanelSection>
      </Box>
      <Divider />
      <NavigationPanelFooter>
        <NavigationPanelFooterLink link={guideBookLink} text="Developers" />
        <NavigationPanelFooterLink
          link={rolesAndResponsibilitiesLink}
          text="Roles and Responsibilities"
        />
      </NavigationPanelFooter>
    </NavigationPanelRoot>
  );
};

export const NavigationWrapper: FC<NavigationPanelProps> = ({
  username,
  displayName,
  permissions,
  onLogOut,
  children,
}) => {
  const location = useLocation();
  const classes = useStyles();
  const [mobileDrawerOpen, setMobileDrawOpen] = useState(false);
  const { environment } = useAppConfig(true);

  // If we're in a nested route, e.g. /cardholders/wgd23@v1.person.identifiers.cam.ac.uk, add
  // a back button to the toolbar. This is a bit of a heuristic but avoids pages having to pass
  // state back to this component indicating whether they want a back button to be rendered.
  const needsBack = location.pathname.split("/").length > 2;

  // Additional text on app bar (and whether to change background colour)
  const isProduction = environment === "production";
  const appBarExtraText = isProduction
    ? ""
    : " - " + environment[0].toUpperCase() + environment.substring(1);
  const appBarColor = isProduction ? undefined : "secondary";

  return (
    <div className={classes.root}>
      <Hidden lgUp>
        <Drawer
          variant="temporary"
          open={mobileDrawerOpen}
          onClose={() => setMobileDrawOpen(false)}
          ModalProps={{ keepMounted: true }}
          classes={{ root: classes.drawer, paper: classes.drawer }}
        >
          <NavigationContent
            username={username}
            displayName={displayName}
            permissions={permissions}
            location={location}
            onLogOut={onLogOut}
            onNavigationClick={() => setMobileDrawOpen(false)}
          />
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer variant="permanent" classes={{ root: classes.drawer, paper: classes.drawer }}>
          <NavigationContent
            username={username}
            displayName={displayName}
            permissions={permissions}
            location={location}
            onLogOut={onLogOut}
            onNavigationClick={() => setMobileDrawOpen(false)}
          />
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <AppBar className={classes.appBar} position="sticky" color={appBarColor}>
          <Toolbar>
            {needsBack && (
              <IconButton
                edge="start"
                color="inherit"
                className={classes.iconButton}
                onClick={() => window.history.back()}
              >
                <ArrowBack />
              </IconButton>
            )}
            <Hidden lgUp>
              <IconButton
                edge="start"
                color="inherit"
                className={classes.iconButton}
                onClick={() => setMobileDrawOpen((open) => (open = !open))}
              >
                <Menu />
              </IconButton>
            </Hidden>
            <Typography variant="h6" noWrap>
              Card Management{appBarExtraText}
            </Typography>
          </Toolbar>
        </AppBar>
        {children}
      </main>
    </div>
  );
};
