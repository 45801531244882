/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  InlineResponse2006Result,
  InlineResponse2006ResultFromJSON,
  InlineResponse2006ResultFromJSONTyped,
  InlineResponse2006ResultToJSON,
} from "./";

/**
 *
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
  /**
   *
   * @type {InlineResponse2006Result}
   * @memberof InlineResponse2006
   */
  result?: InlineResponse2006Result;
}

export function InlineResponse2006FromJSON(json: any): InlineResponse2006 {
  return InlineResponse2006FromJSONTyped(json, false);
}

export function InlineResponse2006FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineResponse2006 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    result: !exists(json, "result")
      ? undefined
      : InlineResponse2006ResultFromJSON(json["result"]),
  };
}

export function InlineResponse2006ToJSON(
  value?: InlineResponse2006 | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    result: InlineResponse2006ResultToJSON(value.result),
  };
}
