import { useCallback, useMemo } from "react";
import { useLocation, useHistory } from "react-router";

/**
 * A hook which returns the url query param values as well as a method of updating these values
 * within the current url.
 */
export const useUrlQueryParams = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const updateUrlParams = useCallback(
    (key: string, value: string) => {
      params.set(key, value);
      history.push({ search: params.toString() });
    },
    [history, params]
  );

  return [params, updateUrlParams] as const;
};
