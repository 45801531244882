/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Attribute,
  AttributeFromJSON,
  AttributeFromJSONTyped,
  AttributeToJSON,
  ContactRow,
  ContactRowFromJSON,
  ContactRowFromJSONTyped,
  ContactRowToJSON,
  Group,
  GroupFromJSON,
  GroupFromJSONTyped,
  GroupToJSON,
  Person,
  PersonFromJSON,
  PersonFromJSONTyped,
  PersonToJSON,
} from "./";

/**
 * Class representing an institution returned by the web service API.
 * @export
 * @interface Institution
 */
export interface Institution {
  /**
   * Flag indicating if the institution is cancelled.
   * @type {boolean}
   * @memberof Institution
   */
  cancelled?: boolean;
  /**
   * The institution's unique ID (e.g., "CS").
   * @type {string}
   * @memberof Institution
   */
  instid?: string;
  /**
   * The institution's name.
   * @type {string}
   * @memberof Institution
   */
  name?: string;
  /**
   * The institution's acronym, if set (e.g., "UCS").
   * @type {string}
   * @memberof Institution
   */
  acronym?: string;
  /**
   * A list of the institution's attributes. This will only be populated
   * if the `fetch` parameter includes the `"all_attrs"`
   * option, or any specific attribute schemes such as `"email"` or
   * `"address"`, or the special pseudo-attribute scheme
   * `"phone_numbers"`.
   * @type {Array<Attribute>}
   * @memberof Institution
   */
  attributes?: Array<Attribute>;
  /**
   * A list of the institution's contact rows. This will only be populated
   * if the `fetch` parameter includes the
   * `"contact_rows"` option.
   * @type {Array<ContactRow>}
   * @memberof Institution
   */
  contactRows?: Array<ContactRow>;
  /**
   * A list of the institution's members. This will only be populated if
   * the `fetch` parameter includes the `"all_members"`
   * option.
   * @type {Array<Person>}
   * @memberof Institution
   */
  members?: Array<Person>;
  /**
   * A list of the institution's parent institutions. This will only be
   * populated if the `fetch` parameter includes the
   * `"parent_insts"` option.
   *
   * NOTE: Currently all institutions have one parent, but in the future
   * institutions may have multiple parents.
   * @type {Array<Institution>}
   * @memberof Institution
   */
  parentInsts?: Array<Institution>;
  /**
   * A list of the institution's child institutions. This will only be
   * populated if the `fetch` parameter includes the
   * `"child_insts"` option.
   * @type {Array<Institution>}
   * @memberof Institution
   */
  childInsts?: Array<Institution>;
  /**
   * A list of all the groups that belong to the institution. This will
   * only be populated if the `fetch` parameter includes the
   * `"inst_groups"` option.
   * @type {Array<Group>}
   * @memberof Institution
   */
  groups?: Array<Group>;
  /**
   * A list of the groups that form the institution's membership. This
   * will only be populated if the `fetch` parameter includes
   * the `"members_groups"` option.
   * @type {Array<Group>}
   * @memberof Institution
   */
  membersGroups?: Array<Group>;
  /**
   * A list of the groups that manage this institution. This will only
   * be populated if the `fetch` parameter includes the
   * `"managed_by_groups"` option.
   * @type {Array<Group>}
   * @memberof Institution
   */
  managedByGroups?: Array<Group>;
  /**
   * An ID that can uniquely identify this institution within the
   * returned XML/JSON document. This is only used in the flattened
   * XML/JSON representation (if the "flatten" parameter is specified).
   * @type {string}
   * @memberof Institution
   */
  id?: string;
  /**
   * A reference (by id) to an institution element in the XML/JSON
   * document. This is only used in the flattened XML/JSON representation
   * (if the "flatten" parameter is specified).
   * @type {string}
   * @memberof Institution
   */
  ref?: string;
}

export function InstitutionFromJSON(json: any): Institution {
  return InstitutionFromJSONTyped(json, false);
}

export function InstitutionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Institution {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cancelled: !exists(json, "cancelled") ? undefined : json["cancelled"],
    instid: !exists(json, "instid") ? undefined : json["instid"],
    name: !exists(json, "name") ? undefined : json["name"],
    acronym: !exists(json, "acronym") ? undefined : json["acronym"],
    attributes: !exists(json, "attributes")
      ? undefined
      : (json["attributes"] as Array<any>).map(AttributeFromJSON),
    contactRows: !exists(json, "contactRows")
      ? undefined
      : (json["contactRows"] as Array<any>).map(ContactRowFromJSON),
    members: !exists(json, "members")
      ? undefined
      : (json["members"] as Array<any>).map(PersonFromJSON),
    parentInsts: !exists(json, "parentInsts")
      ? undefined
      : (json["parentInsts"] as Array<any>).map(InstitutionFromJSON),
    childInsts: !exists(json, "childInsts")
      ? undefined
      : (json["childInsts"] as Array<any>).map(InstitutionFromJSON),
    groups: !exists(json, "groups")
      ? undefined
      : (json["groups"] as Array<any>).map(GroupFromJSON),
    membersGroups: !exists(json, "membersGroups")
      ? undefined
      : (json["membersGroups"] as Array<any>).map(GroupFromJSON),
    managedByGroups: !exists(json, "managedByGroups")
      ? undefined
      : (json["managedByGroups"] as Array<any>).map(GroupFromJSON),
    id: !exists(json, "id") ? undefined : json["id"],
    ref: !exists(json, "ref") ? undefined : json["ref"],
  };
}

export function InstitutionToJSON(value?: Institution | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cancelled: value.cancelled,
    instid: value.instid,
    name: value.name,
    acronym: value.acronym,
    attributes:
      value.attributes === undefined
        ? undefined
        : (value.attributes as Array<any>).map(AttributeToJSON),
    contactRows:
      value.contactRows === undefined
        ? undefined
        : (value.contactRows as Array<any>).map(ContactRowToJSON),
    members:
      value.members === undefined
        ? undefined
        : (value.members as Array<any>).map(PersonToJSON),
    parentInsts:
      value.parentInsts === undefined
        ? undefined
        : (value.parentInsts as Array<any>).map(InstitutionToJSON),
    childInsts:
      value.childInsts === undefined
        ? undefined
        : (value.childInsts as Array<any>).map(InstitutionToJSON),
    groups:
      value.groups === undefined
        ? undefined
        : (value.groups as Array<any>).map(GroupToJSON),
    membersGroups:
      value.membersGroups === undefined
        ? undefined
        : (value.membersGroups as Array<any>).map(GroupToJSON),
    managedByGroups:
      value.managedByGroups === undefined
        ? undefined
        : (value.managedByGroups as Array<any>).map(GroupToJSON),
    id: value.id,
    ref: value.ref,
  };
}
