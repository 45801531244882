import { ComponentProps } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import { styled } from "@material-ui/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { useInView } from "react-intersection-observer";
import StatusDisplay from "../components/StatusDisplayComponent";

import {
  useCardLogo,
  CardIdentifierSummarySchemeEnum,
  useCardRequestDetails,
  workflowStatusDetails,
} from "../api/card-api";

/**
 * Identifiers we want to show if present in the order we want to show them.
 */
const IDENTIFIERS_TO_SHOW: {
  scheme: CardIdentifierSummarySchemeEnum;
  name: string;
  tooltip?: string;
}[] = [
  {
    scheme: CardIdentifierSummarySchemeEnum.BarcodeV1CardUniversityIdentifiersCamAcUk,
    name: "Barcode",
    tooltip: "Identifier for the cardholder originally used by the Library",
  },
  {
    scheme: CardIdentifierSummarySchemeEnum.MifareNumberV1CardUniversityIdentifiersCamAcUk,
    name: "UCam card ID",
    tooltip: "Unique identifier programmed into each card by the University",
  },
  {
    scheme: CardIdentifierSummarySchemeEnum.MifareIdentifierV1CardUniversityIdentifiersCamAcUk,
    name: "Manufacturer card UID",
    tooltip: "Unique identifier programmed into each card by the manufacturer",
  },
  {
    scheme: CardIdentifierSummarySchemeEnum.V1PersonIdentifiersCamAcUk,
    name: "CRSid",
    tooltip: "Identifier for the cardholder used by a wide variety of University systems",
  },
  {
    scheme: CardIdentifierSummarySchemeEnum.PersonV1HumanResourcesUniversityIdentifiersCamAcUk,
    name: "Staff number",
    tooltip: "Identifier for the cardholder used by the HR database",
  },
  {
    scheme: CardIdentifierSummarySchemeEnum.PersonV1StudentRecordsUniversityIdentifiersCamAcUk,
    name: "Student number",
    tooltip: "Identifier for the holder used by the student database",
  },
  {
    scheme: CardIdentifierSummarySchemeEnum.PersonV1LegacyCardUniversityIdentifiersCamAcUk,
    name: "Legacy cardholder identifier",
    tooltip: "Identifier for the cardholder used by previous card systems",
  },
];

/**
 * Styled List which lays out ListItems in columns if necessary.
 */
const AttributeList = styled(List)({
  "&": {
    columnWidth: "20ex",
  },

  "& li": {
    overflow: "hidden",
    breakInside: "avoid",
  },
});

interface Props extends ComponentProps<"div"> {
  id?: string;
  isLoading?: boolean;
}

export const CardRequestAttributes = ({ id, isLoading = false, ...rootProps }: Props) => {
  // We fetch further details on cards when the attribute list comes into view. This avoids
  // unnecessary network operations.
  const { ref } = useInView();

  // render the following fields blank to indicate a card has not been issued
  const expiresAt = "";
  const issuedAt = "";
  const revokedAt = "";

  // Asynchronously fetch details for this card. Note that useQuery (which these hooks are based
  // on) makes sure to cache these results and so it is safe to have this query buried so deep;
  // other calls to useCard() with the same id will re-use the result. We also only start fetching
  // details once the component is in view so as to avoid issuing large number of requests for
  // cards we're not currently showing.
  const { data: cardRequestDetail, isLoading: cardDetailIsLoading } = useCardRequestDetails(
    id || ""
  );

  // Once the card has been fetched, extract the scarf logo id (if present) and then fetch
  // information on the scarf itself. Again, this information is cached.
  const scarfIdentifier = (cardRequestDetail?.attributes as undefined | { scarf: string })?.scarf;
  const scarfUuid =
    scarfIdentifier &&
    scarfIdentifier.endsWith("card-logo.v1.card.university.identifiers.cam.ac.uk")
      ? scarfIdentifier.split("@")[0]
      : null;
  const { data: scarf, isLoading: scarfIsLoading } = useCardLogo(scarfUuid || "", {
    enabled: !!scarfUuid,
  });
  const scarfName = scarf?.name;

  // Simple function to render an undefined attribute as an "unknown" indicator or as a placeholder
  // if the card or attribute is loading. You can pass a custom "isLoading" flag for attributes
  // loaded at later times. The default "isLoading" flag corresponds to the passed isLoading prop.
  const renderAttribute = (s?: string, { isLoading: attributeIsLoading } = { isLoading }) =>
    attributeIsLoading ? <Skeleton /> : s && s !== "" ? s : "\u2013";

  // Collapse and collect identifier values by scheme.
  const identifierValues = (cardRequestDetail?.identifiers || []).reduce(
    (accumulator, { scheme, value }) => {
      accumulator.set(scheme, [...(accumulator.get(scheme) || []), value]);
      return accumulator;
    },
    new Map()
  );

  const flagStates = ["CANCELLED"];

  return (
    <div {...rootProps}>
      <AttributeList disablePadding dense ref={ref}>
        <ListItem>
          <ListItemText
            primary="Status"
            secondary={
              <StatusDisplay
                status={cardRequestDetail?.workflowState || "Unknown"}
                isFlagged={flagStates.includes(cardRequestDetail?.workflowState || "")}
                useMapping={workflowStatusDetails}
              />
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            secondary={renderAttribute(
              cardRequestDetail?.issueNumber !== undefined &&
                cardRequestDetail?.issueNumber !== null
                ? cardRequestDetail.issueNumber.toString()
                : undefined
            )}
            primary="Issue number"
          />
        </ListItem>
        {IDENTIFIERS_TO_SHOW.map(({ scheme, name, tooltip }) => (
          <ListItem key={scheme}>
            <ListItemText
              secondary={renderAttribute((identifierValues.get(scheme) || []).join(", "))}
              primary={
                tooltip ? (
                  <Tooltip title={tooltip}>
                    <span>{name}</span>
                  </Tooltip>
                ) : (
                  name
                )
              }
            />
          </ListItem>
        ))}
        <ListItem>
          <ListItemText
            secondary={renderAttribute(scarfName, {
              isLoading: cardDetailIsLoading || scarfIsLoading,
            })}
            primary="Scarf code"
          />
        </ListItem>
        <ListItem>
          <ListItemText secondary={renderAttribute(issuedAt)} primary="Issued on" />
        </ListItem>
        <ListItem>
          <ListItemText secondary={renderAttribute(expiresAt)} primary="Expires on" />
        </ListItem>
        <ListItem>
          <ListItemText secondary={renderAttribute(revokedAt)} primary="Revoked on" />
        </ListItem>
        <ListItem>
          <ListItemText secondary={renderAttribute(id)} primary="Card Request UUID" />
        </ListItem>
      </AttributeList>
    </div>
  );
};

export default CardRequestAttributes;
