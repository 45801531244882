/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Class representing a person's identifier, for use by the web service API.
 * @export
 * @interface Identifier
 */
export interface Identifier {
  /**
   * The identifier's scheme (e.g., "crsid").
   * @type {string}
   * @memberof Identifier
   */
  scheme?: string;
  /**
   * The identifier's value in that scheme (e.g., a specific CRSid value).
   * @type {string}
   * @memberof Identifier
   */
  value?: string;
}

export function IdentifierFromJSON(json: any): Identifier {
  return IdentifierFromJSONTyped(json, false);
}

export function IdentifierFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Identifier {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    scheme: !exists(json, "scheme") ? undefined : json["scheme"],
    value: !exists(json, "value") ? undefined : json["value"],
  };
}

export function IdentifierToJSON(value?: Identifier | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    scheme: value.scheme,
    value: value.value,
  };
}
