import { ComponentProps, useEffect, useRef, useState } from "react";
import { useStyles } from "../styles/styles";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { useSnackbar } from "notistack";
import LookupPersonAttributes from "./LookupPersonAttributes";
import { Person } from "../api/lookup-api";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { CRSID_SCHEME } from "../api/card-api";
import {
  useNewestPhoto,
  usePhotoPermissions,
  useReviewPhoto,
  StatusEnum,
  CreateStatusEnum,
  Photo,
} from "../api/photo-api";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import StatusDisplay from "./StatusDisplayComponent";
import { photoStatusDetails } from "../api/photo-api";

import { PhotoRejectDialog } from "./PhotoRejectDialog";
import { PhotoUploadDialog } from "./PhotoUploadDialog";

interface Props extends ComponentProps<typeof Card> {
  shortScheme: string;
  identifier: string;
  fullIdentifier: string;
  isPersonLoading: boolean;
  person?: Person | null;
}

/**
 * A Card component which represents a particular person's Lookup profile and
 * most recent Photo API photo.
 */
export const CardholderDetailsCard = ({
  shortScheme,
  identifier,
  fullIdentifier,
  isPersonLoading,
  person,
  ...cardProps
}: Props) => {
  const lookupProfileUrl = `https://www.lookup.cam.ac.uk/person/${shortScheme}/${identifier}`;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isLookupProfileValid = person && !person?.cancelled;

  // Check which permissions the current user has and gate features based on this
  const { permissions: photoPermissions } = usePhotoPermissions();
  const canUploadPhotos = !!(
    photoPermissions?.includes("PHOTO_VIEWER") && photoPermissions?.includes("PHOTO_CREATOR")
  );
  const canReviewPhotos = !!(
    photoPermissions?.includes("PHOTO_VIEWER") && photoPermissions?.includes("PHOTO_REVIEWER")
  );

  // Fetch the latest photo of the person
  const {
    isLoading: isPhotoLoading,
    photo,
    photoContentBlob,
    uploadPhoto,
  } = useNewestPhoto({
    identifier: fullIdentifier,
    onResult: (msg, severity) => enqueueSnackbar(msg, { variant: severity }),
  });
  const [photoContent, setPhotoContent] = useState<string | undefined>(undefined);
  const [curPhotoContentBlob, setCurPhotoContentBlob] = useState<Blob | undefined>(undefined);
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);

  // Mutations for reviewing an unapproved photo
  const {
    approvePhoto,
    isLoading: isReviewOperationInProgress,
    rejectPhoto,
    invalidatePhoto,
  } = useReviewPhoto({
    id: (photo as Photo | undefined)?.id,
    identifier: fullIdentifier,
    onResult: (msg, severity) => enqueueSnackbar(msg, { variant: severity }),
  });

  // Handle a new file being selected in the file select dialog, in which case upload the file
  const uploadFile = useRef<HTMLInputElement | null>(null);
  const onUploadFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      uploadPhoto.mutate({
        identifier: fullIdentifier,
        contentBlob: event.target.files[0],
        status: CreateStatusEnum.Unapproved,
      });
    }
    event.target.value = "";
  };

  // Manage the object URL for displaying the person's approved photo
  useEffect(() => {
    if (curPhotoContentBlob !== photoContentBlob) {
      setCurPhotoContentBlob(photoContentBlob);
      if (photoContent) {
        URL.revokeObjectURL(photoContent);
      }
      if (photoContentBlob) {
        setPhotoContent(URL.createObjectURL(photoContentBlob));
      } else {
        setPhotoContent(undefined);
      }
    }

    return () => {
      if (photoContent) {
        URL.revokeObjectURL(photoContent);
      }
    };
  }, [curPhotoContentBlob, photoContent, photoContentBlob]);

  const isLoading = isPersonLoading || isPhotoLoading || isReviewOperationInProgress;

  return (
    <Card {...cardProps}>
      <Box display="flex" flexDirection="column">
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            User Profile - {person ? person.displayName : "Unknown"} ({identifier.split("@", 1)[0]}
            )
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              {isPhotoLoading && (
                <Box pt={1} pb={1}>
                  <LinearProgress />
                </Box>
              )}

              {!isPhotoLoading && !photo && (
                <Alert severity="warning" elevation={1}>
                  <AlertTitle>No user photo</AlertTitle>
                  <Typography gutterBottom variant="inherit" component="p">
                    {identifier} does not have a corresponding photo.
                  </Typography>
                </Alert>
              )}

              {!isPhotoLoading && photo && photoContent && (
                <>
                  {/* display the user photo */}
                  <Box className={classes.alignCenter}>
                    <Box>
                      <img
                        className={classes.customImage}
                        src={photoContent}
                        alt="user"
                        style={{ maxHeight: "300px" }}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.alignCenter}>
                    <Typography variant="inherit" color="textSecondary">
                      The most recent{" "}
                      <StatusDisplay
                        status={(photo as Photo).status}
                        useMapping={photoStatusDetails}
                      />{" "}
                      photo of the user.
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              {isPersonLoading && (
                <Box pt={1} pb={1}>
                  <LinearProgress />
                </Box>
              )}
              {!isPersonLoading && !person && (
                <Alert severity="warning" elevation={1}>
                  <AlertTitle>No Lookup profile</AlertTitle>
                  <Typography gutterBottom variant="inherit" component="p">
                    {identifier} does not have a corresponding Lookup profile.
                  </Typography>
                </Alert>
              )}

              {!isPersonLoading && person && (
                <>
                  <Box flexGrow={1}>
                    <LookupPersonAttributes person={person} isLoading={isPersonLoading} />
                  </Box>
                </>
              )}

              {!isPersonLoading && photo && photoContent && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <List disablePadding dense>
                    <ListItem>
                      <ListItemText secondary={(photo as Photo).id} primary="Photo UUID" />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        secondary={(photo as Photo).submittedBy.replace(`@${CRSID_SCHEME}`, "")}
                        primary="Submitted by"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        secondary={
                          photo && (photo as Photo).createdAt
                            ? (photo as Photo).createdAt.toLocaleString([], {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                              })
                            : ""
                        }
                        primary="Upload date"
                      />
                    </ListItem>
                  </List>
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>

        <Divider />

        <CardActions>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              {(canUploadPhotos || canReviewPhotos) && (
                <>
                  <PhotoRejectDialog
                    open={rejectModalOpen}
                    onReject={(rejectReason?: string) =>
                      rejectPhoto.mutate({ rejectReason: rejectReason })
                    }
                    onClose={() => setRejectModalOpen(false)}
                  />
                  <PhotoUploadDialog
                    open={uploadModalOpen}
                    onUpload={() => uploadFile.current?.click()}
                    onClose={() => setUploadModalOpen(false)}
                  />

                  <Box
                    display="flex"
                    flexDirection="row-reverse"
                    justifyContent="space-between"
                    width="100%"
                  >
                    {canUploadPhotos && (
                      <Box m={1}>
                        <input
                          type="file"
                          id="file"
                          accept="image/jpg, image/png, image/heic, image/heif"
                          ref={uploadFile}
                          style={{ display: "none" }}
                          onChange={onUploadFileChange}
                        />
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={isLoading || !isLookupProfileValid}
                          onClick={() => setUploadModalOpen(true)}
                        >
                          Upload new photo
                        </Button>
                      </Box>
                    )}
                    {canReviewPhotos &&
                      photo &&
                      (photo as Photo).status === StatusEnum.Approved && (
                        <Box display="flex" flexDirection="row">
                          <Box m={1}>
                            <Tooltip title="Invalidate the approved photo">
                              <span>
                                <Button
                                  startIcon={<ClearIcon />}
                                  color="secondary"
                                  variant="contained"
                                  disabled={isLoading}
                                  onClick={() => invalidatePhoto.mutate()}
                                >
                                  Invalidate Photo
                                </Button>
                              </span>
                            </Tooltip>
                          </Box>
                        </Box>
                      )}
                    {canReviewPhotos &&
                      photo &&
                      (photo as Photo).status === StatusEnum.Unapproved && (
                        <Box display="flex" flexDirection="row">
                          <Box m={1}>
                            <Tooltip title="Reject the photo">
                              <span>
                                <Button
                                  startIcon={<ClearIcon />}
                                  color="secondary"
                                  variant="contained"
                                  disabled={isLoading}
                                  onClick={() => setRejectModalOpen(true)}
                                >
                                  Reject
                                </Button>
                              </span>
                            </Tooltip>
                          </Box>
                          <Box m={1}>
                            <Tooltip title="Approve the photo">
                              <span>
                                <Button
                                  startIcon={<CheckIcon />}
                                  color="primary"
                                  variant="contained"
                                  disabled={isLoading}
                                  onClick={() => approvePhoto.mutate()}
                                >
                                  Approve
                                </Button>
                              </span>
                            </Tooltip>
                          </Box>
                        </Box>
                      )}
                  </Box>
                </>
              )}
            </Grid>

            <Grid item xs={6} md={6} lg={6}>
              <Box display="flex" flexDirection="row-reverse">
                <Box m={1}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isPersonLoading || !person}
                    component="a"
                    target="_blank"
                    href={lookupProfileUrl}
                  >
                    Open Lookup profile
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardActions>
      </Box>
    </Card>
  );
};

export default CardholderDetailsCard;
