import { useParams } from "react-router";
import { Box, Link, Grid, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { MaxWidthContent } from "../styles/layout";

import { useCardholderCrsId, CardIdentifierSummarySchemeEnum } from "../api/card-api";
import { usePersonGetPersonQuery } from "../api/lookup-api";

import { CardholderCardsCard } from "../components";
import CardholderDetailsCard from "../components/CardholderUserProfileCard";

export const PersonDetailPage = () => {
  const { personIdValue, personIdScheme } = useParams<{
    personIdValue: string;
    personIdScheme: string;
  }>();
  // Identifier for the person that could be of any supported scheme
  const fullIdentifier = `${personIdValue}@${personIdScheme}`;

  // Attempt to get a CRSid for the person from Card API in case we're not passed one
  const { data: fetchedCrsid, isLoading: isLoadingCardholderCrsid } =
    useCardholderCrsId(fullIdentifier);
  const crsid =
    personIdScheme === CardIdentifierSummarySchemeEnum.V1PersonIdentifiersCamAcUk
      ? personIdValue
      : fetchedCrsid;

  // Query Lookup by CRSid for the appropriate person. Wait for the request to Card API for a
  // CRSid before considering the lookup complete to ensure we actually have a CRSid (if one is
  // available).
  const lookupRequestParams = crsid
    ? {
        scheme: "crsid",
        identifier: crsid.toLowerCase(),
        fetch: "all_identifiers,all_insts,registeredName,misAffiliation,jdCollege,jdInstid,title",
      }
    : undefined;
  const { isLoading: isLookupLoading, data: person } =
    usePersonGetPersonQuery(lookupRequestParams);
  const isPersonLoading = isLoadingCardholderCrsid || isLookupLoading;

  return (
    <MaxWidthContent>
      <Box m={2}>
        {!isLoadingCardholderCrsid && !crsid && (
          <Box mb={2}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={12} lg={12}>
                <Alert severity="warning" elevation={1}>
                  <AlertTitle>No CRSid recorded</AlertTitle>
                  <Typography gutterBottom variant="inherit" component="p">
                    This card was imported from the legacy card system, no CRSid is linked to this
                    record. Please search using the cardholders CRSid to check if more recent card
                    has been issued and to request a replacement card.
                  </Typography>
                  <Typography variant="inherit" component="p">
                    Please contact the Card Office ({" "}
                    <Link href="mailto:universitycard@admin.cam.ac.uk" color="textSecondary">
                      universitycard@admin.cam.ac.uk
                    </Link>
                    ) for further assistance.
                  </Typography>
                </Alert>
              </Grid>
            </Grid>
          </Box>
        )}
        <Grid container spacing={2} alignItems="stretch" justifyContent="center">
          {/* These sizes need to be kept in sync with Alert above. */}

          <Grid item xs={12} md={12} lg={12}>
            <CardholderCardsCard
              identifier={fullIdentifier}
              displayName={person?.displayName || "Cardholder"}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            {crsid && (
              <CardholderDetailsCard
                shortScheme="crsid"
                identifier={crsid}
                fullIdentifier={fullIdentifier}
                isPersonLoading={isPersonLoading}
                person={person}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </MaxWidthContent>
  );
};
