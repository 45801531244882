import Cookies from "universal-cookie";
import { useUrlQueryParams } from "../routing";

export const DebugPanel = () => {
  const DEBUG_PARAMETER = "DEBUG";
  const cookies = new Cookies();

  const [queryParams, updateQueryParams] = useUrlQueryParams();

  function addCookieParameter(parameterName: string, parameterValue: string) {
    cookies.set(parameterName, parameterValue, { path: "/" });
    return null;
  }

  function deleteCookieParameter(parameterName: string) {
    cookies.remove(parameterName, { path: "/" });
    return null;
  }

  function checkCookieParameter(parameterName: string) {
    const cookie = cookies.get(parameterName);
    return cookie;
  }

  function disableDebugMode() {
    deleteCookieParameter(DEBUG_PARAMETER);
    updateQueryParams(DEBUG_PARAMETER, "");
    return null;
  }

  function checkDebugMode() {
    const DEBUG_PATH = queryParams.get(DEBUG_PARAMETER);
    const DEBUG_COOKIE = checkCookieParameter(DEBUG_PARAMETER);
    if (
      (DEBUG_PATH === null || DEBUG_PATH === "" || DEBUG_PATH === undefined) &&
      (DEBUG_COOKIE === null || DEBUG_COOKIE === undefined || DEBUG_COOKIE === "")
    ) {
      deleteCookieParameter(DEBUG_PARAMETER);
      return false;
    }
    addCookieParameter(DEBUG_PARAMETER, "1");
    if (!DEBUG_PATH) updateQueryParams(DEBUG_PARAMETER, "1");

    return true;
  }

  if (!checkDebugMode()) return null;

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        right: 0,
        zIndex: 1000,
        background: "white",
        border: "1px solid red",
        padding: "1em",
        width: "15em",
        height: "auto",
        margin: "1em",
        color: "black",
      }}
    >
      <div id="debugModePanel">
        <h3>Debug Panel</h3>
        <div className="input-group mb-3">
          <input
            type="checkbox"
            id="debugMode"
            name="debugMode"
            value="debugMode"
            checked={checkDebugMode()}
            onChange={() => {
              disableDebugMode();
            }}
          />
          <span className="input-group-prepend">
            <span className="input-group-text" id="basic-label-1">
              Debug mode is on
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};
