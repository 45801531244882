/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 * @export
 * @interface InlineResponse20012Result
 */
export interface InlineResponse20012Result {
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse20012Result
   */
  value?: boolean;
}

export function InlineResponse20012ResultFromJSON(
  json: any
): InlineResponse20012Result {
  return InlineResponse20012ResultFromJSONTyped(json, false);
}

export function InlineResponse20012ResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineResponse20012Result {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    value: !exists(json, "value") ? undefined : json["value"],
  };
}

export function InlineResponse20012ResultToJSON(
  value?: InlineResponse20012Result | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    value: value.value,
  };
}
