/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Class representing an attribute scheme. This may apply to attributes of
 * people or institutions.
 * @export
 * @interface AttributeScheme
 */
export interface AttributeScheme {
  /**
   * The unique identifier of the attribute scheme.
   * @type {string}
   * @memberof AttributeScheme
   */
  schemeid?: string;
  /**
   * The attribute scheme's precedence. Methods that return or display
   * attributes sort the results primarily in order of increasing values
   * of attribute scheme precedence.
   * @type {number}
   * @memberof AttributeScheme
   */
  precedence?: number;
  /**
   * The name of the attribute scheme in LDAP, if it is exported to LDAP.
   * Note that many attributes are not exported to LDAP, in which case
   * this name is typically just equal to the scheme's ID.
   * @type {string}
   * @memberof AttributeScheme
   */
  ldapName?: string;
  /**
   * The display name for labelling attributes in this scheme.
   * @type {string}
   * @memberof AttributeScheme
   */
  displayName?: string;
  /**
   * The attribute scheme's datatype.
   * @type {string}
   * @memberof AttributeScheme
   */
  dataType?: string;
  /**
   * Flag indicating whether attributes in this scheme can be multi-valued.
   * @type {boolean}
   * @memberof AttributeScheme
   */
  multiValued?: boolean;
  /**
   * Flag for textual attributes schemes indicating whether they are
   * multi-lined.
   * @type {boolean}
   * @memberof AttributeScheme
   */
  multiLined?: boolean;
  /**
   * Flag indicating whether attributes of this scheme are searched by
   * the default search functionality.
   * @type {boolean}
   * @memberof AttributeScheme
   */
  searchable?: boolean;
  /**
   * For textual attributes, an optional regular expression that all
   * attributes in this scheme match.
   * @type {string}
   * @memberof AttributeScheme
   */
  regexp?: string;
}

export function AttributeSchemeFromJSON(json: any): AttributeScheme {
  return AttributeSchemeFromJSONTyped(json, false);
}

export function AttributeSchemeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AttributeScheme {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    schemeid: !exists(json, "schemeid") ? undefined : json["schemeid"],
    precedence: !exists(json, "precedence") ? undefined : json["precedence"],
    ldapName: !exists(json, "ldapName") ? undefined : json["ldapName"],
    displayName: !exists(json, "displayName") ? undefined : json["displayName"],
    dataType: !exists(json, "dataType") ? undefined : json["dataType"],
    multiValued: !exists(json, "multiValued") ? undefined : json["multiValued"],
    multiLined: !exists(json, "multiLined") ? undefined : json["multiLined"],
    searchable: !exists(json, "searchable") ? undefined : json["searchable"],
    regexp: !exists(json, "regexp") ? undefined : json["regexp"],
  };
}

export function AttributeSchemeToJSON(value?: AttributeScheme | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    schemeid: value.schemeid,
    precedence: value.precedence,
    ldapName: value.ldapName,
    displayName: value.displayName,
    dataType: value.dataType,
    multiValued: value.multiValued,
    multiLined: value.multiLined,
    searchable: value.searchable,
    regexp: value.regexp,
  };
}
