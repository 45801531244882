import { Template, generate, BLANK_PDF } from "@pdfme/generator";

const fontSize: number = 10;
const sheetHeight: number = 297;
const sheetWidth: number = 210;
const sheetMarginTop: number = 15;
const sheetMarginSide: number = 5;
const minSheetMargin: number = Math.min(sheetMarginTop, sheetMarginSide);
const sheetGutterCols: number = 3;
const sheetCols: number = 2;
const sheetRows: number = 7;

const maxLabelsPerSheet = sheetRows * sheetCols;
const labelWidth: number = (sheetWidth - 2 * sheetMarginSide - sheetGutterCols) / sheetCols;
const labelHeight: number = (sheetHeight - 2 * sheetMarginTop) / sheetRows;

const maxCardsPerList = 50;
const maxCardListsPerPage = 4;
const cardListWidth =
  (sheetWidth - minSheetMargin * (maxCardListsPerPage + 1)) / maxCardListsPerPage;
const cardListHeight = sheetHeight - 2 * minSheetMargin;

const template: Template = {
  // template matching Avery labels 38mmx99mm (7x2)
  basePdf: BLANK_PDF,
  schemas: [
    {
      "0": {
        type: "text",
        position: {
          x: sheetMarginSide + labelWidth * 0,
          y: sheetMarginTop + labelHeight * 0,
        },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      "1": {
        type: "text",
        position: {
          x: sheetMarginSide + labelWidth * 0,
          y: sheetMarginTop + labelHeight * 1,
        },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      "2": {
        type: "text",
        position: {
          x: sheetMarginSide + labelWidth * 0,
          y: sheetMarginTop + labelHeight * 2,
        },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      "3": {
        type: "text",
        position: {
          x: sheetMarginSide + labelWidth * 0,
          y: sheetMarginTop + labelHeight * 3,
        },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      "4": {
        type: "text",
        position: {
          x: sheetMarginSide + labelWidth * 0,
          y: sheetMarginTop + labelHeight * 4,
        },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      "5": {
        type: "text",
        position: {
          x: sheetMarginSide + labelWidth * 0,
          y: sheetMarginTop + labelHeight * 5,
        },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      "6": {
        type: "text",
        position: {
          x: sheetMarginSide + labelWidth * 0,
          y: sheetMarginTop + labelHeight * 6,
        },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      "7": {
        type: "text",
        position: {
          x: sheetMarginSide + labelWidth * 1 + sheetGutterCols,
          y: sheetMarginTop + labelHeight * 0,
        },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      "8": {
        type: "text",
        position: {
          x: sheetMarginSide + labelWidth * 1 + sheetGutterCols,
          y: sheetMarginTop + labelHeight * 1,
        },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      "9": {
        type: "text",
        position: {
          x: sheetMarginSide + labelWidth * 1 + sheetGutterCols,
          y: sheetMarginTop + labelHeight * 2,
        },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      "10": {
        type: "text",
        position: {
          x: sheetMarginSide + labelWidth * 1 + sheetGutterCols,
          y: sheetMarginTop + labelHeight * 3,
        },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      "11": {
        type: "text",
        position: {
          x: sheetMarginSide + labelWidth * 1 + sheetGutterCols,
          y: sheetMarginTop + labelHeight * 4,
        },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      "12": {
        type: "text",
        position: {
          x: sheetMarginSide + labelWidth * 1 + sheetGutterCols,
          y: sheetMarginTop + labelHeight * 5,
        },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      "13": {
        type: "text",
        position: {
          x: sheetMarginSide + labelWidth * 1 + sheetGutterCols,
          y: sheetMarginTop + labelHeight * 6,
        },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },

      // card lists that ignore the Avery label layout
      "CARDLIST-0": {
        type: "text",
        position: {
          x: minSheetMargin + (minSheetMargin + cardListWidth) * 0,
          y: minSheetMargin,
        },
        width: cardListWidth,
        height: cardListHeight,
        alignment: "left",
        fontSize: fontSize,
      },
      "CARDLIST-1": {
        type: "text",
        position: {
          x: minSheetMargin + (minSheetMargin + cardListWidth) * 1,
          y: minSheetMargin,
        },
        width: cardListWidth,
        height: cardListHeight,
        alignment: "left",
        fontSize: fontSize,
      },
      "CARDLIST-2": {
        type: "text",
        position: {
          x: minSheetMargin + (minSheetMargin + cardListWidth) * 2,
          y: minSheetMargin,
        },
        width: cardListWidth,
        height: cardListHeight,
        alignment: "left",
        fontSize: fontSize,
      },
      "CARDLIST-3": {
        type: "text",
        position: {
          x: minSheetMargin + (minSheetMargin + cardListWidth) * 3,
          y: minSheetMargin,
        },
        width: cardListWidth,
        height: cardListHeight,
        alignment: "left",
        fontSize: fontSize,
      },
    },
  ],
};

export function generateLabels(
  cardInfo: Array<{ code: string; crsid: string; issueNumber: string }>,
  addressInfo: Array<{ code: string; destination: string; address?: string }>
) {
  const inputs = [];
  const cards = cardInfo
    .map(({ crsid, issueNumber, code }) => `${code} ${crsid}/${issueNumber}`)
    .sort();

  const cardLists = [];
  for (let i = 0; i < cards.length; i += maxCardsPerList)
    cardLists.push(cards.slice(i, i + maxCardsPerList).join("\n"));
  const addresses = addressInfo.map(
    ({ code, destination, address }) =>
      `\nc/o Card Representative - ${code}\n${destination}\n${address}`
  );

  // map the cardLists into the per page template locations
  for (var index = 0; index < cardLists.length; index += maxCardListsPerPage) {
    const page: Record<string, string> = {};
    const cardGroupsonPage = cardLists.slice(index, index + maxCardListsPerPage);
    for (var pageIndex = 0; pageIndex < cardGroupsonPage.length; pageIndex += 1) {
      page["CARDLIST-" + String(pageIndex)] = cardGroupsonPage[pageIndex];
    }
    inputs.push(page);
  }

  // map the addresses into the per page template locations
  for (var addressIndex = 0; addressIndex < addresses.length; addressIndex += maxLabelsPerSheet) {
    const page: Record<string, string> = {};
    const addressesOnPage = addresses.slice(addressIndex, addressIndex + maxLabelsPerSheet);
    for (
      var addressPageIndex = 0;
      addressPageIndex < addressesOnPage.length;
      addressPageIndex += 1
    ) {
      page[String(addressPageIndex)] = addressesOnPage[addressPageIndex];
    }
    inputs.push(page);
  }

  // generate a pdf and return the pdf to the browser
  generate({ template, inputs }).then((pdf) => {
    const blob = new Blob([pdf.buffer], { type: "application/pdf" });
    window.open(URL.createObjectURL(blob));
  });
}
