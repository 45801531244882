/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Institution,
  InstitutionFromJSON,
  InstitutionFromJSONTyped,
  InstitutionToJSON,
  Person,
  PersonFromJSON,
  PersonFromJSONTyped,
  PersonToJSON,
} from "./";

/**
 * Class representing a group returned by the web service API.
 * @export
 * @interface Group
 */
export interface Group {
  /**
   * Flag indicating if the group is cancelled.
   * @type {boolean}
   * @memberof Group
   */
  cancelled?: boolean;
  /**
   * The group's numeric ID (actually a string e.g., "100656").
   * @type {string}
   * @memberof Group
   */
  groupid?: string;
  /**
   * The group's unique name (e.g., "cs-editors").
   * @type {string}
   * @memberof Group
   */
  name?: string;
  /**
   * The group's title.
   * @type {string}
   * @memberof Group
   */
  title?: string;
  /**
   * The more detailed description of the group.
   * @type {string}
   * @memberof Group
   */
  description?: string;
  /**
   * The group's email address.
   * @type {string}
   * @memberof Group
   */
  email?: string;
  /**
   *
   * @type {Institution}
   * @memberof Group
   */
  membersOfInst?: Institution;
  /**
   * A list of the group's members, including (recursively) any members of
   * any included groups. This will only be populated if the
   * `fetch` parameter includes the `"all_members"`
   * option.
   * @type {Array<Person>}
   * @memberof Group
   */
  members?: Array<Person>;
  /**
   * A list of the group's direct members, not including any members
   * included via groups included by this group. This will only be
   * populated if the `fetch` parameter includes the
   * `"direct_members"` option.
   * @type {Array<Person>}
   * @memberof Group
   */
  directMembers?: Array<Person>;
  /**
   * A list of the institutions to which this group belongs. This will only
   * be populated if the `fetch` parameter includes the
   * `"owning_insts"` option.
   * @type {Array<Institution>}
   * @memberof Group
   */
  owningInsts?: Array<Institution>;
  /**
   * A list of the institutions managed by this group. This will only be
   * populated if the `fetch` parameter includes the
   * `"manages_insts"` option.
   * @type {Array<Institution>}
   * @memberof Group
   */
  managesInsts?: Array<Institution>;
  /**
   * A list of the groups managed by this group. This will only be
   * populated if the `fetch` parameter includes the
   * `"manages_groups"` option.
   * @type {Array<Group>}
   * @memberof Group
   */
  managesGroups?: Array<Group>;
  /**
   * A list of the groups that manage this group. This will only be
   * populated if the `fetch` parameter includes the
   * `"managed_by_groups"` option.
   * @type {Array<Group>}
   * @memberof Group
   */
  managedByGroups?: Array<Group>;
  /**
   * A list of the groups that this group has privileged access to. Members
   * of this group will be able to read the members of any of those groups,
   * regardless of the membership visibilities. This will only be populated
   * if the `fetch` parameter includes the
   * `"reads_groups"` option.
   * @type {Array<Group>}
   * @memberof Group
   */
  readsGroups?: Array<Group>;
  /**
   * A list of the groups that have privileged access to this group.
   * Members of those groups will be able to read the members of this
   * group, regardless of the membership visibilities. This will only be
   * populated if the `fetch` parameter includes the
   * `"read_by_groups"` option.
   * @type {Array<Group>}
   * @memberof Group
   */
  readByGroups?: Array<Group>;
  /**
   * A list of the groups directly included in this group. Any members of
   * the included groups (and recursively any groups that they include)
   * will automatically be included in this group. This will only be
   * populated if the `fetch` parameter includes the
   * `"includes_groups"` option.
   * @type {Array<Group>}
   * @memberof Group
   */
  includesGroups?: Array<Group>;
  /**
   * A list of the groups that directly include this group. Any members of
   * this group will automatically be included in those groups (and
   * recursively in any groups that include those groups). This will only
   * be populated if the `fetch` parameter includes the
   * `"included_by_groups"` option.
   * @type {Array<Group>}
   * @memberof Group
   */
  includedByGroups?: Array<Group>;
  /**
   * An ID that can uniquely identify this group within the returned
   * XML/JSON document. This is only used in the flattened XML/JSON
   * representation (if the "flatten" parameter is specified).
   * @type {string}
   * @memberof Group
   */
  id?: string;
  /**
   * A reference (by id) to a group element in the XML/JSON document.
   * This is only used in the flattened XML/JSON representation (if the
   * "flatten" parameter is specified).
   * @type {string}
   * @memberof Group
   */
  ref?: string;
}

export function GroupFromJSON(json: any): Group {
  return GroupFromJSONTyped(json, false);
}

export function GroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Group {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cancelled: !exists(json, "cancelled") ? undefined : json["cancelled"],
    groupid: !exists(json, "groupid") ? undefined : json["groupid"],
    name: !exists(json, "name") ? undefined : json["name"],
    title: !exists(json, "title") ? undefined : json["title"],
    description: !exists(json, "description") ? undefined : json["description"],
    email: !exists(json, "email") ? undefined : json["email"],
    membersOfInst: !exists(json, "membersOfInst")
      ? undefined
      : InstitutionFromJSON(json["membersOfInst"]),
    members: !exists(json, "members")
      ? undefined
      : (json["members"] as Array<any>).map(PersonFromJSON),
    directMembers: !exists(json, "directMembers")
      ? undefined
      : (json["directMembers"] as Array<any>).map(PersonFromJSON),
    owningInsts: !exists(json, "owningInsts")
      ? undefined
      : (json["owningInsts"] as Array<any>).map(InstitutionFromJSON),
    managesInsts: !exists(json, "managesInsts")
      ? undefined
      : (json["managesInsts"] as Array<any>).map(InstitutionFromJSON),
    managesGroups: !exists(json, "managesGroups")
      ? undefined
      : (json["managesGroups"] as Array<any>).map(GroupFromJSON),
    managedByGroups: !exists(json, "managedByGroups")
      ? undefined
      : (json["managedByGroups"] as Array<any>).map(GroupFromJSON),
    readsGroups: !exists(json, "readsGroups")
      ? undefined
      : (json["readsGroups"] as Array<any>).map(GroupFromJSON),
    readByGroups: !exists(json, "readByGroups")
      ? undefined
      : (json["readByGroups"] as Array<any>).map(GroupFromJSON),
    includesGroups: !exists(json, "includesGroups")
      ? undefined
      : (json["includesGroups"] as Array<any>).map(GroupFromJSON),
    includedByGroups: !exists(json, "includedByGroups")
      ? undefined
      : (json["includedByGroups"] as Array<any>).map(GroupFromJSON),
    id: !exists(json, "id") ? undefined : json["id"],
    ref: !exists(json, "ref") ? undefined : json["ref"],
  };
}

export function GroupToJSON(value?: Group | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cancelled: value.cancelled,
    groupid: value.groupid,
    name: value.name,
    title: value.title,
    description: value.description,
    email: value.email,
    membersOfInst: InstitutionToJSON(value.membersOfInst),
    members:
      value.members === undefined
        ? undefined
        : (value.members as Array<any>).map(PersonToJSON),
    directMembers:
      value.directMembers === undefined
        ? undefined
        : (value.directMembers as Array<any>).map(PersonToJSON),
    owningInsts:
      value.owningInsts === undefined
        ? undefined
        : (value.owningInsts as Array<any>).map(InstitutionToJSON),
    managesInsts:
      value.managesInsts === undefined
        ? undefined
        : (value.managesInsts as Array<any>).map(InstitutionToJSON),
    managesGroups:
      value.managesGroups === undefined
        ? undefined
        : (value.managesGroups as Array<any>).map(GroupToJSON),
    managedByGroups:
      value.managedByGroups === undefined
        ? undefined
        : (value.managedByGroups as Array<any>).map(GroupToJSON),
    readsGroups:
      value.readsGroups === undefined
        ? undefined
        : (value.readsGroups as Array<any>).map(GroupToJSON),
    readByGroups:
      value.readByGroups === undefined
        ? undefined
        : (value.readByGroups as Array<any>).map(GroupToJSON),
    includesGroups:
      value.includesGroups === undefined
        ? undefined
        : (value.includesGroups as Array<any>).map(GroupToJSON),
    includedByGroups:
      value.includedByGroups === undefined
        ? undefined
        : (value.includedByGroups as Array<any>).map(GroupToJSON),
    id: value.id,
    ref: value.ref,
  };
}
