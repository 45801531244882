import ReactDOM from "react-dom";
import { App } from "./App";

// For React Placeholder animation.
import "react-placeholder/lib/reactPlaceholder.css";

ReactDOM.render(<App />, document.getElementById("root"));

setTimeout(() => {
  // mark the application as started after the initial event loop - stops the top-level
  // error handling catching error events as we expect error boundaries to respond to them
  (window as any).__applicationStarted = true;
});
