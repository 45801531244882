import { useRef, useEffect, useCallback, useState } from "react";
import { Box, CircularProgress, Grid, Typography, Paper, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { PersonPreview, PeopleSearchInput, usePeopleSearch, QueryType } from ".";
import { useUrlQueryParams } from "../routing";
import { SearchInputRefObject, QueryTypeSelector } from "../components";
import { useInstitutionsList } from "../api/lookup-api";
import { RecentCardRequestsTable } from "../components/RecentCardRequestsTable";

import "react-placeholder/lib/reactPlaceholder.css";

const useStyles = makeStyles((theme: Theme) => ({
  personPreview: {
    animationName: "$appear",
    animationDuration: `${theme.transitions.duration.standard}ms`,
    animationTimingFunction: theme.transitions.easing.easeInOut,
    animationFillMode: "both",
  },

  "@keyframes appear": {
    from: {
      opacity: 0,
      transform: `translateY(${theme.spacing(2)}px)`,
    },
    to: {
      opacity: 1,
    },
  },
}));

export const PeoplePage = () => {
  const classes = useStyles();
  const searchRef = useRef<SearchInputRefObject | null>(null);
  const [query, setQuery] = useState("");
  const [queryType, setQueryType] = useState<QueryType>("ALL");
  const [queryInstitution, setQueryInstitution] = useState("");
  const [queryParams, updateQueryParams] = useUrlQueryParams();
  const { people, isLookupLoading, isCardLoading } = usePeopleSearch(
    query,
    queryType,
    queryInstitution
  );

  const { isLoading: isInstitutionsLoading, data: institutionInfo } = useInstitutionsList();
  const institutionCodes = institutionInfo
    ? Array.from(institutionInfo.keys()).sort((a, b) => a.localeCompare(b))
    : [];

  const search = useCallback(
    (queryTerm: string) => {
      updateQueryParams("search", queryTerm);
      setQuery(queryTerm);
    },
    [setQuery, updateQueryParams]
  );

  const updateSearchType = useCallback(
    (searchType: QueryType) => {
      updateQueryParams("type", searchType);
      setQueryType(searchType);
    },
    [setQueryType, updateQueryParams]
  );

  const updateSearchInstitution = useCallback(
    (searchInstitution: string) => {
      if (searchInstitution) {
        updateQueryParams("institution", searchInstitution);
        setQueryInstitution(searchInstitution);
      } else {
        updateQueryParams("institution", "ALL");
        setQueryInstitution("");
      }
    },
    [setQueryInstitution, updateQueryParams]
  );

  // if the query parameter changes - indicating that we've navigated, update the search term
  useEffect(() => {
    const searchParam = queryParams.get("search");
    const searchTypeParam = queryParams.get("type");
    const searchInstitutionParam = queryParams.get("institution");

    if (searchParam && searchParam !== query) {
      searchRef.current?.setValue(searchParam);
      setQuery(searchParam);
    }
    if (searchTypeParam && searchTypeParam !== queryType) {
      setQueryType(searchTypeParam as QueryType);
    }
    if (searchInstitutionParam && searchInstitutionParam !== queryInstitution) {
      if (searchInstitutionParam === "ALL") {
        setQueryInstitution("");
      } else {
        setQueryInstitution(searchInstitutionParam);
      }
    }
  }, [
    queryParams,
    query,
    setQuery,
    setQueryType,
    queryType,
    queryInstitution,
    setQueryInstitution,
  ]);

  const hasResults = people.length > 0;
  const isLoading = isLookupLoading || isCardLoading;

  return (
    <Box p={3}>
      <Box p={1} px={1} pt={1} pb={2} component={Paper}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
          style={{ marginLeft: "0rem", marginBottom: "0rem", marginTop: "0rem" }}
        >
          <Grid item xs="auto">
            <QueryTypeSelector selectedQueryType={queryType} updateQueryType={updateSearchType} />
          </Grid>
          <Grid item xs={4}>
            {!isInstitutionsLoading && (
              <Autocomplete
                id="institution-select"
                options={institutionCodes}
                getOptionLabel={(option) => `${option} - ${institutionInfo?.get(option) || ""}`}
                renderInput={(params) => (
                  <TextField {...params} label="Institution" variant="outlined" size="small" />
                )}
                value={queryInstitution ? queryInstitution : null}
                onChange={(_, newValue) =>
                  updateSearchInstitution(newValue === null ? "" : newValue)
                }
              />
            )}
          </Grid>
        </Grid>
        <PeopleSearchInput
          autoFocus
          variant="outlined"
          role="textbox"
          aria-label="Search for cardholders"
          placeholder="Search for cardholders"
          onSubmitQuery={search}
          ref={searchRef}
        />
      </Box>
      {isLoading && !hasResults && (
        <Box pt={10} textAlign="center">
          <CircularProgress size={60} />
        </Box>
      )}
      {!isLoading && !hasResults && (
        <>
          <Box pt={3} textAlign="center">
            <Typography color="textSecondary" variant="subtitle1">
              {query !== "" ? (
                <>No results for that search, sorry!</>
              ) : (
                <RecentCardRequestsTable />
              )}
            </Typography>
          </Box>
        </>
      )}
      {hasResults && (
        <Box pt={2}>
          <Grid container spacing={2}>
            {people.map((person, index) => (
              <Grid
                key={`${person.identifiers[0].value}@${person.identifiers[0].scheme}`}
                item
                xs={12}
                md={6}
                lg={4}
                xl={3}
                style={{ animationDelay: `${Math.min(index, 15) * 50}ms` }}
                className={classes.personPreview}
              >
                <PersonPreview person={person} cardsLoading={isCardLoading} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};
