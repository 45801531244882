import { useState } from "react";
import {
  Box,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Tooltip,
  CircularProgress,
  LinearProgress,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

import TablePagination from "@material-ui/core/TablePagination";
import PrintIcon from "@material-ui/icons/Print";
import RefreshIcon from "@material-ui/icons/Refresh";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ClearFiltersIcon from "@material-ui/icons/LayersClear";
import RemoveIcon from "@material-ui/icons/Remove";
import SortIcon from "@material-ui/icons/Sort";
import StatusDisplay from "../components/StatusDisplayComponent";

import { useAPIGateway } from "../api-gateway/APIGatewayProvider";

import {
  useCardAPI,
  useCardPermissions,
  useCardRequests,
  useCardRequestsCardholderStatus,
  useCardRequestsDestinations,
  useCardRequestsRequestors,
  CardRequestWorkflowFilterOptions,
  sortCardRequests,
  CardRequestBulkUpdateElementRequestActionEnum,
  CardRequestSummary,
  CardRequestSummaryWorkflowStateEnum,
  workflowStatusDetails,
  WorkflowFilterExtraOptions,
  orderedFiltersKeys,
  keyToDisplayFilterName,
} from "../api/card-api";
import { CRSID_SCHEME } from "../api/card-api";

import { Alert, AlertTitle } from "@material-ui/lab";
import { useInstitutionsForDelivery } from "../api/lookup-api";
import { generateLabels } from "../labels/Labels";
import { requestorDescription } from "./utils";
import { CardRequestContainer } from "../components/CardholderCards";

export interface FilterData {
  identifier?: string;
  cardholderStatus?: string;
  workflowState?: any;
  displayWorkflowState?: any;
  requestor?: string;
  destination?: string;
  ordering?: string;
}

interface CellFormatter {
  id: string;
  label: string;
  width: number;
  renderCell: (cardRequest: CardRequestSummary) => React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  activeIcon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

function CardRequestTableHead({
  numSelected,
  numExpanded,
  rowCount,
  onSelectAllClick,
  onExpandAllClick,
  allowSelection,
  cellFormatters,
}: {
  numSelected: number;
  numExpanded: number;
  rowCount: number;
  cellFormatters: CellFormatter[];
  allowSelection: boolean;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onExpandAllClick: (allExpanded: boolean) => void;
}) {
  const someExpanded = numExpanded > 0;
  const allExpanded = rowCount > 0 && numExpanded === rowCount;
  return (
    <TableHead>
      <TableRow>
        {allowSelection && (
          <TableCell align="center">
            <Tooltip title="Only card request which are ready for printing or verification may be selected in bulk">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </Tooltip>
          </TableCell>
        )}

        {cellFormatters.map((headCell) => (
          <TableCell key={headCell.id} align="center" width={`${headCell.width}%`}>
            {headCell.label}
          </TableCell>
        ))}

        <TableCell>
          <Tooltip title="Expand all card requests">
            <IconButton onClick={() => onExpandAllClick(allExpanded)}>
              {allExpanded ? (
                <ExpandLessIcon />
              ) : someExpanded ? (
                <RemoveIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

interface CardRequestTableRowProps {
  cardRequest: CardRequestSummary;
  isSelected: boolean;
  isExpanded: boolean;
  handleSelect: (cardRequestId: string) => void;
  handleExpand: (cardRequestId: string) => void;
  cellFormatters: CellFormatter[];
  allowSelection: boolean;
  onUpdate: () => void;
}

function CardRequestTableRow({
  cardRequest,
  isSelected,
  isExpanded,
  handleSelect,
  handleExpand,
  cellFormatters,
  allowSelection,
  onUpdate,
}: CardRequestTableRowProps) {
  const theme = useTheme();
  const cardRequestGoWorkflowStates = [CardRequestSummaryWorkflowStateEnum.Pending];
  const cardRequestWarningWorkflowStates = [
    CardRequestSummaryWorkflowStateEnum.PendingCrsidRequired,
    CardRequestSummaryWorkflowStateEnum.PendingDestinationRequired,
    CardRequestSummaryWorkflowStateEnum.PendingPhotoRequired,
    CardRequestSummaryWorkflowStateEnum.Hold,
  ];

  return (
    <>
      <TableRow hover>
        {allowSelection && (
          <TableCell align="center">
            <Tooltip title="Select card requests">
              <span>
                <Checkbox
                  checked={isSelected}
                  onClick={() => handleSelect(cardRequest.id!)}
                  disabled={
                    ![
                      CardRequestSummaryWorkflowStateEnum.Pending,
                      CardRequestSummaryWorkflowStateEnum.CreatingInverification,
                    ].includes(cardRequest.workflowState)
                  }
                />
              </span>
            </Tooltip>
          </TableCell>
        )}

        {cellFormatters.map((formatter) => (
          <TableCell key={formatter.id} align="center" width={`${formatter.width}%`}>
            {formatter.id === "workflowState" ? (
              cardRequestGoWorkflowStates.includes(cardRequest.workflowState) ? (
                <Alert severity="success">{formatter.renderCell(cardRequest)}</Alert>
              ) : cardRequestWarningWorkflowStates.includes(cardRequest.workflowState) ? (
                <Alert severity="warning">{formatter.renderCell(cardRequest)}</Alert>
              ) : (
                <Alert severity="info">{formatter.renderCell(cardRequest)}</Alert>
              )
            ) : (
              formatter.renderCell(cardRequest)
            )}
          </TableCell>
        ))}

        <TableCell>
          <IconButton onClick={() => handleExpand(cardRequest.id!)}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={8} padding="none">
          {isExpanded && (
            <Box padding={2} paddingBottom={3} bgcolor={theme.palette.action.selected}>
              <CardRequestContainer
                cardRequest={cardRequest!}
                onChange={onUpdate}
                showPersonLink={true}
              />
            </Box>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

function CardRequestTableBody({
  isLoading,
  cardRequests,
  isSelected,
  isExpanded,
  handleSelect,
  handleExpand,
  cellFormatters,
  allowSelection,
  onUpdate,
}: {
  isLoading: boolean;
  cardRequests: CardRequestSummary[];
  isSelected: (cardRequestId: string) => boolean;
  isExpanded: (cardRequestId: string) => boolean;
  handleSelect: (cardRequestId: string) => void;
  handleExpand: (cardRequestId: string) => void;
  cellFormatters: CellFormatter[];
  allowSelection: boolean;
  onUpdate: () => void;
}) {
  return (
    <TableBody>
      {isLoading && (
        <TableRow>
          <TableCell colSpan={8} padding="none">
            <LinearProgress />
          </TableCell>
        </TableRow>
      )}
      {!isLoading && cardRequests.length === 0 && (
        <TableRow>
          <TableCell colSpan={7}>
            <Typography variant="body2" align="center">
              No card requests for selected parameters
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {cardRequests.map((cardRequest, index) => {
        return (
          <CardRequestTableRow
            key={cardRequest.id!}
            cardRequest={cardRequest}
            isSelected={isSelected(cardRequest.id!)}
            isExpanded={isExpanded(cardRequest.id!)}
            handleSelect={handleSelect}
            handleExpand={handleExpand}
            cellFormatters={cellFormatters}
            allowSelection={allowSelection}
            onUpdate={onUpdate}
          />
        );
      })}
    </TableBody>
  );
}

function QueueVerifyDialog({
  cardRequestIds,
  open,
  onClose,
  onQueued,
  onGenerateLabels,
}: {
  cardRequestIds: string[];
  open: boolean;
  onClose: () => void;
  onQueued: () => void;
  onGenerateLabels: () => void;
}) {
  const cardApi = useCardAPI();
  const [isLoading, setLoading] = useState(false);
  const [errorDescription, setErrorDescription] = useState<null | string>(null);
  const [downloadedLabels, setDownloadedLabels] = useState(false);

  const onQueueConfirmed = async () => {
    try {
      setLoading(true);
      setErrorDescription(null);
      await cardApi.v1beta1CardRequestsUpdateUpdate({
        cardRequestBulkUpdateRequest: {
          updates: cardRequestIds.map((cardRequestId) => {
            return {
              id: cardRequestId,
              action: CardRequestBulkUpdateElementRequestActionEnum.Complete,
            };
          }),
        },
      });

      onQueued();
      setDownloadedLabels(false);
    } catch (e) {
      setErrorDescription((e as any).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Verify card requests for shipping</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" gutterBottom>
            Download labels for the cards which have been verified and mark them as accepted. This
            indicates that the cards will be shipped on this day.
          </Typography>

          {errorDescription && (
            <Alert severity="error">
              <AlertTitle>Verification failed!</AlertTitle> {errorDescription}{" "}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Box flex="1">
            <Button
              disabled={isLoading}
              onClick={() => {
                onGenerateLabels();
                setDownloadedLabels(true);
              }}
            >
              Download Labels
            </Button>
          </Box>
          <Button
            autoFocus
            disabled={isLoading}
            onClick={() => {
              onClose();
              setDownloadedLabels(false);
            }}
          >
            Cancel
          </Button>
          <Tooltip title="Labels must be downloaded before the cards can be accepted">
            <span>
              <Button disabled={isLoading || !downloadedLabels} onClick={onQueueConfirmed}>
                {isLoading ? <CircularProgress /> : errorDescription ? "Retry" : "Accept"}
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  );
}

function QueuePrintingDialog({
  cardRequestIds,
  open,
  onClose,
  onQueued,
}: {
  cardRequestIds: string[];
  open: boolean;
  onClose: () => void;
  onQueued: () => void;
}) {
  const cardApi = useCardAPI();
  const [isLoading, setLoading] = useState(false);
  const [errorDescription, setErrorDescription] = useState<null | string>(null);

  const onQueueConfirmed = async () => {
    try {
      setLoading(true);
      setErrorDescription(null);
      await cardApi.v1beta1CardRequestsUpdateUpdate({
        cardRequestBulkUpdateRequest: {
          updates: cardRequestIds.map((cardRequestId) => {
            return {
              id: cardRequestId,
              action: CardRequestBulkUpdateElementRequestActionEnum.Add,
            };
          }),
        },
      });

      onQueued();
    } catch (e) {
      setErrorDescription((e as any).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Queue card requests for printing</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" gutterBottom>
            The selected card requests will be sent to the printer.
          </Typography>

          {errorDescription && (
            <Alert severity="error">
              <AlertTitle>Queue for printing failed!</AlertTitle> {errorDescription}{" "}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus disabled={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isLoading} onClick={onQueueConfirmed}>
            {isLoading ? <CircularProgress /> : errorDescription ? "Retry" : "Accept"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function CardRequestFilter({
  filterLabel,
  filterOptions,
  optionToDisplayValue,
  filterValue,
  onFilterChange,
}: {
  filterLabel: string;
  filterOptions: string[];
  filterValue?: string;
  optionToDisplayValue: (option: string | undefined) => string;
  onFilterChange: (filterValue: string | undefined) => void;
}) {
  const [inputValue, setInputValue] = useState("");
  return (
    <Grid item xs={6}>
      <Autocomplete
        options={["__all__"].concat(filterOptions)}
        getOptionLabel={(option) =>
          option === "__all__" ? optionToDisplayValue(undefined) : optionToDisplayValue(option)
        }
        getOptionSelected={(option, text) => (!text && option === "__all__") || option === text}
        style={{ width: "100%" }}
        // we can't set 'undefined' as the value here, so if we're allowing all we use our
        // __all__ string value to allow the menu __all__ menu item to show as selected
        value={filterValue === undefined ? "__all__" : filterValue}
        onChange={(_, newValue) =>
          // ensure that we pass back undefined if our __all__ menu item has been selected
          onFilterChange(!newValue || newValue === "__all__" ? undefined : newValue ?? "")
        }
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" size="small" label={filterLabel} />
        )}
      />
    </Grid>
  );
}

function CardRequestsTable() {
  const { permissions: cardPermissions } = useCardPermissions();
  const isCardRequestUpdater = !!cardPermissions?.includes("CARD_REQUEST_UPDATER");
  const { user } = useAPIGateway();
  const { crsid } = user ?? { displayName: "Unknown user", crsid: "unknown" };

  const [selected, setSelected] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [filters, setFilters] = useState<Partial<FilterData>>({
    workflowState: [CardRequestWorkflowFilterOptions.Pending],
    displayWorkflowState: CardRequestWorkflowFilterOptions.Pending,
  });
  const [printModalOpen, setPrintModalOpen] = useState<boolean>(false);
  const [verifyModalOpen, setVerifyModalOpen] = useState<boolean>(false);

  const [cardRequestCursorCurrent, setCardRequestCursorCurrent] = useState<string>("");

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);

  const {
    isLoading: isCardsLoading,
    data: { cardRequests, nextCursor, previousCursor, cardRequestsCount } = {},
    refetch,
    isRefetching,
  } = useCardRequests(
    { ...filters, cursor: cardRequestCursorCurrent, pageSize: rowsPerPage },
    { staleTime: 0, cacheTime: 0, refetchInterval: 60000 }
  );

  const { data: cardRequestsDestinations } = useCardRequestsDestinations();
  const { data: cardRequestsCardholderStatus } = useCardRequestsCardholderStatus();
  const { data: cardRequestsRequestors } = useCardRequestsRequestors();
  const { isLoading: isInstitutionsLoading, data: institutionInfo } = useInstitutionsForDelivery();

  const isLoading = isCardsLoading || isInstitutionsLoading;

  const handleChangePage = (event: React.MouseEvent | null, newPage: number) => {
    setPage(newPage);
    setSelected([]);
    setExpanded([]);

    if (newPage < page) {
      setCardRequestCursorCurrent(previousCursor ? previousCursor : "");
    }
    if (newPage > page) {
      setCardRequestCursorCurrent(nextCursor ? nextCursor : "");
    }
    refetch();
  };

  const resetPageState = () => {
    setSelected([]);
    setExpanded([]);
    setPage(0);
    setCardRequestCursorCurrent("");
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetPageState();
    setRowsPerPage(+event.target.value);
    refetch();
  };

  // Handle selection of individual and multiple rows
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedIds = (cardRequests || [])
        .filter(
          (cardRequest) =>
            // only select card requests in a pending state
            cardRequest.workflowState === CardRequestSummaryWorkflowStateEnum.Pending
        )
        .map((n) => n.id!);
      setSelected(newSelectedIds);
    } else {
      setSelected([]);
    }
  };
  const handleRowSelect = (cardRequestId: string) => {
    const selectedIndex = selected.indexOf(cardRequestId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, cardRequestId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // Handle expansion of individual and multiple rows
  const isExpanded = (name: string) => expanded.indexOf(name) !== -1;
  const handleExpandAllClick = (allExpanded: boolean) => {
    if (!allExpanded) {
      const newExpandedIds = (cardRequests || []).map((n) => n.id!);
      setExpanded(newExpandedIds);
    } else {
      setExpanded([]);
    }
  };
  const handleRowExpand = (cardRequestId: string) => {
    const expandedIndex = expanded.indexOf(cardRequestId);
    let newExpanded: string[] = [];

    if (expandedIndex === -1) {
      newExpanded = newExpanded.concat(expanded, cardRequestId);
    } else if (expandedIndex === 0) {
      newExpanded = newExpanded.concat(expanded.slice(1));
    } else if (expandedIndex === expanded.length - 1) {
      newExpanded = newExpanded.concat(expanded.slice(0, -1));
    } else if (expandedIndex > 0) {
      newExpanded = newExpanded.concat(
        expanded.slice(0, expandedIndex),
        expanded.slice(expandedIndex + 1)
      );
    }
    setExpanded(newExpanded);
  };

  const cellFormatters: CellFormatter[] = [
    {
      id: "workflowState",
      label: "Workflow State",
      width: 20,
      renderCell: (cardRequest: CardRequestSummary) => (
        <StatusDisplay
          status={cardRequest.workflowState || "Unknown"}
          useMapping={workflowStatusDetails}
        />
      ),
    },
    {
      id: "crsid",
      label: "Cardholder CRSid",
      width: 10,
      renderCell: (cardRequest: CardRequestSummary) =>
        cardRequest.identifiers
          .find((identifier) => identifier.scheme === CRSID_SCHEME)
          ?.value?.toLocaleLowerCase() || "-",
    },
    {
      id: "cardholderStatus",
      label: "Cardholder Status",
      width: 15,
      renderCell: (cardRequest: CardRequestSummary) => cardRequest.cardholderStatus || "-",
    },
    {
      id: "createdAt",
      label: "Requested at",
      width: 15,
      renderCell: (cardRequest: CardRequestSummary) =>
        cardRequest.createdAt?.toLocaleDateString() || "-",
    },
    {
      id: "requestor",
      label: "Requested by",
      width: 15,
      renderCell: (cardRequest: CardRequestSummary) => requestorDescription(cardRequest.requestor),
    },
    {
      id: "destination",
      label: "Deliver to",
      width: 30,
      renderCell: (cardRequest: CardRequestSummary) =>
        cardRequest.destination
          ? `${cardRequest.destination} - ${
              institutionInfo?.get(cardRequest.destination)?.name
            }` || cardRequest.destination
          : "-",
    },
  ];

  function downloadSelectedLabels() {
    const selectedCardRequests = (cardRequests || []).filter((cardRequest) =>
      selected.includes(cardRequest.id!)
    );

    const selectedDestinationCodes = [
      ...new Set((selectedCardRequests || []).map((n) => n.destination!)),
    ].sort();

    const cardInfo = (selectedCardRequests || []).map((cardRequest, index) => {
      return {
        code: cardRequest.destination || "-",
        crsid:
          cardRequest.identifiers
            .find((identifier) => identifier.scheme === CRSID_SCHEME)
            ?.value.toLocaleLowerCase() || "-",
        issueNumber: cardRequest.issueNumber?.toString() || "-",
      };
    });

    const addressInfo = selectedDestinationCodes.map((destinationCode) => {
      const inst = institutionInfo?.get(destinationCode);
      return {
        code: destinationCode,
        destination: inst?.name || destinationCode,
        address: inst?.address,
      };
    });

    return generateLabels(cardInfo, addressInfo);
  }

  const classes = useStyles();
  const [isActiveOrdering, setIsActiveOrdering] = useState(false);

  const handleIconOrderingClick = () => {
    resetPageState();
    setIsActiveOrdering(!isActiveOrdering);
    setFilters({
      workflowState: CardRequestWorkflowFilterOptions.Pending,
      displayWorkflowState: CardRequestWorkflowFilterOptions.Pending,
      ordering: isActiveOrdering ? "" : "-created_at",
    });
  };

  const checkAvailableForPrinting = () => {
    const selectedCardRequests = (cardRequests || []).filter((cardRequest) =>
      selected.includes(cardRequest.id!)
    );
    if (selectedCardRequests.length === 0) return false;
    return selectedCardRequests.every(
      (cardRequest) => cardRequest.workflowState === CardRequestSummaryWorkflowStateEnum.Pending
    );
  };

  const checkAvailableForShipping = () => {
    const selectedCardRequests = (cardRequests || []).filter((cardRequest) =>
      selected.includes(cardRequest.id!)
    );
    if (selectedCardRequests.length === 0) return false;
    return selectedCardRequests.every(
      (cardRequest) =>
        cardRequest.workflowState === CardRequestSummaryWorkflowStateEnum.CreatingInverification
    );
  };

  return (
    <>
      <QueuePrintingDialog
        cardRequestIds={selected}
        open={printModalOpen}
        onClose={() => setPrintModalOpen(false)}
        onQueued={() => {
          setSelected([]);
          setExpanded([]);
          setPage(0);
          setCardRequestCursorCurrent("");
          refetch();
          setPrintModalOpen(false);
        }}
      />

      <QueueVerifyDialog
        cardRequestIds={selected}
        open={verifyModalOpen}
        onGenerateLabels={downloadSelectedLabels}
        onClose={() => setVerifyModalOpen(false)}
        onQueued={() => {
          setSelected([]);
          setExpanded([]);
          setPage(0);
          setCardRequestCursorCurrent("");
          refetch();
          setVerifyModalOpen(false);
        }}
      />

      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography display="inline" variant="h6">
            Manage print queue
          </Typography>
        </Grid>

        <Grid item>
          <Tooltip title="Reset filters">
            <IconButton
              onClick={() => {
                setFilters({
                  workflowState: CardRequestWorkflowFilterOptions.Pending,
                  displayWorkflowState: CardRequestWorkflowFilterOptions.Pending,
                });
                setSelected([]);
                setExpanded([]);
                setPage(0);
                setCardRequestCursorCurrent("");
                refetch();
              }}
            >
              <ClearFiltersIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Refresh data">
            <IconButton
              onClick={() => {
                setSelected([]);
                setExpanded([]);
                setPage(0);
                setCardRequestCursorCurrent("");
                refetch();
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Order by 'requested at' field">
            <IconButton
              style={{ marginRight: "1rem" }}
              className={isActiveOrdering ? classes.activeIcon : ""}
              onClick={() => {
                handleIconOrderingClick();
                refetch();
              }}
            >
              <SortIcon />
            </IconButton>
          </Tooltip>
          {isCardRequestUpdater && (
            <Tooltip title="Select card requests to queue for printing">
              <span>
                <Button
                  startIcon={<PrintIcon />}
                  style={{ marginRight: "1rem" }}
                  color="secondary"
                  variant="contained"
                  disabled={!checkAvailableForPrinting()}
                  onClick={() => setPrintModalOpen(true)}
                >
                  Queue for printing
                </Button>
              </span>
            </Tooltip>
          )}

          {isCardRequestUpdater && (
            <Tooltip title="Select card requests to ship">
              <span>
                <Button
                  startIcon={<LocalShippingIcon />}
                  color="secondary"
                  variant="contained"
                  disabled={!checkAvailableForShipping()}
                  onClick={() => setVerifyModalOpen(true)}
                >
                  Verify & Ship
                </Button>
              </span>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        spacing={3}
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
      >
        <CardRequestFilter
          filterLabel="Workflow State"
          filterOptions={orderedFiltersKeys}
          optionToDisplayValue={keyToDisplayFilterName}
          filterValue={
            filters.displayWorkflowState || workflowStatusDetails["PENDING"].displayName
          }
          onFilterChange={(newFilterValue) => {
            setSelected([]);
            setExpanded([]);
            setPage(0);
            setCardRequestCursorCurrent("");
            const expandedFilterValue = !newFilterValue
              ? // for the "all states" case (where `newFilterValue` is undefined), exclude the
                // `Cancelled` and `CreatedDone` states
                [
                  CardRequestWorkflowFilterOptions.Pending,
                  CardRequestWorkflowFilterOptions.Hold,
                  CardRequestWorkflowFilterOptions.CreatingTodo,
                  CardRequestWorkflowFilterOptions.CreatingInprogress,
                  CardRequestWorkflowFilterOptions.CreatingInverification,
                  CardRequestWorkflowFilterOptions.PendingCrsidRequired,
                  CardRequestWorkflowFilterOptions.PendingPhotoRequired,
                  CardRequestWorkflowFilterOptions.PendingDestinationRequired,
                  CardRequestWorkflowFilterOptions.PendingExpiryDataRequired,
                ]
              : newFilterValue === WorkflowFilterExtraOptions.PendingAll
              ? [
                  CardRequestWorkflowFilterOptions.PendingCrsidRequired,
                  CardRequestWorkflowFilterOptions.PendingPhotoRequired,
                  CardRequestWorkflowFilterOptions.PendingDestinationRequired,
                  CardRequestWorkflowFilterOptions.PendingExpiryDataRequired,
                ]
              : [newFilterValue];
            setFilters((existingFilters) => ({
              ...existingFilters,
              workflowState: expandedFilterValue,
              displayWorkflowState: newFilterValue,
            }));
          }}
        />

        <CardRequestFilter
          filterLabel="Cardholder Status"
          filterOptions={cardRequestsCardholderStatus || []}
          optionToDisplayValue={(option) => (!option ? "All" : option)}
          filterValue={filters.cardholderStatus}
          onFilterChange={(newFilterValue) => {
            setSelected([]);
            setExpanded([]);
            setPage(0);
            setCardRequestCursorCurrent("");
            setFilters((existingFilters) => ({
              ...existingFilters,
              cardholderStatus: newFilterValue,
            }));
          }}
        />

        <CardRequestFilter
          filterLabel="Requested by"
          filterOptions={
            cardRequestsRequestors
              ? [...cardRequestsRequestors, crsid + "@" + CRSID_SCHEME]
              : [crsid + "@" + CRSID_SCHEME]
          }
          optionToDisplayValue={(option) =>
            !option
              ? "All"
              : option === crsid + "@" + CRSID_SCHEME
              ? requestorDescription(option)
              : option === CRSID_SCHEME
              ? "Manual (All)"
              : requestorDescription(option)
          }
          filterValue={filters.requestor}
          onFilterChange={(newFilterValue) => {
            setSelected([]);
            setExpanded([]);
            setPage(0);
            setFilters((existingFilters) => ({
              ...existingFilters,
              requestor: newFilterValue,
            }));
          }}
        />

        <CardRequestFilter
          filterLabel="Deliver to"
          filterOptions={cardRequestsDestinations || []}
          optionToDisplayValue={(option) =>
            !option ? "All" : `${option} - ${institutionInfo?.get(option)?.name}` || option
          }
          filterValue={filters.destination}
          onFilterChange={(newFilterValue) => {
            setSelected([]);
            setExpanded([]);
            setPage(0);
            setCardRequestCursorCurrent("");
            setFilters((existingFilters) => ({
              ...existingFilters,
              destination: newFilterValue,
            }));
          }}
        />
      </Grid>

      <TableContainer>
        <Table aria-labelledby="Print Queue Table" size="medium">
          <CardRequestTableHead
            numSelected={selected.length}
            numExpanded={expanded.length}
            rowCount={(cardRequests || []).length}
            onSelectAllClick={handleSelectAllClick}
            onExpandAllClick={handleExpandAllClick}
            allowSelection={isCardRequestUpdater}
            cellFormatters={cellFormatters}
          />

          <CardRequestTableBody
            isLoading={isLoading || isRefetching}
            cardRequests={
              isActiveOrdering ? cardRequests || [] : sortCardRequests(cardRequests || [])
            }
            isSelected={isSelected}
            isExpanded={isExpanded}
            handleSelect={handleRowSelect}
            handleExpand={handleRowExpand}
            cellFormatters={cellFormatters}
            allowSelection={isCardRequestUpdater}
            onUpdate={() => {
              setSelected([]);
              setExpanded([]);
              setPage(0);
              setCardRequestCursorCurrent("");
              refetch();
            }}
          />
        </Table>
      </TableContainer>

      {!isLoading && (
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={cardRequestsCount ? cardRequestsCount : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}

export const PrintQueuePage = () => {
  return (
    <Box m={3} p={2} component={Paper}>
      <CardRequestsTable />
    </Box>
  );
};
