import { ComponentProps } from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "@material-ui/lab/Skeleton";

import { Person } from "../api/lookup-api";
import { AttributeList } from "../components/AttributeList";

interface Props extends ComponentProps<"div"> {
  person?: Person;
  isLoading?: boolean;
}

const IDENTIFIER_NAMES = new Map([["crsid", "CRSid"]]);

/**
 * A grid of attributes representing a person's profile. The profile is fetched dynamically
 * from the Lookup API.
 */
export const LookupPersonAttributes = ({ person, isLoading, ...rootProps }: Props) => {
  // Extract basic information from Person resource.
  const { displayName, identifiers } = {
    identifiers: [],
    ...person,
  };

  // Simple function to render an undefined attribute as an "unknown" indicator or as a placeholder
  // if the profile is loading.
  const renderAttribute = (s?: string) => (isLoading ? <Skeleton /> : s ? s : "\u2013");

  return (
    <div {...rootProps}>
      <AttributeList disablePadding dense>
        <ListItem>
          <ListItemText secondary={renderAttribute(displayName)} primary="Display name" />
        </ListItem>
        {!isLoading && identifiers.length === 0 ? (
          <ListItem>
            <ListItemText primary="No identifiers" />
          </ListItem>
        ) : null}
        {isLoading &&
          [0, 1].map((idx) => (
            <ListItem key={idx}>
              <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
            </ListItem>
          ))}
        {identifiers
          .filter(({ scheme }) => IDENTIFIER_NAMES.has(scheme ?? ""))
          .map(({ scheme, value }) => (
            <ListItem key={scheme}>
              <ListItemText
                secondary={value}
                primary={scheme && (IDENTIFIER_NAMES.get(scheme) || scheme)}
              />
            </ListItem>
          ))}
      </AttributeList>
    </div>
  );
};

export default LookupPersonAttributes;
