import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  LinearProgress,
} from "@material-ui/core";
import {
  useAllCardRequests,
  CardRequestWorkflowFilterOptions,
  CRSID_SCHEME,
  CardRequestSummaryWorkflowStateEnum,
  workflowStatusDetails,
} from "../api/card-api";
import { useAPIGateway } from "../api-gateway/APIGatewayProvider";

export const RecentCardRequestsTable = () => {
  interface Row {
    crsid: string;
    status: string;
    display_status: string;
    requested_on: string;
    delivering_to: string;
  }

  const { user } = useAPIGateway();
  const { crsid } = user ?? { displayName: "Unknown user", crsid: "unknown" };

  const formatDate = (date: string): string => new Date(date).toLocaleString();
  const history = useHistory();

  const [rows, setRows] = useState<Row[]>([]);

  const [fromDate, setFromDate] = useState<Date>(new Date());

  const filters = {
    requestor: crsid + "@" + CRSID_SCHEME,
    workflowState: Object.values(
      Object.fromEntries(
        Object.entries(CardRequestWorkflowFilterOptions).filter(
          ([key, value]) => value !== CardRequestWorkflowFilterOptions.Cancelled
        )
      )
    ),
    createdAtGte: fromDate.toISOString(),
  };

  const { isLoading: isLoadingCardRequests, data: { cardRequests } = {} } = useAllCardRequests(
    {
      ...filters,
    },
    { staleTime: 0, cacheTime: 0, refetchInterval: 600000 }
  );
  useEffect(() => {
    const createData = (
      crsid: string,
      status: string,
      requested_on: string,
      delivering_to: string
    ): Row => {
      return {
        crsid,
        status,
        display_status:
          workflowStatusDetails[status as keyof typeof workflowStatusDetails]?.displayName ||
          status,
        requested_on,
        delivering_to,
      };
    };
    const updated_rows =
      cardRequests?.map((cardRequest) => {
        return createData(
          cardRequest.identifiers
            ?.find((identifier) => identifier.scheme === CRSID_SCHEME)
            ?.value.toLocaleLowerCase() || "",
          cardRequest.workflowState,
          cardRequest.createdAt ? cardRequest.createdAt.toISOString() : "",
          cardRequest.destination || ""
        );
      }) || [];
    setRows(
      updated_rows.sort((a, b) => b.requested_on.localeCompare(a.requested_on)).slice(0, 10)
    );
  }, [cardRequests, isLoadingCardRequests]);

  useEffect(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    setFromDate(date);
  }, []);

  const cardRequestWarningWorkflowStates = [
    CardRequestSummaryWorkflowStateEnum.PendingCrsidRequired,
    CardRequestSummaryWorkflowStateEnum.PendingDestinationRequired,
    CardRequestSummaryWorkflowStateEnum.PendingPhotoRequired,
    CardRequestSummaryWorkflowStateEnum.Hold,
  ];

  const getChipStyle = (status: string): React.CSSProperties => {
    return cardRequestWarningWorkflowStates.includes(status as CardRequestSummaryWorkflowStateEnum)
      ? { backgroundColor: "#d3455a", color: "white" }
      : { backgroundColor: "#1aae9f", color: "white" };
  };

  const renderRows = () => {
    return rows.map((row) => (
      <TableRow
        key={`${row.crsid}${row.requested_on}`}
        onClick={() => history.push(`/cardholders/${row.crsid}@${CRSID_SCHEME}`)}
        style={{ cursor: "pointer" }}
        hover
      >
        <TableCell>{row.crsid}</TableCell>
        <TableCell>
          <Chip label={`${row.display_status}`} style={getChipStyle(row.status)} />
        </TableCell>
        <TableCell>{formatDate(row.requested_on)}</TableCell>
        <TableCell>{row.delivering_to}</TableCell>
      </TableRow>
    ));
  };

  return (
    <Box alignItems="left" justifyContent="left" component={Paper}>
      <Typography variant="h6" align="left" style={{ padding: "16px" }}>
        My recent requests
      </Typography>
      {isLoadingCardRequests ? (
        <LinearProgress />
      ) : rows.length === 0 ? (
        <Typography variant="body1" align="left" style={{ padding: "16px" }}>
          You have no active requests.
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>CRSid</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Requested on</TableCell>
                <TableCell>Delivering to</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderRows()}</TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
