/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  InlineResponse2005Result,
  InlineResponse2005ResultFromJSON,
  InlineResponse2005ResultFromJSONTyped,
  InlineResponse2005ResultToJSON,
} from "./";

/**
 *
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
  /**
   *
   * @type {InlineResponse2005Result}
   * @memberof InlineResponse2005
   */
  result?: InlineResponse2005Result;
}

export function InlineResponse2005FromJSON(json: any): InlineResponse2005 {
  return InlineResponse2005FromJSONTyped(json, false);
}

export function InlineResponse2005FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineResponse2005 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    result: !exists(json, "result")
      ? undefined
      : InlineResponse2005ResultFromJSON(json["result"]),
  };
}

export function InlineResponse2005ToJSON(
  value?: InlineResponse2005 | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    result: InlineResponse2005ResultToJSON(value.result),
  };
}
