import { useState, FC } from "react";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

export const EnvironmentWarning: FC<{ environment: string }> = ({ environment }) => {
  const [isOpen, setOpen] = useState(true);

  return (
    <Snackbar open={isOpen} autoHideDuration={3000} onClose={() => setOpen(false)}>
      <Alert severity="warning">This is a {environment} environment</Alert>
    </Snackbar>
  );
};
