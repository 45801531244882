import {
  CardIdentifier,
  CardIdentifierSummary,
  CardSummary,
  PersonIdentifier,
  CRSID_SCHEME,
  LEGACY_SCHEME,
} from "../api/card-api";
import { Person as LookupPerson } from "../api/lookup-api";

/**
 * The person object which holds both the Lookup entry and the cards held by this person,
 * they are identified by one or more PersonIdentifiers.
 */
export interface Person {
  /** Identifiers for this person as would be recorded in the Card API. */
  identifiers: [PersonIdentifier, ...PersonIdentifier[]];

  /** If this person has a related Lookup entry, this property holds it. */
  lookupPerson?: LookupPerson;

  /** A list cards for this person. */
  cards: ReadonlyArray<CardSummary>;
}

/**
 * Joins an object representing new people from Lookup with an existing array
 * of people already in search state. If appendMissing is true any of the people which
 * did not already exist in the existingPeople array are added to the end.
 */
export const appendLookupPeopleToPeople = (
  existingPeople: ReadonlyArray<Person>,
  lookupPeople: ReadonlyArray<LookupPerson>,
  appendMissing: boolean = true
): ReadonlyArray<Person> => {
  const updatedPeople = [...existingPeople];

  for (const lookupPerson of lookupPeople) {
    const crsid =
      lookupPerson.identifier?.scheme === "crsid" ? lookupPerson.identifier?.value : undefined;

    // we can't do anything with a Lookup entry that doesn't have a CRSid or is cancelled
    if (!crsid || lookupPerson.cancelled) {
      continue;
    }

    // find a person entry to update
    const matchingPerson = updatedPeople.find((person) =>
      person.identifiers.some(
        ({ scheme, value }) =>
          scheme === CRSID_SCHEME && value.toLowerCase() === crsid.toLowerCase()
      )
    );

    // either update the existing person entry with our Lookup entry or create a new
    // person entry from the Lookup data we already have
    if (matchingPerson) {
      matchingPerson.lookupPerson = lookupPerson;
    } else if (appendMissing) {
      updatedPeople.push({
        lookupPerson,
        identifiers: [{ scheme: CRSID_SCHEME, value: crsid }],
        cards: [],
      });
    }
  }

  return updatedPeople;
};

/**
 * Appends a list of card summary objects to an existing array of people objects, joining the
 * cards with a relevant person object or, if appendMissing is true, creating a new person
 * object from the data contained on a card.
 */
export const appendCardsToPeople = (
  existingPeople: ReadonlyArray<Person>,
  cards: CardSummary[],
  appendMissing: boolean = true
): ReadonlyArray<Person> => {
  const updatedPeople = [...existingPeople];

  for (const newCard of cards) {
    let hasBeenAttachedToPerson = false;

    for (const person of updatedPeople) {
      // if this card already exists on a person object bail early
      if (person.cards && person.cards.some(({ id }) => id === newCard.id)) {
        hasBeenAttachedToPerson = true;
        break;
      }

      // find a person object with matching identifiers
      const matchesIdentifiers = newCard.identifiers.some(({ scheme, value }) =>
        person.identifiers.some(
          (personIdentifier) =>
            scheme === personIdentifier.scheme &&
            value.toLowerCase() === personIdentifier.value.toLowerCase()
        )
      );

      if (matchesIdentifiers) {
        // update the person with data from the card
        person.identifiers = joinIdentifiers(person.identifiers, newCard.identifiers);
        person.cards = [...(person.cards ?? []), newCard];
        hasBeenAttachedToPerson = true;
        break;
      }
    }

    // if we're allowed to append missing cards, add it as a new person object
    if (!hasBeenAttachedToPerson && appendMissing) {
      updatedPeople.push({
        identifiers: joinIdentifiers([], newCard.identifiers),
        cards: [newCard],
      });
    }
  }

  return updatedPeople;
};

/**
 * Joins a list of card identifiers to a list of person identifiers, returning just the
 * person identifiers contained within both. This retains our preference order of CRSid
 * and then legacy cardholder id.
 */
const joinIdentifiers = (
  existingIdentifiers: ReadonlyArray<PersonIdentifier>,
  newIdentifiers: ReadonlyArray<CardIdentifier | CardIdentifierSummary>
) => {
  const updatedIdentifiers = [...existingIdentifiers];
  for (const targetScheme of [CRSID_SCHEME, LEGACY_SCHEME]) {
    if (updatedIdentifiers.some(({ scheme }) => scheme === targetScheme)) {
      continue;
    }
    const matchingIdentifier = newIdentifiers.find(({ scheme }) => scheme === targetScheme);
    if (matchingIdentifier) {
      updatedIdentifiers.push(matchingIdentifier as PersonIdentifier);
    }
  }

  if (updatedIdentifiers.length === 0) {
    throw new TypeError("joinIdentifiers must produce a non empty list of identifiers");
  }
  return updatedIdentifiers as [PersonIdentifier, ...PersonIdentifier[]];
};
