import { OAuth2AuthCodePKCE } from "@bity/oauth2-auth-code-pkce";
import jwtDecode from "jwt-decode";
import log from "loglevel";

export enum AuthState {
  Uninitialized = "Uninitialized",
  Unauthenticated = "Unauthenticated",
  Redirecting = "Redirecting",
  Authenticated = "Authenticated",
  Refreshing = "Refreshing",
  Error = "Error",
}

interface ApiGatewayUser {
  displayName: string;
  crsid: string;
}

export class ApiGatewayService {
  private static readonly INITIAL_URL_STORAGE_KEY = "__initial_url";

  /**
   * A service which wraps an implementation of fetch, automatically ensuring that a valid
   * token is used for requests to the API gateway.
   *
   * Additionally handles initial authentication, allowing a listener to update UI based on the
   * authentication state.
   */

  public readonly fetch: typeof fetch;

  private pkceClient: OAuth2AuthCodePKCE;
  private _user: ApiGatewayUser | null = null;

  constructor(
    clientId: string,
    authEndpoint: string,
    tokenEndpoint: string,
    scopes: string[],
    private authStateListener?: (authState: AuthState) => void
  ) {
    this.pkceClient = new OAuth2AuthCodePKCE({
      clientId,
      scopes,
      authorizationUrl: authEndpoint,
      tokenUrl: tokenEndpoint,
      explicitlyExposedTokens: ["id_token"],
      redirectUrl: window.location.origin,
      onAccessTokenExpiry: this.onAccessTokenExpiry.bind(this),
      onInvalidGrant: this.onInvalidGrant.bind(this),
    });

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.initialize = this.initialize.bind(this);

    this.fetch = this.pkceClient.decorateFetchHTTPClient(fetch);
    this.initialize();
  }

  public get user() {
    return this._user;
  }

  public async login() {
    this.authStateListener?.(AuthState.Redirecting);

    // we expect the `fetchAuthorizationCode` call to redirect as part of the auth code
    // OAuth2 flow, so we save the url to session storage so that we can redirect back
    // to it once the login flow is complete.
    sessionStorage.setItem(ApiGatewayService.INITIAL_URL_STORAGE_KEY, window.location.href);
    await this.pkceClient.fetchAuthorizationCode();
  }

  public logout() {
    this.pkceClient.reset();
    this.authStateListener?.(AuthState.Unauthenticated);
  }

  private async initialize() {
    try {
      const hasBeenRedirected = await this.pkceClient.isReturningFromAuthServer();
      if (hasBeenRedirected) {
        // replace the url either with the initial url that we've set to sessionStorage
        // (allowing us to persist the path and query string in the url) or simply
        // remove the code which has been provided in the redirect from the OAuth
        // flow.
        window.location.replace(
          sessionStorage.getItem(ApiGatewayService.INITIAL_URL_STORAGE_KEY) ??
            window.location.pathname
        );
        return;
      }

      const { explicitlyExposedTokens } = await this.pkceClient.getAccessToken();
      this.setUserFromIdToken(explicitlyExposedTokens?.id_token ?? null);

      this.authStateListener?.(
        this.pkceClient.isAuthorized() ? AuthState.Authenticated : AuthState.Unauthenticated
      );
    } catch (error) {
      log.error("Failed to initialize auth context");
      log.error(error);

      this.authStateListener?.(AuthState.Error);
    }
  }

  private setUserFromIdToken(idToken: string | null) {
    if (!idToken) {
      return;
    }
    try {
      const { sub, name } = jwtDecode<{ sub?: string; name?: string }>(idToken);
      const crsid = (sub ?? "").split("@")[0];
      if (crsid && name) {
        this._user = { displayName: name, crsid };
      }
    } catch (error) {
      log.error("Unable to decode identity token");
      log.error(error);
    }
  }

  private async onAccessTokenExpiry<T>(refreshAccessToken: () => Promise<T>): Promise<T> {
    log.warn("Access token expires, refreshing access token");
    this.authStateListener?.(AuthState.Refreshing);

    try {
      const token = await refreshAccessToken();
      this.authStateListener?.(AuthState.Authenticated);

      return token;
    } catch (error) {
      this.authStateListener?.(AuthState.Error);
      log.error("Unable to refresh access token");

      throw error;
    }
  }

  private async onInvalidGrant(refreshAuthCodeOrRefreshToken: () => Promise<void>) {
    log.warn("Invalid grant, refreshing auth code or refresh token");
    this.authStateListener?.(AuthState.Refreshing);

    try {
      const token = await refreshAuthCodeOrRefreshToken();
      this.authStateListener?.(AuthState.Authenticated);

      return token;
    } catch (error) {
      this.authStateListener?.(AuthState.Error);
      throw error;
    }
  }
}
