import { createTheme, useMediaQuery } from "@material-ui/core";
import { indigo, pink } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

/**
 * A hook returning the current Material UI theme.
 * @returns The current theme based on the users' colour scheme preference
 */
export const useTheme = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = createTheme({
    palette: {
      type: prefersDarkMode ? "dark" : "light",

      // In dark mode, pump the brightness of the primary and secondary colours a bit. In light
      // mode, leave them at their default values.

      primary: {
        main: prefersDarkMode ? indigo[300] : indigo[500],
      },

      secondary: {
        main: prefersDarkMode ? pink["A100"] : pink["A400"],
      },
    },
  });
  return theme;
};

// Apply global styles to the page
const useGlobalStyles = makeStyles({
  "@global": {
    body: {
      height: "100%",
    },
    html: {
      height: "100%",
    },
    "#root": {
      height: "100%",
    },
  },
});

interface AppThemeProviderProps {
  children?: any;
}

/**
 * Component that implements an application-specific theme provider. This permits styling to be
 * applied to root elements.
 * @param props Properties of the component
 * @returns Application-specific theme provider
 */
export const AppThemeProvider = (props: AppThemeProviderProps) => {
  useGlobalStyles();
  return <ThemeProvider theme={useTheme()}>{props.children}</ThemeProvider>;
};
