export const feedbackIssueEmail = "universitycard@admin.cam.ac.uk";
export const projectTeamEmail = "universitycard-dev@uis.cam.ac.uk";
export const guideBookLink =
  "https://guidebook.devops.uis.cam.ac.uk/en/latest/services/card-management-system/";
export const rolesAndResponsibilitiesLink =
  "https://help.uis.cam.ac.uk/information-card-reps#toc-4";
export const lqlHelpLink = "https://www.lookup.cam.ac.uk/lql";
export const faqLink =
  "https://docs.google.com/document/d/1-VJE1O6_GWc-aF2-UQ1gxwDTVsDFh_hyO0YKxX6LFZA/edit?usp=sharing";
export const cardWorkflowsWebpageLink = "https://help.uis.cam.ac.uk/unicard-workflows";
