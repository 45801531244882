/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  InlineResponse200Result,
  InlineResponse200ResultFromJSON,
  InlineResponse200ResultFromJSONTyped,
  InlineResponse200ResultToJSON,
} from "./";

/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {InlineResponse200Result}
   * @memberof InlineResponse200
   */
  result?: InlineResponse200Result;
}

export function InlineResponse200FromJSON(json: any): InlineResponse200 {
  return InlineResponse200FromJSONTyped(json, false);
}

export function InlineResponse200FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineResponse200 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    result: !exists(json, "result")
      ? undefined
      : InlineResponse200ResultFromJSON(json["result"]),
  };
}

export function InlineResponse200ToJSON(value?: InlineResponse200 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    result: InlineResponse200ResultToJSON(value.result),
  };
}
