import { useQuery, UseQueryOptions } from "react-query";
import { useAPIGateway } from "../api-gateway/APIGatewayProvider";
import { useAppConfig } from "../config/AppConfigProvider";
import {
  PersonApi as LookupPersonAPI,
  InstitutionApi as LookupInstitutionAPI,
  Configuration as LookupAPIConfig,
  Person,
  PersonGetPersonRequest,
} from "./lookup";

/**
 * The following are exported here (having possibly been renamed) to abstract their usage from
 * changes to client generation
 */

export {};

export type { Person };

/**
 * A hook which returns an instance of the Lookup person API using the authentication provided
 * by the API Gateway context and the base url provided by the config context.
 */
export const useLookupPersonAPI = () => {
  const { fetch: apiGatewayFetch } = useAPIGateway();
  const { lookupBaseUrl } = useAppConfig(true);
  return new LookupPersonAPI(
    new LookupAPIConfig({
      fetchApi: apiGatewayFetch,
      basePath: lookupBaseUrl,
      // by default the Lookup API gives us xml - ask for json instead
      headers: {
        Accept: "application/json",
      },
    })
  );
};

/**
 * A hook which returns an instance of the Lookup institution API using the authentication provided
 * by the API Gateway context and the base url provided by the config context.
 */
export const useLookupInstitutionAPI = () => {
  const { fetch: apiGatewayFetch } = useAPIGateway();
  const { lookupBaseUrl } = useAppConfig(true);
  return new LookupInstitutionAPI(
    new LookupAPIConfig({
      fetchApi: apiGatewayFetch,
      basePath: lookupBaseUrl,
      // by default the Lookup API gives us xml - ask for json instead
      headers: {
        Accept: "application/json",
      },
    })
  );
};

/**
 * Queries Lookup for people matching the given search term.
 * @returns An array of people matching the query provided.
 */
export const useLookupPeopleSearch = (
  query: string,
  limit: number,
  includeCancelled: boolean = false,
  fromInstitution: string = "",
  queryCacheKey?: string
) => {
  const lookupAPI = useLookupPersonAPI();
  const queryState = useQuery(["lookupPersonSearch", queryCacheKey ?? query], async () => {
    if (query === "" && fromInstitution === "") {
      return [];
    }
    const fetch = fromInstitution ? "all_insts" : "";
    const response = await lookupAPI.personSearch({
      query,
      limit,
      includeCancelled,
      fetch,
      attributes: "surname,displayName,registeredName",
    });
    return (
      (fromInstitution
        ? response.result?.people?.filter((person) => {
            return person.institutions
              ? person.institutions.map((inst) => inst.instid).includes(fromInstitution)
              : false;
          })
        : response.result?.people) ?? []
    );
  });
  return queryState;
};

/**
 * Queries Lookup for people with the given crsids.
 * @returns An array of people matching the crsids provided.
 */
export const useLookupPeopleWithCRSids = (crsids: string[], includeCancelled: boolean = false) => {
  const lookupAPI = useLookupPersonAPI();
  const queryState = useQuery(["lookupCRSidSearch", crsids], async () => {
    const nonNullCRSids = crsids.filter(Boolean);
    if (nonNullCRSids.length === 0) {
      return [];
    }
    const response = await lookupAPI.personSearch({
      query: `person: (${nonNullCRSids.join(",").toLowerCase()})`,
      limit: nonNullCRSids.length,
      fetch: "all_insts,title",
      includeCancelled,
    });
    return response.result?.people ?? [];
  });
  return queryState;
};

/**
 * Queries Lookup for people matching the given identifier(s).
 * @returns An array of people matching the identifier(s) provided.
 */
export const useLookupListPeople = (
  identifiers: string[],
  fromInstitution: string = "",
  queryCacheKey?: string
) => {
  const lookupAPI = useLookupPersonAPI();
  const queryState = useQuery(
    ["lookupListPeople"].concat(queryCacheKey ? [queryCacheKey] : identifiers),
    async () => {
      if (identifiers.length <= 0 && fromInstitution === "") {
        return [];
      }
      const fetch = fromInstitution ? "all_insts" : "";
      const response = await lookupAPI.personListPeople({
        crsids: identifiers.join(",").toLowerCase(),
        fetch,
      });
      return (
        (fromInstitution
          ? response.result?.people?.filter((person) => {
              return person.institutions
                ? person.institutions.map((inst) => inst.instid).includes(fromInstitution)
                : false;
            })
          : response.result?.people) ?? []
      );
    }
  );
  return queryState;
};

/**
 * Wraps Lookup's "personGetPerson" method via react-query's useQuery hook.
 *
 * Returns a single person or null if there is no such person.
 *
 * Get the person with the specified identifier.  By default, only a few basic details about the
 * person are returned, but the optional `fetch` parameter may be used to fetch additional
 * attributes or references of the person.  NOTE: The person returned may be a cancelled person. It
 * is the caller's repsonsibility to check its cancelled flag.
 */
export const usePersonGetPersonQuery = (
  request?: PersonGetPersonRequest,
  options: Omit<UseQueryOptions<Person | null>, "queryKey" | "queryFn"> = {}
) => {
  const lookupAPI = useLookupPersonAPI();
  return useQuery(
    ["personGetPerson", request],
    async () => {
      if (!request) {
        return null;
      }
      const { result } = await lookupAPI.personGetPerson(request);
      return result?.person ?? null;
    },
    options
  );
};

export { LookupPersonAPI };

export const useInstitutionsForDelivery = () => {
  const lookupAPI = useLookupInstitutionAPI();

  return useQuery(
    ["lookupInstitutionsAddress"],
    async () => {
      const { result } = await lookupAPI.institutionAllInsts({
        fetch: "address",
      });
      const codeToInstName = new Map<string, { name: string; address: string }>();
      for (const inst of result?.institutions || []) {
        // filter out inst that don't have an address set in lookup
        const instAddress = inst.attributes?.find(({ scheme }) => scheme === "address")?.value;
        // filter out yearly intake institutions
        if (inst.instid && inst.name && instAddress && !/[0-9]+$/.test(inst.instid)) {
          codeToInstName.set(inst.instid, {
            name: inst.name,
            address: instAddress,
          });
        }
      }
      return codeToInstName;
    },
    { staleTime: Infinity, cacheTime: Infinity }
  );
};

export const useInstitutionsList = () => {
  const lookupAPI = useLookupInstitutionAPI();

  return useQuery(
    ["lookupInstitutions"],
    async () => {
      const { result } = await lookupAPI.institutionAllInsts({});
      const codeToInstName = new Map<string, string>();
      for (const inst of result?.institutions || []) {
        if (inst.instid && inst.name && !/[0-9]+$/.test(inst.instid)) {
          codeToInstName.set(inst.instid, inst.name);
        }
      }
      return codeToInstName;
    },
    { staleTime: Infinity, cacheTime: Infinity }
  );
};
