// usePeopleSearch provides hooks for searching for people and their associated card records.
//
// There is quite some complexity involved in searching for cards from multiple data sources.
// This hook tries to hide all of it and just return a simple list of people results.
import { useState, useEffect } from "react";

import { useCardsWithIdentifiers, CardIdentifierSummarySchemeEnum } from "../api/card-api";
import {
  useLookupListPeople,
  useLookupPeopleSearch,
  useLookupPeopleWithCRSids,
} from "../api/lookup-api";

import { CRSID_SCHEME, USN_SCHEME } from "../api/card-api";

import { Person, appendLookupPeopleToPeople, appendCardsToPeople } from ".";

// Limit on the number of results to return from a Lookup person search
const PERSON_SEARCH_RESULT_LIMIT = 1000;

// Limit on the number of results to process from a Lookup person search after applying the
// institution filter. This limit is for practical display purposes as well as due to limits when
// looking up identifiers with Card API.
const PERSON_SEARCH_POST_FILTER_LIMIT = 50;

type PeopleSearchState = Readonly<{
  /** The people representing the current search results */
  people: ReadonlyArray<Person>;
  /** The CRSids we need to query the Card API for */
  crsidsMissingCardData: string[];
  /** The CRSids we need to query Lookup for */
  crsidsMissingLookupData: string[];
}>;

const BLANK_SEARCH_STATE: PeopleSearchState = {
  people: [],
  crsidsMissingCardData: [],
  crsidsMissingLookupData: [],
};

const IDENTIFIERS_FOR_CARD_SEARCH = [
  CardIdentifierSummarySchemeEnum.V1PersonIdentifiersCamAcUk,
  CardIdentifierSummarySchemeEnum.PersonV1StudentRecordsUniversityIdentifiersCamAcUk,
  CardIdentifierSummarySchemeEnum.PersonV1HumanResourcesUniversityIdentifiersCamAcUk,
  CardIdentifierSummarySchemeEnum.PersonV1LegacyCardUniversityIdentifiersCamAcUk,
  CardIdentifierSummarySchemeEnum.MifareIdentifierV1CardUniversityIdentifiersCamAcUk,
  CardIdentifierSummarySchemeEnum.MifareNumberV1CardUniversityIdentifiersCamAcUk,
  CardIdentifierSummarySchemeEnum.CardV1LegacyCardUniversityIdentifiersCamAcUk,
  CardIdentifierSummarySchemeEnum.TemporaryCardV1CardUniversityIdentifiersCamAcUk,
  CardIdentifierSummarySchemeEnum.BarcodeV1CardUniversityIdentifiersCamAcUk,
  CardIdentifierSummarySchemeEnum.InstitutionV1LegacyCardUniversityIdentifiersCamAcUk,
];

export type QueryType = "ALL" | "NAME" | CardIdentifierSummarySchemeEnum;

export const usePeopleSearch = (query: string, queryType: QueryType, queryInstitution: string) => {
  /**
   * This hook manages searching both the Lookup and Card API for the given query and then
   * filling-in either the Lookup details for card results or the card details for results
   * from Lookup.
   */

  const [state, setState] = useState<PeopleSearchState>(BLANK_SEARCH_STATE);

  // when the query changes reset the state to nothing, which clears any people from our results
  useEffect(() => setState(BLANK_SEARCH_STATE), [query, queryType, queryInstitution]);

  // Determine properties of the user-specified query
  const queryTrimmed = query.trim();
  const queryLooksLikeCRSid = /^[a-zA-Z]+[0-9]+$/.test(queryTrimmed);
  const queryLooksLikeUSN = /^[0-9]{9}$/.test(queryTrimmed);
  const queryNotIdentifier =
    queryType === "NAME" || queryTrimmed.length <= 0 || /\s/.test(queryTrimmed);

  const institutionFilter = queryInstitution === "ALL" ? "" : queryInstitution;

  // construct query for searching Lookup with a non-identifier
  const lookupSearch =
    queryTrimmed &&
    (queryType === "NAME" || queryType === "ALL") &&
    !queryLooksLikeCRSid &&
    !queryLooksLikeUSN
      ? queryTrimmed
      : "";

  // query Lookup for people matching query if the search query changes
  const { isLoading: isLookupSearchLoading, data: lookupSearchPeople } = useLookupPeopleSearch(
    lookupSearch,
    PERSON_SEARCH_RESULT_LIMIT,
    false,
    institutionFilter,
    `lookupPeopleSearch-${query}${queryType}${queryInstitution}`
  );

  // construct query for searching Lookup by CRSid/USN
  const lookupIdentifierSearch =
    queryLooksLikeUSN && [USN_SCHEME, "ALL"].includes(queryType)
      ? [`usn/${queryTrimmed}`]
      : queryLooksLikeCRSid && [CRSID_SCHEME, "ALL"].includes(queryType)
      ? [queryTrimmed]
      : [];

  // query Lookup for people matching the USN if the search query changes
  const { isLoading: isLookupIdentifierLoading, data: lookupIdentifierPeople } =
    useLookupListPeople(
      lookupIdentifierSearch,
      institutionFilter,
      `lookupListPeople-${query}${queryType}${queryInstitution}`
    );

  const isLookupLoading = isLookupSearchLoading || isLookupIdentifierLoading;

  // construct query for searching Card API by either all supported identifier schemes or one
  // specific identifier scheme based on the query type
  const cardIdentifierSearch = queryNotIdentifier
    ? []
    : queryType === "ALL"
    ? IDENTIFIERS_FOR_CARD_SEARCH.map((identifier) => `${queryTrimmed}@${identifier}`)
    : [`${queryTrimmed}@${queryType}`];

  // query the Card API for people matching the identifier list if the search query changes
  const { isLoading: isCardLoading, data: cardResults } = useCardsWithIdentifiers(
    cardIdentifierSearch,
    institutionFilter
  );

  // when we have new results from the Lookup API add them to our list of people and set our
  // list of CRSids which require card data to any current result without card details
  useEffect(() => {
    if (isLookupLoading) {
      return;
    }
    const fullLookupPeople = (lookupSearchPeople ?? []).concat(lookupIdentifierPeople ?? []);
    const lookupPeople = fullLookupPeople.slice(0, PERSON_SEARCH_POST_FILTER_LIMIT);
    if (lookupPeople.length > 0) {
      setState((previousState) => {
        const people = appendLookupPeopleToPeople(previousState.people, lookupPeople);
        const crsidsMissingCardData = people
          .filter((person) => person.cards.length === 0)
          .map((person) => person.identifiers.find(({ scheme }) => scheme === CRSID_SCHEME)?.value)
          .filter(Boolean) as string[];
        return {
          ...previousState,
          people,
          crsidsMissingCardData,
        };
      });
    }
  }, [isLookupLoading, lookupSearchPeople, lookupIdentifierPeople]);

  // when we have new results from the Card API add them to our list of people and set our
  // list of CRSids which require Lookup data
  useEffect(() => {
    if (cardResults && cardResults.length > 0) {
      setState((previousState) => {
        const people = appendCardsToPeople(previousState.people, cardResults);
        const crsidsMissingLookupData = people
          .filter((person) => !person.lookupPerson)
          .map((person) => person.identifiers.find(({ scheme }) => scheme === CRSID_SCHEME)?.value)
          .filter(Boolean) as string[];
        return {
          ...previousState,
          people,
          crsidsMissingLookupData,
        };
      });
    }
  }, [cardResults]);

  // query the Card API for any of our crsids which are missing card data
  const { data: cardResultsForCRSids, isLoading: cardResultsForIdentifiersLoading } =
    useCardsWithIdentifiers(state.crsidsMissingCardData);

  // if we have results from the Card API for a set of CRSids, update the state with these results
  useEffect(() => {
    if (cardResultsForCRSids) {
      setState((existingState) => ({
        ...existingState,
        crsidsMissingCardData: [],
        people: appendCardsToPeople(existingState.people, cardResultsForCRSids, false),
      }));
    }
  }, [cardResultsForCRSids]);

  // query Lookup for any CRSids where requiring personal information from Lookup
  const { data: lookupDataForCRSids } = useLookupPeopleWithCRSids(
    state.crsidsMissingLookupData,
    true
  );

  // when we have new data from Lookup for CRSids, update our people array accordingly and
  // reset the array of crsids missing lookup data
  useEffect(() => {
    if (lookupDataForCRSids) {
      setState((existingState) => ({
        ...existingState,
        people: appendLookupPeopleToPeople(existingState.people, lookupDataForCRSids, false),
        crsidsMissingLookupData: [],
      }));
    }
  }, [lookupDataForCRSids]);

  return {
    people: state.people,
    isLookupLoading,
    isCardLoading: isCardLoading || cardResultsForIdentifiersLoading,
  };
};
