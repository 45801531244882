/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ContactPhoneNumber,
  ContactPhoneNumberFromJSON,
  ContactPhoneNumberFromJSONTyped,
  ContactPhoneNumberToJSON,
  ContactWebPage,
  ContactWebPageFromJSON,
  ContactWebPageFromJSONTyped,
  ContactWebPageToJSON,
  Person,
  PersonFromJSON,
  PersonFromJSONTyped,
  PersonToJSON,
} from "./";

/**
 * Class representing an institution contact row, for use by the web
 * services API.
 * @export
 * @interface ContactRow
 */
export interface ContactRow {
  /**
   * The contact row's text.
   * @type {string}
   * @memberof ContactRow
   */
  description?: string;
  /**
   * Flag indicating if the contact row's text is normally displayed in
   * bold.
   * @type {boolean}
   * @memberof ContactRow
   */
  bold?: boolean;
  /**
   * Flag indicating if the contact row's text is normally displayed in
   * italics.
   * @type {boolean}
   * @memberof ContactRow
   */
  italic?: boolean;
  /**
   * A list of the contact row's addresses. This will always be non-null,
   * but may be an empty list.
   * @type {Array<string>}
   * @memberof ContactRow
   */
  addresses?: Array<string>;
  /**
   * A list of the contact row's email addresses. This will always be
   * non-null, but may be an empty list.
   * @type {Array<string>}
   * @memberof ContactRow
   */
  emails?: Array<string>;
  /**
   * A list of the people referred to by the contact row. This will always
   * be non-null, but may be an empty list.
   * @type {Array<Person>}
   * @memberof ContactRow
   */
  people?: Array<Person>;
  /**
   * A list of the contact row's phone numbers. This will always be
   * non-null, but may be an empty list.
   * @type {Array<ContactPhoneNumber>}
   * @memberof ContactRow
   */
  phoneNumbers?: Array<ContactPhoneNumber>;
  /**
   * A list of the contact row's web pages. This will always be non-null,
   * but may be an empty list.
   * @type {Array<ContactWebPage>}
   * @memberof ContactRow
   */
  webPages?: Array<ContactWebPage>;
}

export function ContactRowFromJSON(json: any): ContactRow {
  return ContactRowFromJSONTyped(json, false);
}

export function ContactRowFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ContactRow {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    description: !exists(json, "description") ? undefined : json["description"],
    bold: !exists(json, "bold") ? undefined : json["bold"],
    italic: !exists(json, "italic") ? undefined : json["italic"],
    addresses: !exists(json, "addresses") ? undefined : json["addresses"],
    emails: !exists(json, "emails") ? undefined : json["emails"],
    people: !exists(json, "people")
      ? undefined
      : (json["people"] as Array<any>).map(PersonFromJSON),
    phoneNumbers: !exists(json, "phoneNumbers")
      ? undefined
      : (json["phoneNumbers"] as Array<any>).map(ContactPhoneNumberFromJSON),
    webPages: !exists(json, "webPages")
      ? undefined
      : (json["webPages"] as Array<any>).map(ContactWebPageFromJSON),
  };
}

export function ContactRowToJSON(value?: ContactRow | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    description: value.description,
    bold: value.bold,
    italic: value.italic,
    addresses: value.addresses,
    emails: value.emails,
    people:
      value.people === undefined
        ? undefined
        : (value.people as Array<any>).map(PersonToJSON),
    phoneNumbers:
      value.phoneNumbers === undefined
        ? undefined
        : (value.phoneNumbers as Array<any>).map(ContactPhoneNumberToJSON),
    webPages:
      value.webPages === undefined
        ? undefined
        : (value.webPages as Array<any>).map(ContactWebPageToJSON),
  };
}
