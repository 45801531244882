import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";

export const PhotoRejectDialog = ({
  open,
  onReject,
  onClose,
}: {
  open: boolean;
  onReject: (reason?: string) => void;
  onClose: () => void;
}) => {
  const [rejectReason, setRejectReason] = useState("");

  const rejectReasonHandler = (reason?: string) => {
    if (reason && reason.length > 250) {
      reason = reason.slice(0, 250);
    }
    setRejectReason(reason || "");
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Reject Photo</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" gutterBottom>
          <Grid item sm={12}>
            <DialogContentText>
              The reject reason will be sent to the photo uploader in an email informing them the
              photo has been rejected. Enter a maximum of 250 characters.
            </DialogContentText>
            <TextField
              id="reason-reject"
              maxRows={4}
              fullWidth
              multiline
              label="Reject Reason (optional)"
              onChange={(e) => {
                rejectReasonHandler(e.currentTarget.value);
              }}
              value={rejectReason}
            />
          </Grid>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onReject(rejectReason);
            onClose();
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
