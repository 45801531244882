import React, { useState, useEffect } from "react";

// Material-UI imports
import { useTheme } from "@material-ui/core/styles";
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";

// Amcharts core imports
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// Amcharts charts imports
import {
  DateAxis,
  ValueAxis,
  XYChart,
  XYCursor,
  ColumnSeries,
  Legend,
} from "@amcharts/amcharts4/charts";

// Local imports
import { FullWidthPage } from "../styles/layout";
import { useAnalyticsMetrics, GroupByEnum, useCardPermissions } from "../api/card-api";

export const DashboardPage = () => {
  const { permissions: cardPermissions } = useCardPermissions();
  const isCardAnalyticsReader = !!cardPermissions?.includes("CARD_ANALYTICS_READER");
  const [groupBy, setGroupBy] = useState<GroupByEnum>(GroupByEnum.YEAR);

  type ChartData = {
    date: Date;
    issued: number;
    revoked: number;
  };

  type RevokedReasons = {
    date: Date;
    LostByCardholder?: number;
    LostInTransit?: number;
    Stolen?: number;
    NotWorkingPhysicalDamage?: number;
    NotWorkingElectronicIssue?: number;
    PrintFaded?: number;
    ChangeOfRole?: number;
    ChangeOfDetails?: number;
    IncorrectDetails?: number;
    NoLongerRequiresaCard?: number;
    Expiring?: number;
    Other?: number;
  };

  type AnalyticsData = {
    issuedAt: Array<{ date: Date; count: number }>;
    revokedAt: Array<{ date: Date; count: number }>;
    revokedAtNotes: Array<RevokedReasons>;
  };

  const analytics = useAnalyticsMetrics({
    groupBy: groupBy,
  });

  const isDarkMode =
    (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) || false;

  useEffect(() => {
    if (isDarkMode) {
      am4core.useTheme(am4themes_dark);
    } else {
      am4core.useTheme(am4themes_animated);
    }
  }, [isDarkMode]);

  const analyticsData = analytics.data;
  const revokedAtNotesConverted = (analyticsData?.revokedAtNotes || []).map((note) => ({
    ...note,
    date: new Date(note.date),
  }));

  const theme = useTheme();

  useEffect(() => {
    const textColor = theme.palette.text.primary;
    const data: AnalyticsData = {
      issuedAt: analyticsData?.issuedAt || [],
      revokedAt: analyticsData?.revokedAt || [],
      revokedAtNotes: revokedAtNotesConverted,
    };
    const createChart = (
      chartId: string,
      data: ChartData[],
      dateFormat: string,
      timeUnit: string
    ) => {
      const chart = am4core.create(chartId, XYChart);
      chart.paddingRight = 20;
      chart.data = data;

      const dateAxis = chart.xAxes.push(new DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.dateFormatter.dateFormat = dateFormat;
      dateAxis.tooltipDateFormat = `${dateFormat} yyyy`;
      dateAxis.baseInterval = { timeUnit: timeUnit as any, count: 1 };

      const valueAxis = chart.yAxes.push(new ValueAxis());
      valueAxis.renderer.labels.template.fill = am4core.color(textColor);
      valueAxis.renderer.minWidth = 35;
      valueAxis.strictMinMax = true;
      valueAxis.numberFormatter.numberFormat = "#";
      valueAxis.calculateTotals = true;
      valueAxis.min = 0;
      valueAxis.renderer.labels.template.adapter.add("text", (text) => {
        return Math.round(Number(text)).toString();
      });

      const issuedSeries = chart.series.push(new ColumnSeries());
      issuedSeries.dataFields.dateX = "date";
      issuedSeries.dataFields.valueY = "issued";
      issuedSeries.tooltipText = "{valueY.value} issued";
      issuedSeries.columns.template.fill = am4core.color("#f50057");
      issuedSeries.columns.template.stroke = am4core.color("#000000");
      issuedSeries.strokeWidth = 2;
      issuedSeries.minBulletDistance = 10;
      issuedSeries.name = "Issued";

      const revokedSeries = chart.series.push(new ColumnSeries());
      revokedSeries.dataFields.dateX = "date";
      revokedSeries.dataFields.valueY = "revoked";
      revokedSeries.tooltipText = "{valueY.value} revoked";
      revokedSeries.columns.template.fill = am4core.color("#2196f3");
      revokedSeries.columns.template.stroke = am4core.color("#000000");
      revokedSeries.strokeWidth = 2;
      revokedSeries.minBulletDistance = 10;
      revokedSeries.name = "Revoked";

      chart.legend = new Legend();
      chart.legend.labels.template.fill = am4core.color(textColor);
      chart.cursor = new XYCursor();
      chart.cursor.behavior = "zoomY";
      chart.cursor.lineX.disabled = true;

      return chart;
    };

    const createRevokedNotesChart = (
      chartId: string,
      data: any[],
      dateFormat: string,
      timeUnit: string
    ) => {
      const chart = am4core.create(chartId, XYChart);
      chart.paddingRight = 20;
      chart.data = data;

      const dateAxis = chart.xAxes.push(new DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.dateFormatter.dateFormat = dateFormat;
      dateAxis.tooltipDateFormat = `${dateFormat} yyyy`;
      dateAxis.renderer.labels.template.fill = am4core.color(textColor);
      dateAxis.baseInterval = { timeUnit: timeUnit as any, count: 1 };

      const valueAxis = chart.yAxes.push(new ValueAxis());
      valueAxis.renderer.labels.template.fill = am4core.color(textColor);
      valueAxis.renderer.minWidth = 35;
      valueAxis.strictMinMax = true;
      valueAxis.numberFormatter.numberFormat = "#";
      valueAxis.calculateTotals = true;
      valueAxis.min = 0;
      valueAxis.renderer.labels.template.adapter.add("text", (text) => {
        return Math.round(Number(text)).toString();
      });

      const reasons = [
        "LostByCardholder",
        "LostInTransit",
        "Stolen",
        "NotWorkingPhysicalDamage",
        "NotWorkingElectronicIssue",
        "PrintFaded",
        "ChangeOfRole",
        "ChangeOfDetails",
        "IncorrectDetails",
        "NoLongerRequiresaCard",
        "Expiring",
        "Other",
      ];
      reasons.forEach((reason) => {
        const hasDataForReason = data.some((item) => item[reason]);

        if (hasDataForReason) {
          const series = chart.series.push(new ColumnSeries());
          series.stacked = true;
          series.dataFields.dateX = "date";
          series.dataFields.valueY = reason;
          series.name = reason;
          series.tooltipText = `{name}: {valueY.value}`;
          series.strokeWidth = 2;
          series.minBulletDistance = 10;
        }
      });

      chart.legend = new Legend();
      chart.legend.labels.template.fill = am4core.color(textColor);
      chart.cursor = new XYCursor();
      chart.cursor.behavior = "zoomY";
      chart.cursor.lineX.disabled = true;

      return chart;
    };

    const mergeData = (
      issued: { date: Date; count: number }[],
      revoked: { date: Date; count: number }[]
    ): ChartData[] => {
      const issuedDict = issued.reduce((acc, entry) => {
        acc[entry.date.toISOString()] = entry.count;
        return acc;
      }, {} as { [key: string]: number });

      const revokedDict = revoked.reduce((acc, entry) => {
        acc[entry.date.toISOString()] = entry.count;
        return acc;
      }, {} as { [key: string]: number });

      const allDates = new Set([
        ...issued.map((item) => item.date),
        ...revoked.map((item) => item.date),
      ]);
      const sortedDates = Array.from(allDates).sort();

      return sortedDates.map((date) => ({
        date: new Date(date),
        issued: issuedDict[new Date(date).toISOString()] || 0,
        revoked: revokedDict[new Date(date).toISOString()] || 0,
      }));
    };

    const { issuedAt, revokedAt, revokedAtNotes } = data || {};

    if (!issuedAt || !revokedAt || !revokedAtNotes) {
      return;
    }

    let dateFormat = "";
    let timeUnit = "";
    switch (groupBy) {
      case GroupByEnum.YEAR:
        dateFormat = "yyyy";
        timeUnit = "year";
        break;
      case GroupByEnum.MONTH:
        dateFormat = "MMM yyyy";
        timeUnit = "month";
        break;
      case GroupByEnum.WEEK:
        dateFormat = "dd MMM yyyy";
        timeUnit = "week";
        break;
      case GroupByEnum.DAY:
        dateFormat = "dd MMM yyyy";
        timeUnit = "day";
        break;
    }

    const chartData = mergeData(issuedAt, revokedAt);

    const revokedNotesData = revokedAtNotes.map((entry: RevokedReasons) => {
      return {
        ...entry,
        date: new Date(entry.date),
      };
    });

    const chart = createChart("issuedAndRevokedDiv", chartData, dateFormat, timeUnit);
    const revokedNotesChart = createRevokedNotesChart(
      "revokedNotesChartDiv",
      revokedNotesData,
      dateFormat,
      timeUnit
    );

    return () => {
      chart.dispose();
      revokedNotesChart.dispose();
    };
  }, [groupBy, analyticsData, revokedAtNotesConverted, theme]);

  return (
    <>
      {isCardAnalyticsReader && (
        <Grid container item xs={12} spacing={2}>
          <FullWidthPage>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Card Metrics Dashboard
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Group By</InputLabel>
                <Select
                  value={groupBy}
                  onChange={(e) => setGroupBy(e.target.value as GroupByEnum)}
                  label="Group By"
                >
                  <MenuItem value={GroupByEnum.YEAR}>Year</MenuItem>
                  <MenuItem value={GroupByEnum.MONTH}>Month</MenuItem>
                  <MenuItem value={GroupByEnum.WEEK}>Week</MenuItem>
                  <MenuItem value={GroupByEnum.DAY}>Day</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Issued and Revoked Cards
              </Typography>
              <div id="issuedAndRevokedDiv" style={{ width: "100%", height: "500px" }}></div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Revoked Reasons
              </Typography>
              <div id="revokedNotesChartDiv" style={{ width: "100%", height: "300px" }}></div>
            </Grid>
          </FullWidthPage>
        </Grid>
      )}
    </>
  );
};

export default DashboardPage;
