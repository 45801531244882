/* tslint:disable */
/* eslint-disable */
/**
 * Lookup/Ibis web service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  InlineResponse2009Result,
  InlineResponse2009ResultFromJSON,
  InlineResponse2009ResultFromJSONTyped,
  InlineResponse2009ResultToJSON,
} from "./";

/**
 *
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
  /**
   *
   * @type {InlineResponse2009Result}
   * @memberof InlineResponse2009
   */
  result?: InlineResponse2009Result;
}

export function InlineResponse2009FromJSON(json: any): InlineResponse2009 {
  return InlineResponse2009FromJSONTyped(json, false);
}

export function InlineResponse2009FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineResponse2009 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    result: !exists(json, "result")
      ? undefined
      : InlineResponse2009ResultFromJSON(json["result"]),
  };
}

export function InlineResponse2009ToJSON(
  value?: InlineResponse2009 | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    result: InlineResponse2009ResultToJSON(value.result),
  };
}
