import React, { createContext, useContext, useEffect, useState, ReactNode, FC } from "react";
import { useAppConfig } from "../config/AppConfigProvider";
import { getPinoRemoteLogger, setupGlobalErrorHandler } from "ucam-observe-remote-client";

interface LoggerContextType {
  logger: ReturnType<typeof getPinoRemoteLogger> | null;
}

const LoggerContext = createContext<LoggerContextType | null>(null);

interface LoggerProviderProps {
  children: ReactNode;
  LoadingComponent: FC;
}

export const LoggerProvider = ({ children, LoadingComponent }: LoggerProviderProps) => {
  const [logger, setLogger] = useState<ReturnType<typeof getPinoRemoteLogger> | null>(null);

  const config = useAppConfig<false>(false);

  useEffect(() => {
    if (config) {
      const loggerInstance = getPinoRemoteLogger({
        level: "info",
        clientId: config.ucamObserveRemoteClientId,
        remoteUrl: `${config.ucamObserveRemoteBaseUrl}/log`,
      });
      setupGlobalErrorHandler(loggerInstance);
      setLogger(loggerInstance);
    }
  }, [config]);

  if (!config) {
    return <LoadingComponent />;
  }

  return <LoggerContext.Provider value={{ logger }}>{children}</LoggerContext.Provider>;
};

export const useLogger = () => {
  const context = useContext(LoggerContext);
  if (!context) {
    throw new Error("useLogger must be used within a LoggerProvider");
  }
  return context.logger;
};
