import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useState } from "react";

export function InputDialog({
  isOpen,
  onClose,
  onSuccess,
  action,
  dialogTitle,
  failureTitle,
  content,
}: {
  isOpen: boolean;
  onClose: () => void;
  action: (fieldValue?: string) => Promise<void>;
  onSuccess: () => void;
  dialogTitle: string;
  failureTitle: string;
  content:
    | {
        fieldTitle: string;
        fieldValue: string;
        onValidate?: (value: string) => boolean;
      }
    | string;
}) {
  const [isLoading, setLoading] = useState(false);
  const [errorDescription, setErrorDescription] = useState<null | string>(null);
  const [fieldValue, setFieldValue] = useState<string>(
    typeof content !== "string" ? content.fieldValue : ""
  );

  const fieldsValid =
    typeof content === "string" || !content?.onValidate || content.onValidate(fieldValue);

  const onSetConfirmed = async () => {
    try {
      setLoading(true);
      setErrorDescription(null);
      await action(fieldValue);
      onSuccess();
      setFieldValue("");
    } catch (e) {
      setErrorDescription((e as any).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent dividers>
        {typeof content === "string" ? (
          <Typography variant="body1" gutterBottom>
            {content}
          </Typography>
        ) : (
          <TextField
            placeholder={content.fieldTitle}
            variant="outlined"
            rows={4}
            multiline
            style={{ width: 400 }}
            value={fieldValue}
            onChange={(e) => {
              setFieldValue(e.target.value);
            }}
          />
        )}
        {errorDescription && (
          <Alert severity="error">
            <AlertTitle>{failureTitle}</AlertTitle> {errorDescription}{" "}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isLoading || !fieldsValid} onClick={onSetConfirmed}>
          {isLoading ? <CircularProgress /> : errorDescription ? "Retry" : "Accept"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
