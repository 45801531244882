import { FC, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Link as MuiLink,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { MoreVert as MoreIcon } from "@material-ui/icons";
import { makeStyles, Theme } from "@material-ui/core/styles";

import { sortCards, CardIdentifierSummarySchemeEnum, CRSID_SCHEME } from "../api/card-api";
import { CardVisualization } from "../components";
import { Person } from ".";

const useStyles = makeStyles((theme: Theme) => ({
  visualisationContainer: {
    position: "relative",
  },

  annotation: {
    position: "absolute",
    right: theme.spacing(1),
    bottom: theme.spacing(1),
  },

  cardLink: {
    cursor: "pointer",
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}));

export interface PersonPreviewProps {
  /**
   * Person as returned from usePeopleSearch.
   */
  person: Person;
  /**
   * Whether cards are being loaded.
   */
  cardsLoading: boolean;

  classes?: ReturnType<typeof useStyles>;
}

/**
 * PersonPreview previews a card given an API Card resource.
 */
export const PersonPreview: FC<PersonPreviewProps> = ({
  person,
  cardsLoading,
  classes: classesProp,
}) => {
  const classes = useStyles({ classes: classesProp });

  const title = person.lookupPerson?.visibleName || "Unknown person";
  const { scheme: idScheme, value: idValue } = person.identifiers[0];
  const crsid = idScheme === CRSID_SCHEME ? idValue : undefined;
  const card = sortCards(person.cards)[person.cards.length - 1];

  return (
    <Card>
      <CardHeader
        title={title}
        subheader={crsid ? crsid : `Legacy cardholder (${idValue})`}
        action={<PersonMenu idScheme={idScheme} idValue={idValue} />}
      />
      <Divider />
      <div className={classes.visualisationContainer}>
        <Link className={classes.cardLink} to={`/cardholders/${idValue}@${idScheme}`}>
          <CardVisualization
            visualizationUrl={card?.frontVisualizationLink}
            isLoading={cardsLoading}
            fallbackText="No cards issued"
            cardStatus={card?.status}
          />
          {person.cards.length > 1 && (
            <div className={classes.annotation}>
              <Chip label={`+${person.cards.length - 1} more`} color="primary" size="small" />
            </div>
          )}
        </Link>
      </div>
    </Card>
  );
};

/**
 * Component which represents the popout menu displayed at the top right of the
 * person preview component.
 */
const PersonMenu = ({
  idScheme,
  idValue,
}: {
  idScheme: CardIdentifierSummarySchemeEnum;
  idValue: string;
}) => {
  const openButtonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  return (
    <>
      <IconButton ref={openButtonRef} onClick={() => setOpen(true)}>
        <MoreIcon />
      </IconButton>
      <Menu anchorEl={openButtonRef.current} open={open} onClose={() => setOpen(false)}>
        {idScheme === CardIdentifierSummarySchemeEnum.V1PersonIdentifiersCamAcUk && (
          <MenuItem>
            <MuiLink
              className={classes.cardLink}
              href={`https://www.lookup.cam.ac.uk/person/crsid/${idValue}`}
              target="_blank"
            >
              Lookup Profile
            </MuiLink>
          </MenuItem>
        )}
        <MenuItem>
          <Link className={classes.cardLink} to={`/cardholders/${idValue}@${idScheme}`}>
            Card Details
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};
