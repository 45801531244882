import { Button, Card, CardActions, CardContent, CardHeader, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { Component } from "react";

import { feedbackIssueEmail } from "../config/consts";
import { FullPageGrid } from "../styles/layout";

export const ErrorContainer = styled(Card)({
  maxWidth: "40rem",
  width: "80%",
});
export const ExplanationText = styled(Typography)({ margin: "0.5rem auto 1rem" });

/**
 * A default Error boundary which displays a modal and allows the user to refresh the page.
 */
export class ErrorBoundary extends Component {
  state = { hasError: false, errorMessage: "" };

  static getDerivedStateFromError(error?: Error) {
    return { hasError: true, errorMessage: error?.message };
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    const onBack = () => {
      this.setState({ hasError: false });
      window.history.back();
    };

    return (
      <FullPageGrid container>
        <ErrorContainer variant="outlined">
          <CardHeader title="Something went wrong 🛠"></CardHeader>
          <CardContent>
            <ExplanationText gutterBottom>
              Apologies for the inconvenience, please either retry the action or raise an issue for
              the development team to investigate.
            </ExplanationText>
            <Alert severity="error">An error occurred: {this.state.errorMessage}</Alert>
          </CardContent>
          <CardActions>
            <Button onClick={() => window.location.reload()}>Refresh</Button>
            <Button onClick={onBack}>Go back</Button>
            <Button href={`mailto:${feedbackIssueEmail}`}>Contact the Card office</Button>
          </CardActions>
        </ErrorContainer>
      </FullPageGrid>
    );
  }
}
