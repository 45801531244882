import { ComponentProps } from "react";
import { styled } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { useCardRequestDetails } from "../api/card-api";

const StyledSubtitle = styled("div")({
  margin: "10px 0",
});

const StyledTable = styled("table")({
  width: "100%",
  whiteSpace: "nowrap",
});

const StyledTH = styled("th")({
  textAlign: "left",
  marginTop: "6px",
  marginBottom: "6px",
  paddingTop: "4px",
  paddingBottom: "4px",
  paddingLeft: "16px",
  paddingRight: "16px",
  minWidth: "140px",
});
const StyledTD = styled("td")({
  textAlign: "left",
  marginTop: "6px",
  paddingTop: "4px",
  paddingBottom: "4px",
  paddingLeft: "16px",
  paddingRight: "16px",
  minWidth: "140px",
});

interface Props extends ComponentProps<"div"> {
  id?: string;
  isLoading?: boolean;
}

interface Identifer {
  scheme?: string;
  value?: string;
}

interface Affiliation {
  end?: string;
  status?: string;
  identifer?: Identifer;
}

export const CardRequestAffiliations = ({ id, isLoading = false, ...rootProps }: Props) => {
  const { data: cardRequestDetail } = useCardRequestDetails(id || "");

  const affiliations = cardRequestDetail?.affiliations || [];
  const affiliationsList = affiliations.map((item) => {
    const affiliation: Affiliation = item;
    const identifer: Identifer = affiliation.identifer || {};

    return (
      <tr key={affiliation.end}>
        <StyledTD>{affiliation["status"]}</StyledTD>
        <StyledTD>{affiliation["end"]}</StyledTD>
        <StyledTD>{identifer["scheme"]}</StyledTD>
        <StyledTD>{identifer["value"]}</StyledTD>
      </tr>
    );
  });
  if (affiliationsList.length === 0) return null;
  return (
    <div {...rootProps}>
      <Typography variant="subtitle1">
        <StyledSubtitle>Affiliations</StyledSubtitle>
      </Typography>
      <StyledTable>
        <tr>
          <StyledTH>Status</StyledTH>
          <StyledTH>End date</StyledTH>
          <StyledTH>Scheme</StyledTH>
          <StyledTH>Value</StyledTH>
        </tr>
        {affiliations && <tbody>{affiliationsList}</tbody>}
      </StyledTable>
    </div>
  );
};

export default CardRequestAffiliations;
