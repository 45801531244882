import * as React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  CRSID_SCHEME,
  CardRequest,
  useCardLogo,
  useCollegeInstitutionIds,
  useCardFirstCardIdentifierDetailsIfExists,
} from "../api/card-api";
import { cardWorkflowsWebpageLink } from "../config/consts";
import CancelIcon from "@material-ui/icons/Cancel";
import WarningIcon from "@material-ui/icons/Warning";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {
  useCard,
  useCardOperations,
  useCardPermissions,
  CardSummary,
  useCardAPI,
  CardRequestUpdateRequestActionEnum,
  CardRequestWorkflowStateEnum,
  useCardRequestDetails,
  PHOTO_SCHEME,
} from "../api/card-api";
import { Box, Button, ListItem, Link, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "../styles/styles";
import { useSnackbar } from "notistack";
import { useMemo, useState, useEffect } from "react";
import { SelectInputDialog } from "./SelectInputDialog";
import CardActions from "@material-ui/core/CardActions";
import { InputDialog } from "./InputDialog";
import { useAPIGateway } from "../api-gateway/APIGatewayProvider";
import { useQueryClient } from "react-query";
import { AttributeList } from "./AttributeList";
import { EditableField } from "./EditableField";
import { useInstitutionsForDelivery } from "../api/lookup-api";
import { usePhotoById, Photo } from "../api/photo-api";
import { requestorDescription } from "../print-queue/utils";

interface AttributeProperties {
  label?: string | React.ReactNode;
  fieldName?: string;
  editable: boolean;
  fieldOptions?: string[];
  displayValueGetter: (cardRequest: CardRequest) => string;
  displayValueFormatter?: (option: string) => string;
}

interface CardControlProps {
  card?: CardSummary;
  isLoading?: boolean;
}

export const CardControls = ({ card }: CardControlProps) => {
  const { id } = card || {};
  const { enqueueSnackbar } = useSnackbar();

  const { data: cardDetail, isLoading: isLoadingCardDetails } = useCard(id || "", {
    enabled: !!id,
  });

  const { cancelCardMutation } = useCardOperations({
    id,
    onResult: (msg, severity) => enqueueSnackbar(msg, { variant: severity }),
  });

  const { permissions: cardPermissions, isLoading: isLoadingCardPermissions } =
    useCardPermissions();
  const isCardUpdater = !!cardPermissions?.includes("CARD_UPDATER");
  const [revokeModalOpen, setRevokeModalOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();

  return (
    <>
      <SelectInputDialog
        isOpen={revokeModalOpen}
        onClose={() => setRevokeModalOpen(false)}
        action={async (selectValue?: string, fieldValue?: string) => {
          await cancelCardMutation.mutate({
            cancelReason: fieldValue ? `${selectValue} - ${fieldValue}` : selectValue,
          });
        }}
        onSuccess={() => {
          queryClient.invalidateQueries("v1beta1CardIdentifiersList");
          queryClient.invalidateQueries("v1beta1CardIdentifierDetail");
          setRevokeModalOpen(false);
        }}
        dialogTitle="Revoke card"
        failureTitle="Revoke card failed!"
        content={{
          fieldTitle: "Enter additional information (optional)",
          fieldValue: "",
          selectValue: "",
          selectOptions: [
            "Lost by Cardholder",
            "Lost in Transit",
            "Stolen",
            "Not Working (physical damage)",
            "Not Working (electronic issue)",
            "Print Faded",
            "Change of Role",
            "Change of Details",
            "Incorrect Details",
            "No Longer Requires a Card",
            "Expiring",
            "Other",
          ],
          selectLabel: "Revoke Reason",
          onValidate: (selectValue: string, fieldValue: string) => selectValue !== "",
        }}
      />
      {!isLoadingCardDetails && !isLoadingCardPermissions && isCardUpdater && (
        <Box m={1}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setRevokeModalOpen(true)}
            disabled={!Boolean(cardDetail?.canUpdate)}
          >
            Revoke card
          </Button>
        </Box>
      )}
    </>
  );
};

export const CardNoteControls = ({ card }: CardControlProps) => {
  const cardApi = useCardAPI();
  const { id } = card || {};
  const { data: cardDetail, refetch } = useCard(id || "", { enabled: !!id });
  const { permissions: cardPermissions } = useCardPermissions();
  const isCardNoteCreator = !!cardPermissions?.includes("CARD_NOTE_CREATOR");
  const [noteModalOpen, setNoteModalOpen] = useState<boolean>(false);

  return (
    <>
      <InputDialog
        isOpen={noteModalOpen}
        onClose={() => setNoteModalOpen(false)}
        action={async (noteText?: string) => {
          await cardApi.v1beta1CardNotesCreate({
            cardNoteCreateRequestTypeRequest: {
              cardId: cardDetail!.id!,
              text: noteText || "",
            },
          });
        }}
        onSuccess={() => {
          refetch();
          setNoteModalOpen(false);
        }}
        dialogTitle="Add card note"
        failureTitle="Add card note failed!"
        content={{ fieldTitle: "Enter the card note", fieldValue: "" }}
      />

      {isCardNoteCreator && (
        <>
          <CardActions>
            <Box
              display="flex"
              flexDirection="row-reverse"
              justifyContent="space-between"
              width="100%"
            >
              <Box m={1}>
                <Button color="primary" variant="contained" onClick={() => setNoteModalOpen(true)}>
                  Add note to card
                </Button>
              </Box>
            </Box>
          </CardActions>
        </>
      )}
    </>
  );
};

interface CardRequestControlsProps {
  id: string;
  onChange: () => void;
  showPersonLink?: boolean;
}

export function CardRequestControls({ id, onChange }: CardRequestControlsProps) {
  const { data: cardRequestDetail, refetch: refetchCardRequestDetails } =
    useCardRequestDetails(id);

  // user permissions
  const { permissions: cardPermissions } = useCardPermissions();
  const isCardRequestUpdater = !!cardPermissions?.includes("CARD_REQUEST_UPDATER");
  const isCardRequestCreator = !!cardPermissions?.includes("CARD_REQUEST_CREATOR");
  const isCardRequestPrintAdmin = !!cardPermissions?.includes("CARD_REQUEST_PRINT_ADMIN");

  const { user } = useAPIGateway();
  const { crsid } = user ?? { displayName: "Unknown user", crsid: "unknown" };

  // card request modals
  const [holdModalOpen, setHoldModalOpen] = useState<boolean>(false);
  const [releaseHoldModalOpen, setReleaseHoldModalOpen] = useState<boolean>(false);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [refreshModalOpen, setRefreshModalOpen] = useState<boolean>(false);
  const [requeueModalOpen, setRequeueModalOpen] = useState<boolean>(false);

  const principalIsCardRequestRequestor = (cardRequestRequestor: string) => {
    if (isCardRequestCreator && cardRequestRequestor === crsid + "@" + CRSID_SCHEME) {
      return true;
    } else {
      return false;
    }
  };

  const cardRequestEditableWorkflowStates = [
    CardRequestWorkflowStateEnum.Pending,
    CardRequestWorkflowStateEnum.PendingCrsidRequired,
    CardRequestWorkflowStateEnum.PendingDestinationRequired,
    CardRequestWorkflowStateEnum.PendingPhotoRequired,
    CardRequestWorkflowStateEnum.PendingExpiryDataRequired,
    CardRequestWorkflowStateEnum.Hold,
  ];

  const cardRequestRequeueableWorkflowStates = [
    CardRequestWorkflowStateEnum.CreatingInverification,
    CardRequestWorkflowStateEnum.CreatingInprogress,
  ];

  const cardApi = useCardAPI();
  const queryClient = useQueryClient();

  return (
    <>
      <InputDialog
        // force requeue the card request
        isOpen={requeueModalOpen}
        onClose={() => setRequeueModalOpen(false)}
        action={async () => {
          await cardApi.v1beta1CardRequestsUpdate({
            id: id,
            cardRequestUpdateRequest: {
              action: CardRequestUpdateRequestActionEnum.Requeue,
            },
          });
        }}
        onSuccess={() => {
          refetchCardRequestDetails();
          onChange();
          setRequeueModalOpen(false);
        }}
        dialogTitle="Requeue"
        failureTitle="Requeue failed!"
        content="Abort operation and force requeue the card request."
      />

      <InputDialog
        // refresh card request
        isOpen={refreshModalOpen}
        onClose={() => setRefreshModalOpen(false)}
        action={async () => {
          await cardApi.v1beta1CardRequestsUpdate({
            id: id,
            cardRequestUpdateRequest: {
              action: CardRequestUpdateRequestActionEnum.Update,
            },
          });
        }}
        onSuccess={() => {
          queryClient.invalidateQueries("card-visualization");
          refetchCardRequestDetails();
          onChange();
          setRefreshModalOpen(false);
        }}
        dialogTitle="Refresh card request"
        failureTitle="Refresh failed!"
        content="Refresh the card request from the identity data sources."
      />

      <InputDialog
        // set card request hold
        isOpen={holdModalOpen}
        onClose={() => setHoldModalOpen(false)}
        action={async (holdReason?: string) => {
          await cardApi.v1beta1CardRequestsUpdate({
            id: id,
            cardRequestUpdateRequest: {
              action: CardRequestUpdateRequestActionEnum.SetHold,
              fields: { holdReason },
            },
          });
        }}
        onSuccess={() => {
          refetchCardRequestDetails();
          onChange();
          setHoldModalOpen(false);
        }}
        dialogTitle="Hold card request"
        failureTitle="Hold card request failed!"
        content={{ fieldTitle: "Enter the reason for holding the card request", fieldValue: "" }}
      />

      <InputDialog
        // release card request hold
        isOpen={releaseHoldModalOpen}
        onClose={() => setReleaseHoldModalOpen(false)}
        action={async () => {
          await cardApi
            .v1beta1CardRequestsUpdate({
              id: id,
              cardRequestUpdateRequest: {
                action: CardRequestUpdateRequestActionEnum.ReleaseHold,
              },
            })
            .then(async () => {
              await cardApi.v1beta1CardRequestsUpdate({
                id: id,
                cardRequestUpdateRequest: {
                  action: CardRequestUpdateRequestActionEnum.Update,
                },
              });
            });
        }}
        onSuccess={() => {
          refetchCardRequestDetails();
          onChange();
          setReleaseHoldModalOpen(false);
        }}
        dialogTitle="Release card request hold"
        failureTitle="Release hold failed!"
        content="Click Accept to release this card request from hold."
      />

      <InputDialog
        // cancel card request
        isOpen={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        action={async (cancelReason?: string) => {
          await cardApi.v1beta1CardRequestsUpdate({
            id: id,
            cardRequestUpdateRequest: {
              action: CardRequestUpdateRequestActionEnum.Cancel,
              fields: { cancelReason },
            },
          });
        }}
        onSuccess={() => {
          queryClient.invalidateQueries("v1beta1CardIdentifiersList");
          queryClient.invalidateQueries("v1beta1CardIdentifierDetail");

          refetchCardRequestDetails();
          onChange();
          setCancelModalOpen(false);
        }}
        dialogTitle="Cancel card request"
        failureTitle="Cancel card request failed!"
        content={{ fieldTitle: "Enter the reason for canceling the card request", fieldValue: "" }}
      />

      {isCardRequestPrintAdmin && (
        <Box m={1}>
          <Button
            startIcon={<CancelIcon />}
            color="secondary"
            variant="contained"
            disabled={
              !cardRequestRequeueableWorkflowStates.includes(cardRequestDetail?.workflowState!)
            }
            onClick={() => setRequeueModalOpen(true)}
          >
            Scrap & Requeue
          </Button>
        </Box>
      )}

      {(isCardRequestUpdater ||
        principalIsCardRequestRequestor(cardRequestDetail?.requestor!)) && (
        <Box m={1}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setCancelModalOpen(true)}
            disabled={
              !Boolean(cardRequestDetail?.canUpdate) ||
              !cardRequestEditableWorkflowStates.includes(cardRequestDetail?.workflowState!)
            }
          >
            Cancel card request
          </Button>
        </Box>
      )}

      {(isCardRequestUpdater ||
        principalIsCardRequestRequestor(cardRequestDetail?.requestor!)) && (
        <Box m={1}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setReleaseHoldModalOpen(true)}
            disabled={
              !Boolean(cardRequestDetail?.workflowState === CardRequestWorkflowStateEnum.Hold)
            }
          >
            Release Hold
          </Button>
        </Box>
      )}

      {(isCardRequestUpdater ||
        principalIsCardRequestRequestor(cardRequestDetail?.requestor!)) && (
        <Box m={1}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setHoldModalOpen(true)}
            disabled={
              !cardRequestEditableWorkflowStates.includes(cardRequestDetail?.workflowState!)
            }
          >
            Hold card request
          </Button>
        </Box>
      )}

      {(isCardRequestUpdater ||
        principalIsCardRequestRequestor(cardRequestDetail?.requestor!)) && (
        <Box m={1}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setRefreshModalOpen(true)}
            disabled={
              !Boolean(cardRequestDetail?.canUpdate) ||
              !cardRequestEditableWorkflowStates.includes(cardRequestDetail?.workflowState!)
            }
          >
            Refresh
          </Button>
        </Box>
      )}
    </>
  );
}

export function CardRequestEditableFields({ id, showPersonLink }: CardRequestControlsProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { data, refetch } = useCardRequestDetails(id);

  const { permissions: cardPermissions } = useCardPermissions();
  const isCardRequestUpdater = !!cardPermissions?.includes("CARD_REQUEST_UPDATER");
  const isCardRequestCreator = !!cardPermissions?.includes("CARD_REQUEST_CREATOR");

  const { user } = useAPIGateway();
  const { crsid } = user ?? { displayName: "Unknown user", crsid: "unknown" };

  const { data: institutionInfo } = useInstitutionsForDelivery();
  const { data: collegeInstitutionIds } = useCollegeInstitutionIds();

  const principalIsCardRequestRequestor = (cardRequestRequestor: string) => {
    if (isCardRequestCreator && cardRequestRequestor === crsid + "@" + CRSID_SCHEME) {
      return true;
    } else {
      return false;
    }
  };

  const [photoIdentifier, setPhotoIdentifier] = React.useState<undefined | string>(undefined);

  React.useEffect(() => {
    if (data) {
      const photo_id_summary = data.identifiers.find((ident) => ident.scheme === PHOTO_SCHEME);
      if (photo_id_summary) {
        setPhotoIdentifier(photo_id_summary.value);
      }
    }
  }, [data]);

  const { photo } = usePhotoById(photoIdentifier);

  const photoUploadedDate =
    photo && (photo as Photo).createdAt
      ? (photo as Photo).createdAt.toLocaleString([], {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
      : "";

  const scarfUuid = useMemo(() => {
    const scarfIdentifier = (data?.attributes as any)?.scarf;
    return scarfIdentifier &&
      scarfIdentifier.endsWith("card-logo.v1.card.university.identifiers.cam.ac.uk")
      ? scarfIdentifier.split("@")[0]
      : null;
  }, [data]);
  const { data: scarf } = useCardLogo(scarfUuid || "", {
    enabled: !!scarfUuid,
  });

  const cardRequestEditableWorkflowStates = [
    CardRequestWorkflowStateEnum.Pending,
    CardRequestWorkflowStateEnum.PendingCrsidRequired,
    CardRequestWorkflowStateEnum.PendingDestinationRequired,
    CardRequestWorkflowStateEnum.PendingPhotoRequired,
    CardRequestWorkflowStateEnum.PendingExpiryDataRequired,
    CardRequestWorkflowStateEnum.Hold,
  ];

  const CRSID_VALUE =
    data?.identifiers.find((identifier) => identifier.scheme === CRSID_SCHEME)?.value +
    "@" +
    CRSID_SCHEME;

  const attributeProperties: AttributeProperties[] = [
    ...(showPersonLink
      ? [
          {
            label: (
              <Link href={`/cardholders/${CRSID_VALUE}`} target="_blank">
                Go to Cardholder Page
              </Link>
            ),
            fieldName: "crsidLink",
            editable: false,
            displayValueGetter: () => "",
          },
        ]
      : []),
    {
      label: "Requested by ",
      fieldName: "requestor",
      editable: false,
      displayValueGetter: (cardRequest) => requestorDescription(cardRequest.requestor),
    },
    {
      label: "Deliver to ",
      fieldName: "destination",
      editable:
        (isCardRequestUpdater || principalIsCardRequestRequestor(data?.requestor!)) &&
        !!data &&
        cardRequestEditableWorkflowStates.includes(data.workflowState),
      fieldOptions: institutionInfo ? ["", ...new Set(institutionInfo?.keys())] : [""],
      displayValueGetter: (cardRequest) =>
        cardRequest.destination ? `${cardRequest.destination}` : "",
      displayValueFormatter: (option: string) =>
        option ? `${option} - ${institutionInfo?.get(option)?.name}` : "(default)",
    },
    {
      label: "College (sets scarf-code)",
      // collegeInstitutionId is case sensitive and will be dropped if snake case is used.
      fieldName: "collegeInstitutionId",
      editable:
        (isCardRequestUpdater || principalIsCardRequestRequestor(data?.requestor!)) &&
        !!data &&
        cardRequestEditableWorkflowStates.includes(data.workflowState),
      fieldOptions: collegeInstitutionIds ? ["", ...new Set(collegeInstitutionIds)] : [""],
      displayValueGetter: (cardRequest) => cardRequest.collegeInstitutionId ?? "NO_COLLEGE",
      displayValueFormatter: (option: string) =>
        option
          ? option === "NO_COLLEGE"
            ? `${option}`
            : `${option} - ${institutionInfo?.get(option)?.name}`
          : "(default)",
    },
    {
      label: "Scarf code",
      editable: false,
      displayValueGetter: (cardRequest) => scarf?.name ?? "-",
    },
    {
      label: "Hold reason",
      editable: false,
      displayValueGetter: (cardRequest) => cardRequest.holdReason || "-",
    },
    {
      label: "Cancel reason",
      editable: false,
      displayValueGetter: (cardRequest) => cardRequest.cancelReason || "-",
    },
    {
      label: "Photo uploaded date",
      editable: false,
      displayValueGetter: (cardRequest) => photoUploadedDate,
    },
  ];

  const cardApi = useCardAPI();
  const queryClient = useQueryClient();

  async function onUpdateField(fieldName: string, fieldValue: string) {
    try {
      await cardApi.v1beta1CardRequestsUpdate({
        id: id,
        cardRequestUpdateRequest: {
          action: CardRequestUpdateRequestActionEnum.Update,
          fields: { [fieldName]: fieldValue },
        },
      });
      // refresh card request following field update
      await cardApi.v1beta1CardRequestsUpdate({
        id: id,
        cardRequestUpdateRequest: {
          action: CardRequestUpdateRequestActionEnum.Update,
        },
      });
    } catch (e) {
      enqueueSnackbar((e as any).message, { variant: "error" });
    } finally {
      queryClient.invalidateQueries("card-visualization");
      refetch();
    }
  }

  const classes = useStyles();

  return (
    <>
      <Box m={1}>
        <AttributeList>
          {attributeProperties.map((detailsGetter) => (
            <Box key={(data?.id ? data.id : "") + detailsGetter.label || " "}>
              <ListItem dense>{detailsGetter.label}</ListItem>
              <ListItem dense>
                {data ? (
                  <EditableField
                    fieldOptions={detailsGetter.fieldOptions}
                    currentValue={detailsGetter.displayValueGetter(data!)}
                    isEditable={detailsGetter.editable}
                    optionToDisplayValue={(value: string) => {
                      return detailsGetter.displayValueFormatter
                        ? detailsGetter.displayValueFormatter(value)
                        : value;
                    }}
                    onChangeValue={(fieldValue) => {
                      if (detailsGetter.fieldName) {
                        onUpdateField(detailsGetter.fieldName, fieldValue);
                      }
                    }}
                  />
                ) : (
                  <Skeleton className={classes.skeletonListItem} />
                )}
              </ListItem>
            </Box>
          ))}
        </AttributeList>
      </Box>
    </>
  );
}

interface CardRequestCreateControlProps {
  identifier: string | undefined;
  onChange: () => void;
}

interface Dictionary {
  [key: string]: string;
}

export function CardRequestCreateControl({ identifier, onChange }: CardRequestCreateControlProps) {
  const { permissions: cardPermissions } = useCardPermissions();
  const isCardRequestCreator = !!cardPermissions?.includes("CARD_REQUEST_CREATOR");
  const [isRequestCardButtonDisabled, setRequestCardButtonDisabled] = useState(true);

  const cardApi = useCardAPI();

  const {
    data: cardIdentifierDetailsData,
    refetch: refetchDetail,
    isRefetching,
    isLoading,
  } = useCardFirstCardIdentifierDetailsIfExists(identifier);

  const allowedActions = cardIdentifierDetailsData?.allowedActions as Dictionary | undefined;

  const [handleClickIsLoading, setHandleClickIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleClick = async () => {
    setHandleClickIsLoading(true);
    try {
      await cardApi.v1beta1CardRequestsCreate({
        cardRequestCreateTypeRequest: { identifier: identifier || "" },
      });
      refetchDetail();
      onChange();
      setIsError(false);
    } catch (error) {
      setIsError(true);
    } finally {
      setHandleClickIsLoading(false);
    }
  };

  const classes = useStyles();

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(16),
    },
  }))(Tooltip);

  useEffect(() => {
    const disabledState =
      isLoading ||
      isRefetching ||
      handleClickIsLoading ||
      !!(allowedActions && !allowedActions.canCreateCardRequest);

    setRequestCardButtonDisabled(disabledState);
  }, [isLoading, isRefetching, handleClickIsLoading, allowedActions]);

  return (
    <>
      {isCardRequestCreator && (
        <>
          {isError && (
            <Box m={0}>
              <Typography color="error">An error occurred. Please try again.</Typography>
            </Box>
          )}
          {allowedActions && !allowedActions.canCreateCardRequest && (
            <Box m={0}>
              <HtmlTooltip title={allowedActions?.canCreateCardRequestDetails || ""}>
                <IconButton>
                  <WarningIcon />
                </IconButton>
              </HtmlTooltip>
            </Box>
          )}
          <Box>
            <HtmlTooltip title="Click for card workflows webpage">
              <Link href={cardWorkflowsWebpageLink} target="_blank">
                <Button variant="contained">
                  Help <HelpOutlineIcon className={classes.helpOutlineIcon} />
                </Button>
              </Link>
            </HtmlTooltip>
          </Box>
          <Box m={1}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleClick}
              disabled={isRequestCardButtonDisabled}
            >
              Request a new card
            </Button>
          </Box>
        </>
      )}
    </>
  );
}
